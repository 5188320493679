import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../utils/BaseURL";
import { resetErrorAction, resetLoadingAction, resetSuccessAction } from "./globalSlice";

const INITIAL_STATE = {
    loading: false,
    signUpLoading: false,
    error: null,
    success: false,
    loginSuccess: false,
    users: [],
    user: null,
    isVerified: false,
    profile: null,
    isEmailSent: false,
    isEmailChange: false,
    isPasswordChanged: false,
    deleteAccLoading: false,
    deleteAccSuccess: false,
    deleteAccError: null,
    passwordChangeLoading: false,
    passwordChangeSuccess: false,
    passwordChangeError: null,
    changeEmailSuccess: false,
    changeEmailError: null,
    loadingVerificationButton: false,
    emailChangeLoading: false,
    verificationError: false,
    blockUserLoading: false,
    blockUserSuccess: false,
    blockUserError: false,
    changeStatusLoading: false,
    changeStatusSuccess: false,
    changeStatusError: false,
    getFeedBackLoading: false,
    getFeedBackSuccess: false,
    getFeedBackError: false,
    fetchUserLoading: false,
    fetchUserError: false,
    fetchUserProfile: null,

    newEmail: null,
    userAuth: {
        error: null,
        userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null,
    },
};

//! Registration action
export const registrationAction = createAsyncThunk(
    "users/registration",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const { data } = await axios.post(`https://lostify2.onrender.com/users/registration`, payload);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! login action
export const loginAction = createAsyncThunk(
    "users/login",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const { data } = await axios.post(`https://lostify2.onrender.com/users/login`, payload);
            //!save user into localstorage
            localStorage.setItem("userInfo", JSON.stringify(data));
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! account verification token check
export const accountVerificationCheckAction = createAsyncThunk(
    "users/account-verification-check",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const { data } = await axios.put(`https://lostify2.onrender.com/users/verify-account`, payload);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! send "change email" email
export const changeEmailAction = createAsyncThunk(
    "users/change-email",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/users/change-email`, payload, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! get user profile
export const userProfileAction = createAsyncThunk(
    "users/profile",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.get(`${baseURL}/users/profile`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! get user profile by ID admin
export const fetchSingleUserAction = createAsyncThunk(
    "users/profile/:userinfoid",
    async ({ userinfoid }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.get(`${baseURL}/users/profile/${userinfoid}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! upload profile image
export const uploadProfileImageAction = createAsyncThunk(
    "users/upload-profile-image",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const formData = new FormData();
            formData.append("file", payload?.image);
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/users/upload-profile-image`, formData, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! account verification
export const accountVerificationAction = createAsyncThunk(
    "users/account-verification",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/users/verify-account`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! send account verification email
export const sendAccountVerificationEmailAction = createAsyncThunk(
    "users/send-account-verification-email",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/users/send-verify-account-email`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! email change verification token check
export const emailVerificationCheckAction = createAsyncThunk(
    "users/email-verification-token-check",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(
                `${baseURL}/users/change-email/check-token/${payload?.token}`,
                {},
                config
            );

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! email notifications switch
export const emailNotificationSwitchAction = createAsyncThunk(
    "users/account-notifications-switch",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/users/notifications`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! change user status
export const changeUserStatusAction = createAsyncThunk(
    "users/admin/change-status",
    async ({ status, statususerid, statusduration }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(
                `${baseURL}/users/admin/change-status`,
                { status, statususerid, statusduration },
                config
            );

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! delete profile
export const deleteProfileAction = createAsyncThunk(
    "users/account/delete",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.delete(`${baseURL}/users/delete-account`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! delete profile admin
export const deleteProfileAdminAction = createAsyncThunk(
    "users/account/delete-admin",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.delete(`${baseURL}/users/admin/delete-account/${payload}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! block user
export const blockUserAction = createAsyncThunk(
    "users/account/block-admin",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/users/admin/add-to-blacklist/${payload}`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! send forgot password email
export const sendResetPasswordEmail = createAsyncThunk(
    "users/forgot-password",
    async ({ formData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const { data } = await axios.post(`${baseURL}/users/forgot-password`, formData);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! reset password with token
export const resetPasswordAction = createAsyncThunk(
    "users/reset-password",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const { data } = await axios.put(`${baseURL}/users/reset-password/${payload.resetToken}`, {
                password: payload.password,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! change password from settings
export const changePasswordAction = createAsyncThunk(
    "users/change-password",
    async ({ formData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/users/change-password`, formData, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! get feedback

export const getFeedBackAction = createAsyncThunk(
    "users/admin/get-feedback",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const { data } = await axios.get(`${baseURL}/users/admin/get-feedback`, config);
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const usersSlice = createSlice({
    name: "usersSlices",
    initialState: INITIAL_STATE,
    reducers: {
        logoutUsersSlice: (state) => {
            state = undefined;
        },
    },
    extraReducers: (builder) => {
        //! get feedback
        builder.addCase(getFeedBackAction.pending, (state, action) => {
            state.getFeedBackLoading = true;
        });
        builder.addCase(getFeedBackAction.fulfilled, (state, action) => {
            state.getFeedBackLoading = false;
            state.getFeedBackSuccess = action.payload;
            state.getFeedBackError = null;
        });
        builder.addCase(getFeedBackAction.rejected, (state, action) => {
            state.getFeedBackLoading = false;
            state.getFeedBackError = action.payload.message;
        });
        //! change status
        builder.addCase(changeUserStatusAction.pending, (state, action) => {
            state.changeStatusLoading = true;
        });
        builder.addCase(changeUserStatusAction.fulfilled, (state, action) => {
            state.changeStatusLoading = false;
            state.changeStatusSuccess = action.payload.message;
            state.changeStatusError = null;
        });
        builder.addCase(changeUserStatusAction.rejected, (state, action) => {
            state.changeStatusLoading = false;
            state.changeStatusError = action.payload.message;
        });
        //! block user
        builder.addCase(blockUserAction.pending, (state, action) => {
            state.blockUserLoading = true;
        });
        builder.addCase(blockUserAction.fulfilled, (state, action) => {
            state.blockUserLoading = false;
            state.blockUserSuccess = action.payload.message;
            state.blockUserError = null;
        });
        builder.addCase(blockUserAction.rejected, (state, action) => {
            state.blockUserLoading = false;
            state.blockUserError = action.payload.message;
        });
        //! delete profile
        builder.addCase(deleteProfileAction.pending, (state, action) => {
            state.deleteAccLoading = true;
        });
        builder.addCase(deleteProfileAction.fulfilled, (state, action) => {
            state.deleteAccLoading = false;
            state.deleteAccSuccess = true;
            state.deleteAccError = null;
        });
        builder.addCase(deleteProfileAction.rejected, (state, action) => {
            state.deleteAccLoading = false;
            state.deleteAccError = action.payload.message;
        });
        //! delete profile admin
        builder.addCase(deleteProfileAdminAction.pending, (state, action) => {
            state.deleteAccLoading = true;
        });
        builder.addCase(deleteProfileAdminAction.fulfilled, (state, action) => {
            state.deleteAccLoading = false;
            state.deleteAccSuccess = true;
            state.deleteAccError = null;
        });
        builder.addCase(deleteProfileAdminAction.rejected, (state, action) => {
            state.deleteAccLoading = false;
            state.deleteAccError = action.payload.message;
        });
        //!email notification action
        builder.addCase(emailNotificationSwitchAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(emailNotificationSwitchAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
            state.profile = action.payload;
        });
        builder.addCase(emailNotificationSwitchAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
        //!check verification token
        builder.addCase(accountVerificationCheckAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(accountVerificationCheckAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.verificationError = null;
            state.isVerified = true;
        });
        builder.addCase(accountVerificationCheckAction.rejected, (state, action) => {
            state.loading = false;
            state.verificationError = action.payload.message;
        });

        //!send verification email
        builder.addCase(accountVerificationAction.pending, (state, action) => {
            state.loadingVerificationButton = true;
        });
        builder.addCase(accountVerificationAction.fulfilled, (state, action) => {
            state.loadingVerificationButton = false;
            state.error = null;
            state.isEmailSent = true;
        });
        builder.addCase(accountVerificationAction.rejected, (state, action) => {
            state.loadingVerificationButton = false;
            state.error = action.payload.message;
        });

        //!send verification email manualy
        builder.addCase(sendAccountVerificationEmailAction.pending, (state, action) => {
            state.loadingVerificationButton = true;
        });
        builder.addCase(sendAccountVerificationEmailAction.fulfilled, (state, action) => {
            state.loadingVerificationButton = false;
            state.error = null;
            state.isEmailSent = true;
        });
        builder.addCase(sendAccountVerificationEmailAction.rejected, (state, action) => {
            state.loadingVerificationButton = false;
            state.error = action.payload.message;
        });

        //!email change verification token
        builder.addCase(emailVerificationCheckAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(emailVerificationCheckAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
        });
        builder.addCase(emailVerificationCheckAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
        //!password change verification token
        builder.addCase(resetPasswordAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(resetPasswordAction.fulfilled, (state, action) => {
            state.loading = false;
            state.isPasswordChanged = true;
            state.error = null;
        });
        builder.addCase(resetPasswordAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
        //!password change from settings menu
        builder.addCase(changePasswordAction.pending, (state, action) => {
            state.passwordChangeLoading = true;
        });
        builder.addCase(changePasswordAction.fulfilled, (state, action) => {
            state.passwordChangeLoading = false;
            state.passwordChangeSuccess = true;
            state.passwordChangeError = null;
        });
        builder.addCase(changePasswordAction.rejected, (state, action) => {
            state.passwordChangeLoading = false;
            state.passwordChangeError = action.payload.message;
        });

        //!send password reset token
        builder.addCase(sendResetPasswordEmail.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(sendResetPasswordEmail.fulfilled, (state, action) => {
            state.loading = false;
            state.isEmailSent = true;
            state.error = null;
        });
        builder.addCase(sendResetPasswordEmail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //!send change email
        builder.addCase(changeEmailAction.pending, (state, action) => {
            state.emailChangeLoading = true;
        });
        builder.addCase(changeEmailAction.fulfilled, (state, action) => {
            state.emailChangeLoading = false;
            state.changeEmailSuccess = true;
            state.changeEmailError = null;
            state.isEmailSent = true;
        });
        builder.addCase(changeEmailAction.rejected, (state, action) => {
            state.emailChangeLoading = false;
            state.changeEmailError = action.payload;
        });

        //!upload profile image
        builder.addCase(uploadProfileImageAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(uploadProfileImageAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
            state.profile = action.payload;
        });
        builder.addCase(uploadProfileImageAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //!get profile
        builder.addCase(userProfileAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(userProfileAction.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            // state.success = true;
            state.profile = action.payload;
        });
        builder.addCase(userProfileAction.rejected, (state, action) => {
            state.loading = false;
            // state.error = action.payload.message;
        });
        //!get profile admin
        builder.addCase(fetchSingleUserAction.pending, (state) => {
            state.fetchUserLoading = true;
        });
        builder.addCase(fetchSingleUserAction.fulfilled, (state, action) => {
            state.fetchUserLoading = false;
            state.fetchUserError = action.payload.message;
            state.fetchUserProfile = action.payload;
        });
        builder.addCase(fetchSingleUserAction.rejected, (state, action) => {
            state.fetchUserLoading = false;
            state.fetchUserError = action.payload.message;
        });

        //!Login
        builder.addCase(loginAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loginAction.fulfilled, (state, action) => {
            state.loading = false;
            state.loginSuccess = action.payload.message;
            state.error = null;
            state.userAuth.userInfo = action.payload;
        });
        builder.addCase(loginAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //!Registration
        builder.addCase(registrationAction.pending, (state) => {
            state.signUpLoading = true;
        });
        builder.addCase(registrationAction.fulfilled, (state, action) => {
            state.signUpLoading = false;
            state.success = action.payload;
            state.error = null;
            state.user = action.payload;
        });
        builder.addCase(registrationAction.rejected, (state, action) => {
            state.signUpLoading = false;
            state.error = action.payload;
        });

        //!reset error action
        builder.addCase(resetErrorAction.fulfilled, (state) => {
            state.error = null;
            state.deleteAccError = false;
            state.changeEmailError = false;
            state.passwordChangeError = false;
            state.verificationError = false;
            state.blockUserError = false;
            state.changeStatusError = false;
            state.getFeedBackError = false;
            state.fetchUserError = false;
        });
        //!reset success action
        builder.addCase(resetSuccessAction.fulfilled, (state) => {
            state.success = false;
            state.loginSuccess = false;
            state.isEmailSent = false;
            state.isEmailChange = false;
            state.isPasswordChanged = false;
            state.deleteAccSuccess = false;
            state.passwordChangeSuccess = false;
            state.changeEmailSuccess = false;
            state.blockUserSuccess = false;
            state.changeStatusSuccess = false;
            state.getFeedBackSuccess = false;
            state.fetchUserProfile = null;
            state.isVerified = false;
        });

        //!reset loading action
        builder.addCase(resetLoadingAction.fulfilled, (state) => {
            state.loginSuccess = false;
            state.loading = false;
            state.fetchUserLoading = false;
            state.deleteAccLoading = false;
            state.passwordChangeLoading = false;
            state.changeStatusLoading = false;
            state.getFeedBackLoading = false;
            state.emailChangeLoading = false;
            state.blockUserLoading = false;
            state.loadingVerificationButton = false;
            state.deleteAccSuccess = false;
            state.signUpLoading = false;
        });
    },
});

const usersReducer = usersSlice.reducer;
export const { logoutUsersSlice } = usersSlice.actions;
export default usersReducer;
