import { MapPinIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { deleteSearchAction, fetchPostsAction } from "../../../../redux/slices/postsSlice";
import { useNavigate } from "react-router-dom";
import Error from "../../../Alerts/Error";
import Loading from "../../../Alerts/Loading";

export default function SavedSingleSearch({ search }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeMenu = useSelector((state) => state?.global?.button);
    const { searchSaveError, searchSaveLoading } = useSelector((state) => state?.posts);
    const unsaveSearchHandler = (searchid) => {
        dispatch(deleteSearchAction(searchid));
    };

    //* New search handler
    const newSearcHandler = (formData) => {
        dispatch(fetchPostsAction({ page: 1, limit: 15, formData }));
        navigate("/search");
    };
    return (
        <>
            {searchSaveError && <Error message={searchSaveError} />}
            <div className="bg-white flex lg:items-center lg:justify-between border px-2 lg:px-6 py-2 lg:py-6 rounded-md">
                <div className="w-10 truncate flex-1">
                    <h2 className="font-Roboto-Semibold  text-base leading-7 text-gray-700 sm:mb-4 sm:truncate  sm:tracking-tight ">
                        {search?.title && `Title: ${search?.title}`}
                    </h2>
                    <p className="font-Roboto-Semibold leading-7 text-gray-700 sm:mb-4 sm:truncate text-base sm:tracking-tight ">
                        {search?.content && `Content: ${search?.content}`}
                    </p>

                    <div className=" font-Roboto-Base mt-1 flex flex-col sm:mt-0 xl:flex-row sm:flex-wrap xl:space-x-6">
                        {search?.situation && (
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 28 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    class={`w-5 h-5 `}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                    />
                                </svg>

                                <p className="text-gray-500">
                                    Situation: {search?.situation === "650efd6882a974d8b2661703" ? "Lost" : "Find"}
                                </p>
                            </div>
                        )}
                        {(search?.countryname || search?.cityname) && (
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                {
                                    <MapPinIcon
                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                    />
                                }
                                {search?.countryname && search?.countryname}
                                {search?.cityname && `, ${search?.cityname}`}
                            </div>
                        )}

                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            {search?.devicetype === "phone" && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                    />
                                </svg>
                            )}
                            {search?.devicetype === "tablet" && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 002.25-2.25v-15a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 4.5v15a2.25 2.25 0 002.25 2.25z"
                                    />
                                </svg>
                            )}
                            {search?.devicetype === "laptop" && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    class="bi bi-laptop"
                                    viewBox="0 0 20 18"
                                >
                                    {" "}
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />{" "}
                                </svg>
                            )}
                            {search?.manufacturer && `${search?.manufacturer}`}
                            {search?.model && `, ${search?.model}`}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <p className="text-gray-500">{search?.deviceid && `EMEI/ID: ${search?.deviceid}`}</p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            {search?.color !== "" && (
                                <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 28 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
                                        />
                                    </svg>
                                    <p className="text-gray-500">Color: {search?.color}</p>
                                </>
                            )}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <p className="text-gray-500">{search?.year && `Year: ${search?.year}`}</p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex lg:ml-4 lg:mt-0 self-end">
                    {/* control buttons */}
                    {activeMenu === 5 && (
                        <span className="font-Roboto-Bold ml-3 flex flex-col">
                            <button
                                onClick={() => newSearcHandler(search)}
                                type="button"
                                className="w-20 lg:w-24 lg:mr-2 inline-flex items-center justify-center rounded-md bg-white px-3 py-2 sm:text-xs lg:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="hidden sm:blockw-5 h-5 mr-1"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                </svg>
                                Search
                            </button>

                            {searchSaveLoading ? (
                                <Loading color="blue" h="2rem" w="2rem" mt="1rem" />
                            ) : (
                                <button
                                    onClick={() => unsaveSearchHandler(search?._id)}
                                    type="button"
                                    className="w-20 lg:w-24 mt-4 inline-flex items-center justify-center rounded-md bg-white px-3 py-2 sm:text-xs lg:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 28 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="hidden sm:block w-5 h-5"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                    </svg>
                                    Delete
                                </button>
                            )}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
}
