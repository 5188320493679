import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function NavLinks({ navigation }) {
    const page = useSelector((state) => state?.global?.page);
    return (
        <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item, index) => {
                const key = index;

                return (
                    <Link
                        key={index}
                        to={item.href}
                        className={`${
                            page === key && "underline underline-offset-8 decoration-1 decoration-white/80"
                        } font-Roboto-Bold hover:text-indigo-400 lg:text-sm min-[2560px]:text-2xl font-semibold leading-6 text-white`}
                    >
                        {item.name}
                    </Link>
                );
            })}
        </div>
    );
}

export default NavLinks;
