import { useSelector } from "react-redux";
import user from "../../media/user.png";

const CommentsList = ({ comments }) => {
    const { role } = useSelector((state) => state?.users?.profile?.user);
    const isAdmin = role === "admin";

    const copyComments = [...comments];
    const reversedComments = copyComments.reverse();

    return (
        <div className="flex flex-col space-y-4">
            <div className="flex space-x-4">
                <div className="flex-grow">
                    <div>
                        {reversedComments?.length === 0 ? (
                            <h2 className="text-gray-700 text-center pb-6 pt-3">No comments </h2>
                        ) : (
                            reversedComments?.map((comment, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="w-space-95 lg:w-full mx-auto lg:mx-0 mt-1  flex relative"
                                    >
                                        <div className="bg-blue-50 px-4  py-5 sm:px-6 flex justify-between items-center">
                                            <div className="flex flex-col justify-evenly space-x-0">
                                                <div className="flex-none">
                                                    <img
                                                        src={comment?.author?.profilePicture || user}
                                                        alt="avatar"
                                                        className="min-w-12 min-h-12 rounded-full w-12  ring-1 ring-white"
                                                    />
                                                </div>
                                                <h4 className="text-center font-Roboto-Bold mt-1 text-sm font-medium text-blue-600">
                                                    {comment?.author?.name}
                                                </h4>
                                            </div>
                                        </div>
                                        <p className="text-xs absolute top-1 right-1 text-gray-500">
                                            {new Date(comment?.createdAt).toString().slice(0, 24)}
                                        </p>
                                        {isAdmin && (
                                            <>
                                                <p className="super-small absolute bottom-1 right-1 text-gray-500">
                                                    a. ID: {comment?.author?._id || comment?.author}
                                                </p>
                                                <p className="super-small absolute bottom-4 right-1 text-gray-500">
                                                    c. ID: {comment?._id}
                                                </p>
                                            </>
                                        )}
                                        <div className="bg-blue-50 px-4 w-full py-6 sm:px-6 ">
                                            <p className="font-Roboto-Base my-4 sm:my-1 text-sm text-gray-700">
                                                {comment?.message}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentsList;
