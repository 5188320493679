import React from "react";
import { Link } from "react-router-dom";
import Modal from "../helpers/Modal";

function Page404() {
    return (
        <Modal>
            {" "}
            <main className=" grid min-h-screen place-items-center  sm:py-32 lg:px-8">
                <div className="text-center">
                    <h1 className="font-Roboto-Semibold text-3xl sm:text-5xl font-bold tracking-tight text-white ">
                        Page not found
                    </h1>
                    <p className="font-Roboto-Bold mt-6 font-bold leading-7 text-white">
                        The page you are looking for does not exist.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to="/"
                            className="font-Roboto-Bold rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Go back home
                        </Link>
                    </div>
                </div>
            </main>
        </Modal>
    );
}

export default Page404;
