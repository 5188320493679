import React from "react";
import ProfileImage from "./ProfileImage";

function CoverImage() {
    return (
        <div className="relative">
            <div className="body-profile">
                <div className="flex h-40">
                    <ProfileImage />
                </div>
                <div className="light x1"></div>
                <div className="light x2"></div>
                <div className="light x3"></div>
                <div className="light x4"></div>
                <div className="light x5"></div>
                <div className="light x6"></div>
                <div className="light x7"></div>
                <div className="light x8"></div>
                <div className="light x9"></div>
            </div>
        </div>
    );
}

export default CoverImage;
