import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddComment from "../Comments/AddComment";
import { setPostId, setShowDetailModal } from "../../redux/slices/globalSlice";

function PostDetails() {
    //* Details modal depends on where you are now on the site
    const active = useSelector((state) => state?.global?.button);
    const activeMenu = useSelector((state) => state?.global?.page);
    const id = useSelector((state) => state?.global?.postId);

    const posts = useSelector((state) => state?.users?.profile?.user?.posts);
    const savedPosts = useSelector((state) => state?.users?.profile?.user?.savedPosts);
    const searchPosts = useSelector((state) => state?.posts?.posts?.posts);

    //* Search post depends on navigation
    let post;
    if (active === 0 || active === 3) post = posts?.find((post) => post?._id === id);
    if (active === 4) post = savedPosts?.find((post) => post?._id === id);
    if (activeMenu === 2 || active === 2) post = searchPosts?.find((post) => post?._id === id);

    const data = [
        { type: "Situation", value: post?.situation?.type },
        { type: "Country", value: post?.countryname },
        { type: "City", value: post?.cityname },
        { type: "Type", value: post?.devicetype?.name },
        { type: "Brand", value: post?.manufacturer },
        { type: "Model", value: post?.model },
        { type: "Color", value: post?.color },
        { type: "Year", value: post?.year },
        { type: "EMEI/ID", value: post?.deviceid },
        { type: "Post created", value: new Date(post?.createdAt).toDateString() },
        { type: "Description", value: post?.content },
        { type: "Post ID", value: post?._id },
        { type: "Author ID", value: activeMenu === 2 ? post?.author?._id : post?.author },
    ];

    //* Close details modal
    const dispatch = useDispatch();
    const closeDetailsModal = () => {
        dispatch(setShowDetailModal(false));
        dispatch(setPostId(""));
    };

    return (
        <div className="">
            <div onClick={closeDetailsModal} className="backdrop "></div>
            <div className="z-30 w-vw-95 h-vh-80 mx-space-2.5 my-space-15 sm:w-vw-90 sm:h-vh-90 sm:mx-space-5 sm:my-space-7.5 lg:w-vw-90 lg:h-vh-80 lg:mx-space-5 lg:my-space-10 xl:w-vw-85 xl:h-vh-85 xl:mx-space-7.5 xl:my-space-5 2xl:w-vw-90 2xl:h-vh-90 2xl:mx-space-5 2xl:my-space-2.5 fixed bg-white py-3 lg:px-6 lg:py-5 xl:px-10 xl:py-5 rounded-md overflow-scroll">
                <div className="py-4 px-4 lg:px-0 flex justify-between">
                    <h3 className="font-Roboto-Bold text-lg md:text-2xl 2xl:text-2xl font-semibold leading-7 text-gray-900">
                        {post?.title}
                    </h3>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-8 h-8  text-black cursor-pointer"
                        onClick={closeDetailsModal}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="flex lg:flex-row flex-col justify-between mt-6 border-t border-gray-100">
                    <div className="basis-5/12  divide-y divide-gray-100 bg-white">
                        {post?.image !== "" && (
                            <div className="w-space-95 mb-5 lg:mb-0 mx-auto lg:w-full">
                                <img src={post?.image || ""} alt=""></img>
                            </div>
                        )}
                        {data?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className=" font-Roboto-Semibold w-space-95 mx-auto py-1 px-1 lg:px-4 lg:py-2 grid grid-cols-3 gap-4 "
                                >
                                    <div className="text-sm lg:text-xs 2xl:text-md min-[2560px]:text-xl font-medium leading-6 text-gray-900">
                                        {item?.type}:
                                    </div>
                                    <div className="text-end mt-1 text-sm lg:text-xs min-[2560px]:text-xl leading-6 text-gray-700 col-span-2">
                                        {item?.value}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="basis-6/12 mt-6 lg:mt-0">
                        <AddComment
                            comments={post?.comments}
                            postId={post?._id}
                            postAuthorId={activeMenu === 2 ? post?.author?._id : post?.author}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostDetails;
