import React from "react";

function CreatePostInput({
    max,
    accept,
    title,
    type,
    id,
    placeholder,
    value,
    onChange,
    errors,
    span,
    content,
    basis,
}) {
    return (
        <div className={`${basis} mb-4 flex flex-col`}>
            <label
                htmlFor={id}
                className="mb-1 font-Roboto-Bold text-base  min-[2560px]:text-xl text-coolGray-800"
            >
                {title}
            </label>
            {!content ? (
                <input
                    className={`min-w-max py-2 px-3 leading-5 text-black font-semibold border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-blue-500  rounded-l `}
                    type={type}
                    id={id}
                    placeholder={placeholder}
                    name={id}
                    value={value}
                    onChange={onChange}
                    min={5}
                    max={max || 25}
                    accept={accept}
                />
            ) : (
                <textarea
                    className="resize-none py-3 px-3 leading-5 w-full text-coolGray-800 font-Roboto-Bold border border-coolGray-200 outline-none focus:ring-2 focus:ring-blue-500  rounded-lg "
                    placeholder={placeholder}
                    name={id}
                    value={value}
                    onChange={onChange}
                    resize="none"
                ></textarea>
            )}
            <span className="text-red-400 font-semibold">{span}</span>
            {errors?.[id] && <p className="pt-2 font-bold text-red-600">{errors?.[id]}</p>}
        </div>
    );
}

export default CreatePostInput;
