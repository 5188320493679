import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function MainInfo() {
    const loggedInUser = useSelector((state) => state?.users?.userAuth?.userInfo);

    return (
        <div className="text-center max-[400px]:mt-space-25 mt-space-30 sm:mt-32 lg:mt-28 xl:mt-24 2xl:mt-32 min-[1920px]:mt-48 min-[2560px]:mt-96">
            <h1 className="font-Roboto-Bold max-[365px]:text-2xl text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-white  ">
                <span className="text-blue-400">Find</span> your lost{" "}
                <span className="text-blue-400">device </span>
                or
            </h1>
            <h1 className="font-Roboto-Bold max-[365px]:text-2xl text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-white  ">
                <span className="text-blue-400">help </span> to find{" "}
                <span className="text-blue-400">someone </span>
                else's
            </h1>
            <h1 className=" font-Roboto-Bold max-[365px]:text-2xl text-3xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-white ">
                <span className="text-blue-400">all </span>around the
                <span className="text-blue-400"> world.</span>
            </h1>
            <p className="font-Roboto-Bold mt-10 max-[400px]:super-small  text-xs sm:text-lg lg:text-lg font-bold leading-10 text-white">
                <span id="head1">The world's</span>
                <span id="head2"> first service</span>
                <span id="head3"> solves</span>
                <span id="head4"> the problem </span>
                <span id="head5"> of lost gadgets.</span>
            </p>
            {!loggedInUser ? (
                <div className="font-Roboto-Bold mx-auto mt-10 w-vw-95 sm:w-full flex sm:items-center justify-center gap-x-3 sm:gap-x-6">
                    <Link
                        to="/login"
                        className="outline-0 bg py-2 basis-32 sm:basis-48 sm:p-4 text-lg max-[400px]:text-base sm:text-2xl"
                    >
                        Log in
                    </Link>
                    <Link
                        to="/signup"
                        className="outline-0 bg py-2 basis-32 sm:basis-48 sm:p-4 text-lg max-[400px]:text-base sm:text-2xl"
                    >
                        Sign up
                    </Link>
                </div>
            ) : (
                <div className=" font-Roboto-Bold mx-auto mt-10 w-vw-95 sm:w-full flex sm:items-center justify-center gap-x-3 sm:gap-x-6">
                    <Link
                        to="/search"
                        className="outline-0 bg  py-2  basis-36 sm:basis-48 sm:p-4 text-lg max-[400px]:text-base sm:text-2xl"
                    >
                        Search Device
                    </Link>
                    <Link
                        to="/posts/create"
                        className="outline-0 bg  py-2  basis-36 sm:basis-48 sm:p-4 text-lg max-[400px]:text-base sm:text-2xl"
                    >
                        Create Post
                    </Link>
                </div>
            )}
        </div>
    );
}

export default MainInfo;
