import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCitiesAction } from "../../redux/slices/citiesSlice";
import Loading from "../Alerts/Loading";
import SearchPostSelect from "./SearchPostSelect";
import SearchPostInput from "./SearchPostInput";
import { fetchPostsAction, saveSearchAction } from "../../redux/slices/postsSlice";
import Error from "../Alerts/Error";
import Success from "../Alerts/Success";
import { resetSuccessAction } from "../../redux/slices/globalSlice";

function SearchForm({ page, handleSetPage, paginationButtonClick, setPaginationButtonClickToFalse }) {
    const allPosts = useSelector((state) => state?.posts?.posts?.allPosts);
    //* Initial Form Data state
    const [formData, setFormData] = useState({
        situation: "",
        title: "",
        content: "",
        deviceid: "",
        devicetype: "",
        manufacturer: "",
        model: "",
        color: "",
        year: "",
        countryname: "",
        cityname: "",
    });

    //* Search form is not empty variable

    const isNotEmpty =
        formData.title !== "" ||
        formData.situation !== "" ||
        formData.content !== "" ||
        formData.deviceid !== "" ||
        formData.devicetype !== "" ||
        formData.manufacturer !== "" ||
        formData.model !== "" ||
        formData.color !== "" ||
        formData.year !== "" ||
        formData.countryname !== "" ||
        formData.cityname !== "";

    //* Fetch cities
    const dispatch = useDispatch();
    const { cityList, cityLoading } = useSelector((state) => state?.cities);

    useEffect(() => {
        if (formData.countryname) dispatch(fetchCitiesAction(formData.countryname));
    }, [dispatch, formData.countryname]);

    //* Get all options
    const {
        success,
        posts,
        situationOptions,
        devicetypeOptions,
        manufacturerOptions,
        colorOptions,
        countryOptions,
        yearOptions,
    } = useSelector((state) => state?.posts);
    const cityOptions = cityList?.data?.map((item) => {
        return { value: item, label: item };
    });

    //* Input handlers

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const situationHandleChange = (selectedOption) => {
        setFormData({ ...formData, situation: selectedOption.value });
    };
    const deviceTypeHandleChange = (selectedOption) => {
        setFormData({ ...formData, devicetype: selectedOption.value });
    };
    const manufacturerHandleChange = (selectedOption) => {
        setFormData({ ...formData, manufacturer: selectedOption.value });
    };
    const colorHandleChange = (selectedOption) => {
        setFormData({ ...formData, color: selectedOption.value });
    };
    const yearHandleChange = (selectedOption) => {
        setFormData({ ...formData, year: selectedOption.value });
    };
    const countryHandleChange = (selectedOption) => {
        setFormData({ ...formData, countryname: selectedOption.value });
    };
    const citynameHandleChange = (selectedOption) => {
        setFormData({ ...formData, cityname: selectedOption.value });
    };

    //* Event handling
    const { error, loading } = useSelector((state) => state?.posts);

    //* Submit handler
    const handleSubmit = (e) => {
        e.preventDefault();
        handleSetPage(1);
        dispatch(fetchPostsAction({ page: 1, limit: 15, formData }));
    };

    //* Pagination click button handler

    useEffect(() => {
        if (paginationButtonClick) {
            dispatch(fetchPostsAction({ page, limit: 15, formData }));
            setPaginationButtonClickToFalse();
        }
    }, [dispatch, page, paginationButtonClick]);

    //* Save search handler
    const { searchSaveSuccess, searchSaveError } = useSelector((state) => state?.posts);

    const saveSearchHandler = () => {
        dispatch(saveSearchAction({ formData }));
    };

    //* Move to result mobile
    const element = document.getElementById("result");
    useEffect(() => {
        if (success) {
            dispatch(resetSuccessAction());
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [dispatch, element, success]);

    return (
        <div>
            <form
                onSubmit={handleSubmit}
                className="font-bold w-vw-97 mx-auto sm:w-vw-97 lg:w-vw-95 xl:w-vw-90 2xl:w-vw-80 min-[2560px]:w-vw-70 flex flex-col sm:border-2 rounded-md items-center sm:py-10 relative "
            >
                <div className="flex flex-col lg:flex-row w-vw-97 sm:w-11/12 lg:w-space-97 xl:w-space-95">
                    <SearchPostSelect
                        title={"Situation"}
                        options={situationOptions}
                        name="situation"
                        onChange={situationHandleChange}
                        basis={"basis-1/12"}
                    />
                    <SearchPostSelect
                        title={"Device Type"}
                        options={devicetypeOptions}
                        name="devicetype"
                        onChange={deviceTypeHandleChange}
                        basis={"basis-2/12"}
                    />
                    <SearchPostSelect
                        title={"Manufacturer"}
                        options={manufacturerOptions}
                        name="manufacturer"
                        onChange={manufacturerHandleChange}
                        basis={"basis-2/12"}
                    />
                    <SearchPostInput
                        title="Model"
                        type="text"
                        id="model"
                        placeholder="Enter model of device "
                        value={formData.model}
                        onChange={handleChange}
                        span=""
                        basis={"basis-2/12"}
                    />

                    <SearchPostSelect
                        title={"Country"}
                        options={countryOptions}
                        name="countryname"
                        onChange={countryHandleChange}
                        basis={"basis-2/12"}
                    />
                    {!cityLoading ? (
                        <SearchPostSelect
                            title={"City"}
                            options={cityOptions}
                            name="cityname"
                            onChange={citynameHandleChange}
                            basis={"basis-2/12"}
                        />
                    ) : (
                        <Loading w={"3rem"} mt={"1.6rem"} ml={"3rem"} />
                    )}
                </div>
                <div className=" sm:mt-0 lg:mt-8 flex flex-col lg:flex-row w-vw-97 sm:w-11/12 lg:w-space-97 xl:w-space-95">
                    <SearchPostSelect
                        title={"Color"}
                        options={colorOptions}
                        name="color"
                        onChange={colorHandleChange}
                        basis={"basis-1/12"}
                    />
                    <SearchPostSelect
                        title={"Year"}
                        options={yearOptions}
                        name="year"
                        onChange={yearHandleChange}
                        basis={"basis-2/12"}
                    />
                    <SearchPostInput
                        title="Title"
                        type="text"
                        id="title"
                        placeholder="Enter the post title"
                        value={formData.title}
                        onChange={handleChange}
                        span=""
                        basis={"basis-3/12 lg:w-space-10"}
                    />
                    <SearchPostInput
                        title="EMEI/ID"
                        type="text"
                        id="deviceid"
                        placeholder="Enter the device EMEI/ID"
                        value={formData.deviceid}
                        onChange={handleChange}
                        span=""
                        basis={"basis-3/12 lg:w-space-10"}
                    />
                    <SearchPostInput
                        title="Content"
                        type="text"
                        id="content"
                        placeholder="Write your post content"
                        value={formData?.content}
                        onChange={handleChange}
                        span=""
                        basis={"basis-3/12 lg:w-space-10"}
                    />
                    {loading ? (
                        <Loading color="white" />
                    ) : (
                        <>
                            <Link
                                onClick={handleSubmit}
                                className="outline-0 font-Roboto-Bold h-12 sm:self-end lg:self-center  mt-2 sm:mx-3 outline outline-white flex flex-row shrink basis-1/12 py-1 px-5  justify-center text-center items-center rounded-md bg-blue-500  text-2xl font-semibold text-white  hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                            >
                                Search
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                </svg>
                            </Link>
                            {isNotEmpty && (
                                <Link
                                    onClick={saveSearchHandler}
                                    className=" font-Roboto-Boldh-7 absolute -bottom-10 right-0 px-2 flex text-white  rounded-md"
                                >
                                    save search
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 26 20"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6 ml-1"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                        />
                                    </svg>
                                </Link>
                            )}
                        </>
                    )}
                </div>
            </form>
            {posts?.allPosts !== 0 && posts?.posts?.length !== undefined && (
                <h2 className="font-Roboto-Bold text-white text-md sm:text-xl font-bold  mt-16 sm:mt-10 ">
                    {page === 1 && `1-${posts?.posts?.length} of ${posts?.allPosts} Result(s):`}
                    {page > 1 &&
                        `${(page - 1) * 15 + 1}-${allPosts > page * 15 ? page * 15 : allPosts} of ${
                            posts?.allPosts
                        } Result(s):`}
                </h2>
            )}
            {posts?.status === "success" && posts?.posts?.length === 0 && (
                <h2 className="font-Roboto-Bold text-white text-xl text-center font-bold mt-20 sm:mt-14">
                    No post found. Try to change search parameters.
                </h2>
            )}
            {/* Error */}
            {(error || searchSaveError) && (
                <Error
                    message={
                        error?.message ||
                        searchSaveError ||
                        "Sities does not uploaded. Please, try to choose country again or continue without city."
                    }
                />
            )}
            {/* Success  */}
            {searchSaveSuccess && <Success message={"Search saved successfully"} />}
        </div>
    );
}

export default SearchForm;
