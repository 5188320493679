import React, { useEffect } from "react";
import { setActivePage } from "../../redux/slices/globalSlice";
import { useDispatch } from "react-redux";
import Modal from "../helpers/Modal";
import ReactPlayer from "react-player";
import cover from "../../media/cover.png";
import { Link } from "react-router-dom";

function WhatAndWhy() {
    //* set active page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActivePage(4));
    }, [dispatch]);

    const toTopHandler = () => {
        const element = document.getElementById("toppage");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    return (
        <Modal>
            <div id={"toppage"} className="py-24 font-semibold text-white">
                <h1 className="font-Roboto-Bold  text-center text-3xl sm:text-4xl mt-10 mb-14 lg:my-16">
                    About the Project
                </h1>
                <div className=" mx-auto w-vw-97 lg:w-vw-70 2xl:w-vw-50 ">
                    <ReactPlayer
                        url={
                            "https://res.cloudinary.com/dwvuatlj3/video/upload/v1700324051/lostAndFound/what_is_lostify_xrgvjl.mp4"
                        }
                        controls={true}
                        width={"100%"}
                        height={"100%"}
                        volume={0.75}
                        pip={true}
                        stopOnUnmount={false}
                        playing={true}
                        light={<img src={cover} alt="cover" />}
                    />
                </div>
                <div className="font-Roboto-Bold  mx-auto w-vw-90 lg:w-vw-70 2xl:w-vw-50 text-md sm:text-xl">
                    <p className="text-center leading-relaxed mt-16 ">
                        The idea of creating this service is very simple. Every year, tens of thousands of people
                        around the world lose their gadgets in their cities, in other cities and even in other
                        countries.
                    </p>
                    <br />
                    <p className=" text-left leading-relaxed">
                        <p className="text-center">
                            How do people usually react in such cases? Well, they have some options:
                        </p>{" "}
                        <br /> <br />
                        1) try to find it on their own; <br />
                        Objections: you may easily not find your device or someone else has already found it before
                        you; <br />
                        <br />
                        2) contact the police in the hope that someone found it and took it there;
                        <br />
                        Objections: the person who found your gadget is unlikely to want to contact the police,
                        since waiting for reception and paperwork can take significant time. He will never know
                        whether the device was returned to the owner and will not even hear words of gratitude.;
                        <br />
                        <br />
                        3) expect someone to pick up your smartphone and return it to you;
                        <br />
                        Objections: if you have lost your smartphone, it is charged, and the person who found it
                        answered the call, then you are very lucky... But what if it is a tablet or laptop and
                        there is no way to call on them? What if the battery on your smartphone is completely dead?
                        Then you're out of the game;
                        <br />
                        <br />
                        4) and lastly, forget about the device and give up any search.
                        <br />
                        Objections: well, this is always a possible option, but since you are reading this, it
                        means it is not an option for you;
                        <br />
                        <br />
                    </p>

                    <p className="text-center leading-relaxed">
                        Let's change the rules and look from the other side, from the side of the one who found
                        someone else's device. He also have some of options. But as it becomes clear from the above
                        examples, most of the options become unusable. As a result, the last and always available
                        option is to sell the device. An option that does not make our society better.
                        <br />
                    </p>
                    <br />
                    <p className="text-center leading-relaxed">
                        Look at the situation from a different perspective. What if your device was blatantly
                        stolen? In that case, you don't want the device to be of interest to used devices buyers.
                        And many buyers would not want to purchase stolen items. What to do in this case? Do we
                        have options for getting out of all the situations listed above?
                        <br />
                    </p>
                    <br />
                    <h2 className="text-center leading-relaxed">
                        The answer is YEAS!
                        <br />
                    </h2>
                    <br />
                    <p className="text-center leading-relaxed ">
                        No matter where on the planet you lose or find the device, no matter what condition its
                        battery is in, no matter how your own search for it ends. You can simply go to our website
                        and in a few minutes create a post about a lost or found device, indicating all its known
                        parameters, or use the search in our database, perhaps someone is looking for theirs or has
                        found your device and has already created a post about it. Or if you are looking for a used
                        device to buy, check whether it is wanted as missing in our database or not. <br />
                    </p>
                    <br />
                    <h2 className=" leading-relaxed text-center  ">
                        Conclusion.
                        <br />
                        <br />
                    </h2>
                    <p className=" leading-relaxed text-center">
                        As you can see, the service was created to make this world a better place. Therefore:
                    </p>
                    <br />
                    <ul className=" text-left leading-relaxed list-disc ">
                        <li> be respectful to the participants of our service;</li>
                        <li> always check the search before creating a new post;</li>
                        <li> always delete your irrelevant posts;</li>
                        <li> read FAQ before use a feedback;</li>
                        <li>
                            we consider it absolutely normal to offer a reward for the return of a device in the
                            range of 5-15% of its market value. The rest is up to the conscience of the
                            participants.
                        </li>
                        <li>
                            and remember, there are many scammers in the world, so do not transfer money to anyone
                            with the condition that someone will send you the device by a transport company. This
                            can be either the only way to get the device back if you lost the device on vacation,
                            for example, or a method of fraud. By using our service you take all consequences upon
                            yourself.
                        </li>
                        <br />
                    </ul>
                </div>
                <div className="flex justify-center items-center">
                    <Link
                        onClick={toTopHandler}
                        className="outline-0 font-Roboto-Base w-28 my-5  py-1 lg:hidden text-white  text-base  text-center bg-blue-500  rounded-md"
                    >
                        back to top
                    </Link>
                </div>
            </div>
        </Modal>
    );
}

export default WhatAndWhy;
