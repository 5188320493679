import React from "react";
import Select from "react-select";

function CreatePostSelect({ title, options, name, onChange, errors, basis, id, placeholder, className }) {
    return (
        <div className={`${basis}  mb-4 flex flex-col`}>
            <label htmlFor={id} className={`mb-1 font-Roboto-Bold  ${className}`}>
                {title}
            </label>
            <Select
                placeholder={placeholder}
                className="font-bold"
                id={id}
                options={options}
                name={name}
                onChange={onChange}
            ></Select>
            {errors?.[name] && <p className="pt-2 text-red-500 font-bold">{errors?.[name]}</p>}
        </div>
    );
}

export default CreatePostSelect;
