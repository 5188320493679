import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../Alerts/Loading";
import Success from "../../../Alerts/Success";
import Error from "../../../Alerts/Error";
import { userProfileAction } from "../../../../redux/slices/usersSlice";

function DeleteModal({
    closeDeleteModalHandler,
    deleteWithDealHandler,
    firstMessage,
    secondMessage,
    successMessage,
    deleteHandler,
    counter,
    deletePost,
}) {
    const { error, loading, success } = useSelector((state) => state?.posts);
    const { deleteAccError } = useSelector((state) => state?.users);
    const dispatch = useDispatch();
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closeDeleteModalHandler();
                dispatch(userProfileAction());
            }, 2000);
        }
    }, [dispatch, closeDeleteModalHandler, success]);

    return (
        <div className="flex w-full h-full justify-center">
            <div onClick={closeDeleteModalHandler} className="backdrop"></div>
            <div className="fixed z-30 px-5 xl:px-10 py-5  my-56 max-[375px]:my-space-50 sm:my-72 md:my-space-30 lg:my-48  xl:py-8 w-space-95 sm:w-space-55  md:w-space-50 lg:w-space-40 xl:w-space-35 2xl:w-space-30 xl:my-space-18 2xl:my-space-15 bg-white rounded-xl shadow-2xl">
                <form className="flex min-[1920px]:my-10 flex-col items-center  ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-black cursor-pointer absolute top-3 right-3"
                        onClick={closeDeleteModalHandler}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h2 className="font-Roboto-Bold my-4 mt-8 xl:mt-10 2xl:mt-0 md:mb-0 sm:my-5 lg:mb-4 text-xl lg:text-xl xl:text-2xl md:text-2xl  2xl:text-2xl min-[1921px]:text-3xl text-coolGray-900 font-bold text-center">
                        {firstMessage}
                    </h2>
                    <h3 className="font-Roboto-Bold xl:mt-3 md:max-lg:my-5  md:mb-0  lg:mb-4  text-md lg:text-xs xl:text-md 2xl:text-lg  text-coolGray-900 font-bold text-center text-red-500 ">
                        {secondMessage}
                    </h3>
                    {loading ? (
                        <Loading color={"blue"} />
                    ) : (
                        <div className="font-Roboto-Bold flex justify-evenly 2xl:justify-evenly w-10/12 2xl:w-11/12 min-[1980px]:pt-6">
                            <button
                                className="block my-5 xl:my-1 py-2 px-1 sm:mb-4 sm:py-2 md:py-3 lg:py-3 xl:px-3 2xl:py-3 w-28 max-[360px]:w-20  md:w-28 lg:w-28 xl:w-28 2xl:w-32 min-[1920px]:w-32 min-[2560px]:w-40 min-[1920px]:py-2 2xl:text-xl min-[2560px]:text-2xl text-white font-medium text-center bg-blue-500 hover:bg-blue-600  focus:ring-blue-500 rounded-md "
                                type="submit"
                                onClick={counter === 1 ? deleteWithDealHandler : deleteHandler}
                            >
                                Yes{{ deletePost } && ", delete"}
                            </button>
                            <button
                                className="block my-5 xl:my-1 py-2 px-1 sm:mb-4 sm:py-2 md:py-3 lg:py-3 xl:px-3 2xl:py-3 w-28 max-[360px]:w-20  md:w-28 lg:w-28 xl:w-28 2xl:w-32 min-[1920px]:w-32 min-[2560px]:w-40 min-[1920px]:py-2 2xl:text-xl min-[2560px]:text-2xl text-white font-medium text-center bg-blue-500 hover:bg-blue-600  focus:ring-blue-500 rounded-md"
                                type="submit"
                                onClick={counter === 1 ? deleteHandler : closeDeleteModalHandler}
                            >
                                No{{ deletePost } && ", delete"}
                            </button>
                        </div>
                    )}
                    {(error || deleteAccError) && <Error message={error || deleteAccError} />}
                    {success && <Success message={successMessage} />}
                </form>
            </div>
        </div>
    );
}

export default DeleteModal;
