import React from "react";
import { Link } from "react-router-dom";

function LoginButton() {
    return (
        <div className="hidden lg:flex  lg:justify-end ">
            <Link
                to="/login"
                className="font-Roboto-Bold text-xl font-semibold leading-6 rounded-full text-white ring-1 ring-white px-4 py-1  hover:ring-blue-400"
            >
                Log in <span aria-hidden="true">&rarr;</span>
            </Link>
        </div>
    );
}

export default LoginButton;
