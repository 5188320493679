import { useEffect } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { emailVerificationCheckAction, logoutUsersSlice } from "../../redux/slices/usersSlice";
import Loading from "../Alerts/Loading";
import Modal from "../helpers/Modal";
import Error from "../Alerts/Error";
import { logoutCitiesSlice } from "../../redux/slices/citiesSlice";
import { logoutCommentsSlice } from "../../redux/slices/commentsSlice";
import { logoutGlobalSlice } from "../../redux/slices/globalSlice";
import { logoutPostsSlice } from "../../redux/slices/postsSlice";

export default function EmailChange() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, error, isEmailChange } = useSelector((state) => state?.users);
    const { token } = useParams();

    useEffect(() => {
        if (token) {
            dispatch(emailVerificationCheckAction({ token }));
        }
        if (isEmailChange) {
            setTimeout(() => {
                dispatch(logoutCitiesSlice());
                dispatch(logoutCommentsSlice());
                dispatch(logoutGlobalSlice());
                dispatch(logoutPostsSlice());
                dispatch(logoutUsersSlice());
                localStorage.clear();

                window.location.reload();

                navigate("/login");
            }, 2000);
        }
    }, [dispatch, navigate, token, isEmailChange]);
    return (
        <Modal>
            <div className="">
                {loading ? (
                    <div className="w-screen h-screen flex items-center justify-center flex-col">
                        <h1 className="post?.image text-center  text-white text-xl lg:text-3xl 3xl:text-5xl font-bold">
                            Verifying...
                        </h1>
                        <Loading mt={"2rem"} />
                    </div>
                ) : (
                    <>
                        {error === "" && (
                            <div className="flex flex-col items-center justify-center min-h-screen ">
                                <FiCheckCircle className="text-blue-500 text-9xl" />
                                <h1 className="post?.image mt-4 text-xl sm:text-2xl 2xl:text3xl font-bold text-white">
                                    Email changed successfully!
                                </h1>
                            </div>
                        )}
                    </>
                )}
            </div>
            {/* Error */}
            {error && <Error message={error} nav={"/"} />}
        </Modal>
    );
}
