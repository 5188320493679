import { Bars3Icon } from "@heroicons/react/24/outline";

const OpenMenuMobileButton = ({ setMobileMenuOpen }) => {
    return (
        <div className="flex lg:hidden">
            <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(true)}
            >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-7 w-7" aria-hidden="true" />
            </button>
        </div>
    );
};

export default OpenMenuMobileButton;
