import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function PaginationButtons({ handlePreviousPage, handleNextPage, handleSetPage, page }) {
    const allPosts = useSelector((state) => state?.posts?.posts?.allPosts);
    const totalPages = Array.from({ length: Math.ceil(allPosts / 15) }, () => 1);

    return (
        allPosts > 15 && (
            <>
                <div className="flex items-center justify-between  px-4  sm:px-6">
                    <div className=" sm:flex sm:flex-1 sm:items-center sm:justify-center">
                        <div>
                            <nav
                                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                                aria-label="Pagination"
                            >
                                <Link
                                    onClick={handlePreviousPage}
                                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-white ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0"
                                >
                                    <span className="sr-only">Previous</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.75 19.5L8.25 12l7.5-7.5"
                                        />
                                    </svg>
                                </Link>

                                {totalPages?.forEach((p, index) => {
                                    if (index + 1 < 11) {
                                        return (
                                            <Link
                                                key={index + 1}
                                                onClick={() => handleSetPage(index + 1)}
                                                aria-current="page"
                                                className={`hidden sm:inline-flex relative  items-center px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-blue-500 focus:z-20 focus:outline-offset-0 ${
                                                    page === index + 1 && "bg-blue-500"
                                                }`}
                                            >
                                                {index + 1}
                                            </Link>
                                        );
                                    }
                                })}
                                {totalPages?.length > 10 && (
                                    <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                                        ...
                                    </span>
                                )}

                                <Link
                                    onClick={handleNextPage}
                                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-white ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0"
                                >
                                    <span className="sr-only">Next</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        />
                                    </svg>
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </>
        )
    );
}

export default PaginationButtons;
