import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { resetErrorAction, resetLoadingAction, resetSuccessAction } from "./globalSlice";

const INITIAL_STATE = {
    cityLoading: false,
    cityError: null,
    citySuccess: false,
    cityList: [],
};

//! Fetch Cities
export const fetchCitiesAction = createAsyncThunk(
    "fetch-cities",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        const data = { country: `${payload}` };
        const options = {
            method: "POST",
            maxBodyLength: Infinity,
            url: "https://countriesnow.space/api/v0.1/countries/cities",
            headers: {},
            data,
        };

        try {
            const { data } = await axios.request(options);
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const CitiesSlice = createSlice({
    name: "citiesSlice",
    initialState: INITIAL_STATE,
    reducers: {
        logoutCitiesSlice(state) {
            state = undefined;
        },
    },
    extraReducers: (builder) => {
        //! fetch manufacturer
        builder.addCase(fetchCitiesAction.pending, (state, action) => {
            state.cityLoading = true;
        });
        builder.addCase(fetchCitiesAction.fulfilled, (state, action) => {
            state.cityLoading = false;
            state.citySuccess = true;
            state.cityError = null;
            state.cityList = action.payload;
        });
        builder.addCase(fetchCitiesAction.rejected, (state, action) => {
            state.cityLoading = false;
            state.cityError = true;
        });

        //!reset error action
        builder.addCase(resetErrorAction.fulfilled, (state) => {
            state.cityError = null;
        });

        //!reset success action
        builder.addCase(resetSuccessAction.fulfilled, (state) => {
            state.citySuccess = false;
        });

        //!reset loading action
        builder.addCase(resetLoadingAction.fulfilled, (state) => {
            state.cityLoading = false;
        });
    },
});

const citiesReducer = CitiesSlice.reducer;
export const { logoutCitiesSlice } = CitiesSlice.actions;
export default citiesReducer;
