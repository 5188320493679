import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Error from "../Alerts/Error";
import Success from "../Alerts/Success";
import { registrationAction } from "../../redux/slices/usersSlice";
import Loading from "../Alerts/Loading";

function SignUpForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isHidden, setIsHidden] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        name: "",
    });

    // Handle form change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //* Client-side validation
    const [errors, setErrors] = useState({});
    const validateForm = (data) => {
        let errors = {};
        if (data.name.length < 2) errors.name = "Name should be minimum 2 characters";
        if (data.password.length < 6) errors.password = "Password should be minimum 6 characters";
        if (data.name.includes(" ")) errors.name = "White space is not alloud! Use '_' isted.";
        return errors;
    };

    //* Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(
                registrationAction({
                    name: formData.name,
                    email: formData.email.toLowerCase(),
                    password: formData.password,
                })
            );
        }
    };

    const { error, success, signUpLoading } = useSelector((state) => state?.users);

    //* Redirection
    useEffect(() => {
        if (success) {
            setFormData({
                email: "",
                password: "",
                name: "",
            });
            navigate("/");
        }
    }, [dispatch, navigate, success]);

    return (
        <>
            {/* Error */}
            {error && <Error message={error?.message} />}

            {/* Success */}
            {success && <Success timer={10000} message={success?.message} />}

            <form onSubmit={handleSubmit} className="space-y-6" action="#" method="POST">
                <div className="">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                        User Name
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={handleChange}
                            value={formData?.name}
                            id="name"
                            min={3}
                            name="name"
                            type="text"
                            autoComplete="name"
                            required
                            className="font-semibold text-gray-600 block w-full rounded-md border-0 px-1.5 py-1.5 text-whiteshadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    {errors?.name && <p className="pt-2 font-semibold text-red-400">{errors?.name}</p>}
                </div>
                <div className="">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                        Email
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={handleChange}
                            value={formData?.email}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="font-semibold text-gray-600 block w-full rounded-md border-0 px-1.5 py-1.5 text-whiteshadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    {errors?.email && <p className="pt-2 font-semibold text-red-400">{errors?.email}</p>}
                </div>

                <div className="">
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                            Password
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                            onChange={handleChange}
                            value={formData?.password}
                            id="password"
                            name="password"
                            min={6}
                            type={isHidden ? "password" : "text"}
                            autoComplete="of"
                            required
                            className="font-semibold block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {errors?.password && <p className="pt-2 font-semibold text-red-400">{errors?.password}</p>}
                    </div>
                    <div className="flex items-center mt-2 ">
                        <input
                            onClick={() => setIsHidden((p) => !p)}
                            id="showpassword"
                            type="checkbox"
                            className="cursor-pointer rounded-full"
                        />
                        <label htmlFor="showpassword" className="text-white font-bold text-xs ml-2">
                            show password
                        </label>
                    </div>
                </div>
                <div>
                    <input
                        onClick={() => setIsDisabled((p) => !p)}
                        type="checkbox"
                        id="accept"
                        className="cursor-pointer rounded-full "
                    ></input>
                    <label htmlFor="showpassword" className="text-white font-bold text-xs ml-2">
                        I accept{" "}
                        <Link className="text-blue-300 hover:text-blue-400 " to={"/terms"}>
                            Terms and Conditions
                        </Link>{" "}
                        &{" "}
                        <Link className="text-blue-300 hover:text-blue-400 " to={"/policy"}>
                            Privacy Policy
                        </Link>
                    </label>
                </div>
                {/* Loading */}
                {signUpLoading ? (
                    <Loading w={40} h={40} />
                ) : (
                    <div>
                        <button
                            disabled={isDisabled}
                            type="submit"
                            className={`${
                                isDisabled
                                    ? "bg-gray-400"
                                    : "bg-blue-400  hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            } font-Roboto-Bold flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm`}
                        >
                            Sign in
                        </button>
                    </div>
                )}
            </form>
        </>
    );
}

export default SignUpForm;
