import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../utils/BaseURL";

//!reset success action

export const resetSuccessAction = createAsyncThunk("reset-success-state", () => {
    return true;
});

//!reset error action
export const resetErrorAction = createAsyncThunk("reset-error-state", () => {
    return true;
});

//!reset loading action
export const resetLoadingAction = createAsyncThunk("reset-loading-state", () => {
    return true;
});

//!send feedback message action
export const sendFeedBackMessageAction = createAsyncThunk(
    "send-feedback",
    async ({ formData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const { data } = await axios.post(`${baseURL}/comments/feedback`, formData);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//!add appoinment action
export const addAppoinment = createAsyncThunk(
    "add-appoinment",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState()?.users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const { data } = await axios.post(`${baseURL}/comments/add-appoinment`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//!Reset Tokens action
export const resetTokensAction = createAsyncThunk(
    "reset-tokens",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState()?.users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const { data } = await axios.post(`${baseURL}/users/reset-tokens`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//!add deal action
export const addDealAction = createAsyncThunk(
    "add-deal",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState()?.users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const { data } = await axios.post(`${baseURL}/comments/add-deal`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//!send feedback answer action
export const feedBackAnswerAction = createAsyncThunk(
    "send-feedback-answer",
    async ({ formData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState()?.users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/comments/feedback-answer`, formData, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! Get admin data action
export const getAdminDataAction = createAsyncThunk(
    "get-admin-data",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.get(`${baseURL}/users/admin/get-all`, config);
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const INITIAL_STATE = {
    page: 0,
    button: 0,
    postId: "",
    loading: false,
    success: false,
    error: false,
    getAdminError: false,
    showImageModal: false,
    showDetailsModal: false,
    showFeedBackModal: false,
    showEditPostModal: false,
    showAdminDetailsModal: false,
    showAdminUserDataModal: false,
    showConfirmAppoinmentModal: false,
    resetTokensSuccess: false,
    resetTokensError: false,
    resetTokensLoading: false,
    data: {},
};

const GlobalSlice = createSlice({
    name: "globalSlice",
    initialState: INITIAL_STATE,
    reducers: {
        logoutGlobalSlice(state) {
            state = undefined;
        },
        setShowConfirmAppoinmentModal(state, action) {
            state.showConfirmAppoinmentModal = action.payload;
        },
        setShowAdminUserDataModal(state, action) {
            state.showAdminUserDataModal = action.payload;
        },
        setShowAdminDetailModal(state, action) {
            state.showAdminDetailsModal = action.payload;
        },
        setActivePage(state, action) {
            state.page = action.payload;
        },
        setActiveProfileButtons(state, action) {
            state.button = action.payload;
        },
        setPostId(state, action) {
            state.postId = action.payload;
        },
        setShowImageModal(state, action) {
            state.showImageModal = action.payload;
        },
        setShowFeedBackModal(state, action) {
            state.showFeedBackModal = action.payload;
        },
        setShowDetailModal(state, action) {
            state.showDetailsModal = action.payload;
        },
        setShowEditPostModal(state, action) {
            state.showEditPostModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        //! Reset Tokens
        builder.addCase(resetTokensAction.pending, (state, action) => {
            state.resetTokensLoading = true;
        });

        builder.addCase(resetTokensAction.fulfilled, (state, action) => {
            state.resetTokensSuccess = action.payload;
            state.resetTokensLoading = false;
            state.resetTokensError = null;
        });
        builder.addCase(resetTokensAction.rejected, (state, action) => {
            state.resetTokensError = action.payload.message;
            state.resetTokensLoading = false;
        });
        //! Send feedback answer
        builder.addCase(feedBackAnswerAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(feedBackAnswerAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = null;
        });
        builder.addCase(feedBackAnswerAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
        //! Send feedback
        builder.addCase(sendFeedBackMessageAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(sendFeedBackMessageAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = null;
        });
        builder.addCase(sendFeedBackMessageAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //! get admin data
        builder.addCase(getAdminDataAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAdminDataAction.fulfilled, (state, action) => {
            state.loading = false;
            state.getAdminError = null;
            state.data = action.payload;
        });
        builder.addCase(getAdminDataAction.rejected, (state, action) => {
            state.loading = false;
            state.getAdminError = action.payload;
        });

        //!reset error action
        builder.addCase(resetErrorAction.fulfilled, (state) => {
            state.error = null;
            state.resetTokensError = false;
        });

        //!reset success action
        builder.addCase(resetSuccessAction.fulfilled, (state) => {
            state.success = false;
            state.resetTokensSuccess = false;
        });

        //!reset loading action
        builder.addCase(resetLoadingAction.fulfilled, (state) => {
            state.loading = false;
            state.resetTokensLoading = false;
        });
    },
});

export default GlobalSlice.reducer;
export const {
    setActivePage,
    setActiveProfileButtons,
    setPostId,
    setShowImageModal,
    setShowDetailModal,
    setShowEditPostModal,
    setShowFeedBackModal,
    setShowAdminDetailModal,
    setShowAdminUserDataModal,
    setShowConfirmAppoinmentModal,
    logoutGlobalSlice,
} = GlobalSlice.actions;
