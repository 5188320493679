import { useEffect } from "react";
import { CalendarIcon, MapPinIcon, PencilIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { savePostAction } from "../../../../redux/slices/postsSlice";
import { userProfileAction } from "../../../../redux/slices/usersSlice";
import { setPostId, setShowDetailModal, setShowEditPostModal } from "../../../../redux/slices/globalSlice";
import Loading from "../../../Alerts/Loading";

export default function SinglePost({ post, deletePostHandler }) {
    const dispatch = useDispatch();
    const activeMenu = useSelector((state) => state?.global?.button);

    //* Did user save this post before

    const { postSaveSuccess, postSaveLoading } = useSelector((state) => state?.posts);

    //* Save/unsave post action
    const savePostHandler = (postid) => {
        dispatch(savePostAction(postid));
    };

    useEffect(() => {
        if (postSaveSuccess) {
            setTimeout(() => {
                dispatch(userProfileAction());
            }, 2000);
        }
    }, [postSaveSuccess, dispatch]);

    //* Details modal handler

    const detailsModalHandler = (id) => {
        dispatch(setPostId(id));
        dispatch(setShowDetailModal(true));
    };

    //* Edit modal handler

    const editModalHandler = (post) => {
        dispatch(setPostId(post));
        dispatch(setShowEditPostModal(true));
    };

    return (
        <>
            <div className="bg-white sm:flex-row lg:flex lg:items-center lg:justify-between border px-2 xl:px-6 py-2 xl:py-6 rounded-md">
                <div className="font-Roboto-Bold min-w-0 flex-1 ">
                    <h2 className="text-2xl min-[2560px]:text-4xl font-bold leading-7 text-gray-800 sm:mb-4 lg:mb-4 sm:truncate sm:text-3xl sm:tracking-tight ">
                        {post?.title}
                    </h2>
                    <div className=" mt-1 flex flex-col 2xl:flex-row sm:mt-0  sm:flex-wrap ">
                        <div className="mt-2 mr-3 flex items-center text-sm  min-[2560px]:text-lg text-gray-500">
                            <MapPinIcon
                                className="mr-1.5  h-5 w-5 min-[2560px]:h-7 min-[2560px]:w-7 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                            />
                            {post?.countryname}, {post?.cityname}
                        </div>
                        <div className="mt-2 mr-3 flex items-center text-sm min-[2560px]:text-lg text-gray-500">
                            {post?.devicetype?.name === "phone" && (
                                <svg
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 min-[2560px]:w-7 min-[2560px]:h-7"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                    />
                                </svg>
                            )}
                            {post?.devicetype?.name === "tablet" && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 min-[2560px]:w-7 min-[2560px]:h-7"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 002.25-2.25v-15a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 4.5v15a2.25 2.25 0 002.25 2.25z"
                                    />
                                </svg>
                            )}
                            {post?.devicetype?.name === "laptop" && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    class="mr-1 w-5 h-5 min-[2560px]:w-7 min-[2560px]:h-7"
                                    viewBox="-2 0 21 18"
                                >
                                    {" "}
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />{" "}
                                </svg>
                            )}
                            {post?.manufacturer} {post?.model && `, ${post?.model}`}
                        </div>

                        <div className="mt-2 mr-3 flex items-center text-sm min-[2560px]:text-lg text-gray-500">
                            <CalendarIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                            />
                            {new Date(post?.createdAt).toDateString()}
                        </div>
                        {post?.deviceid && (
                            <div className="mt-2 mr-3 flex items-center text-sm min-[2560px]:text-lg text-gray-500">
                                <p className="text-gray-500">EMEI/ID: {post?.deviceid}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="font-Roboto-Bold mt-5 flex lg:ml-4 lg:mt-0 self-end">
                    <span className="mr-3 ">
                        <button
                            onClick={() => detailsModalHandler(post?._id)}
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm min-[2560px]:text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 28 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                class="w-5 h-5 min-[2560px]:w-7 min-[2560px]:h-7"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                />
                            </svg>
                            Details
                        </button>
                    </span>
                    {activeMenu === 4 &&
                        (activeMenu === 4 && postSaveLoading ? (
                            <Loading color={"blue"} w={40} h={40} />
                        ) : (
                            <span className="">
                                <button
                                    onClick={() => savePostHandler(post?._id)}
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white px-4 py-2 text-sm min-[2560px]:text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 28 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5 min-[2560px]:w-7 min-[2560px]:h-7"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                    </svg>
                                    Delete
                                </button>
                            </span>
                        ))}

                    {activeMenu === 0 && (
                        <>
                            <span className="">
                                <button
                                    onClick={() => editModalHandler(post)}
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white px-5 py-2 text-sm min-[2560px]:text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <PencilIcon
                                        className="-ml-0.5 mr-1.5 h-5 w-5 min-[2560px]:w-7 min-[2560px]:h-7 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    Edit
                                </button>
                            </span>
                            <span className="ml-3 ">
                                <button
                                    onClick={() => deletePostHandler(post?._id)}
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white px-4 py-2 text-sm min-[2560px]:text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 min-[2560px]:w-7 min-[2560px]:h-7 mr-1"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                    </svg>
                                    Delete
                                </button>
                            </span>
                        </>
                    )}

                    {/* Dropdown */}
                    {/* {activeMenu !== 4 && (
                        <div className="grow flex self-center">
                            <Menu as="div" className="relative  text-end sm:hidden">
                                <Menu.Button className=" inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                                    More
                                    <ChevronDownIcon
                                        className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className=" absolute right-0 z-10 -mr-16 mt-2 w-36 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    href=""
                                                    onClick={() => editModalHandler(post)}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                >
                                                    Edit
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    onClick={() => detailsModalHandler(post?._id)}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                >
                                                    Details
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    onClick={() => deletePostHandler(post?._id)}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                >
                                                    Delete
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    )} */}
                </div>
            </div>
        </>
    );
}
