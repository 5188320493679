import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../slices/usersSlice";
import postsReducer from "../slices/postsSlice";
import citiesReducer from "../slices/citiesSlice";
import commentsReducer from "../slices/commentsSlice";
import globalReducer from "../slices/globalSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

const persistConfig = {
    key: "root",
    storage,
};

const reducer = combineReducers({
    users: usersReducer,
    posts: postsReducer,
    cities: citiesReducer,
    comments: commentsReducer,
    global: globalReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export default store;
