import React from "react";
import SinglePost from "./SinglePost";

const UserPosts = ({ profile, deletePostHandler }) => {
    return (
        <section className="relative pb-40 bg-white">
            <div className="container relative z-10 px-2 mx-auto min-[2560px]:mx-0">
                <div className=" text-start md:max-w-5xl md:mb-16">
                    <h3 className="font-Roboto-Bold mb-4 text-2xl min-[2560px]:text-4xl font-semibold leading-tight tracking-tighter text-darkCoolGray-900">
                        My Posts
                    </h3>
                </div>

                <div className="flex flex-col mb-12 mx-auto md:mb-20">
                    {profile?.posts.length === 0 ? (
                        <h1 className="text-center">no posts</h1>
                    ) : (
                        <ul className=" flex  flex-col gap-y-4 z-20 relative">
                            {profile?.posts.map((post, index) => (
                                <SinglePost post={post} key={index} deletePostHandler={deletePostHandler} />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UserPosts;
