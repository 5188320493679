import React from "react";
import LoginButton from "./LoginButton";
import OpenMenuMobileButton from "./OpenMenuMobileButton";
import Logo from "./Logo";
import NavLinks from "./NavLinks";
import ProfileDropdown from "./ProfileDropdown";
import { useSelector } from "react-redux";

function DesktopNavBar({ navigation, setMobileMenuOpen }) {
    const loggedInUser = useSelector((state) => state?.users);
    const token = loggedInUser?.userAuth?.userInfo?.token;
    return (
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <Logo />
            <OpenMenuMobileButton setMobileMenuOpen={setMobileMenuOpen} />
            <NavLinks navigation={navigation} />
            {!token && <LoginButton />}
            {token && <ProfileDropdown />}
        </nav>
    );
}

export default DesktopNavBar;
