import React from "react";
import SinglePost from "../MyPosts/SinglePost";
import { useSelector } from "react-redux";
import Error from "../../../Alerts/Error";

const FavoritePosts = ({ profile }) => {
    const { postSaveError } = useSelector((state) => state?.posts);
    return (
        <section className="relative pb-8 bg-white">
            <div
                className="absolute top-0 left-0 w-full h-full"
                style={{
                    backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left top",
                }}
            />
            <div className="container relative z-10 px-4 mx-auto">
                <div className=" text-start md:max-w-5xl md:mb-16">
                    <h3 className="font-Roboto-Bold mb-4 text-2xl font-semibold leading-tight tracking-tighter text-darkCoolGray-900">
                        Favorite Posts
                    </h3>
                </div>

                <div className="flex flex-col mb-12 mx-auto md:mb-20">
                    {profile?.savedPosts.length === 0 ? (
                        <h1 className="text-center">no favorite posts</h1>
                    ) : (
                        <ul className="flex flex-col gap-y-4 z-20 relative">
                            {profile?.savedPosts.map((post, index) => (
                                <SinglePost post={post} key={index} />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            {/* Error */}
            {postSaveError && <Error message={postSaveError} />}
        </section>
    );
};

export default FavoritePosts;
