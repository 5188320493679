import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../Alerts/Loading";
import Success from "../../../Alerts/Success";
import Error from "../../../Alerts/Error";
import { sendAccountVerificationEmailAction } from "../../../../redux/slices/usersSlice";

function VerificationButton({ positionMain, color }) {
    const dispatch = useDispatch();
    const { isEmailSent, loadingVerificationButton, error, userAuth } = useSelector((state) => state?.users);
    const verifyAccountHandler = (e) => {
        e.preventDefault();
        dispatch(sendAccountVerificationEmailAction());
    };

    return (
        <>
            {loadingVerificationButton ? (
                <Loading color={color || "blue"} mt={"2rem"} />
            ) : (
                <button
                    onClick={verifyAccountHandler}
                    className={`${
                        positionMain && "sm:hidden max-sm:p-2 max-sm:mt-0 max-sm:ml-0 max-sm mb-5 max-sm:mr-4"
                    } rounded-md ml-3 lg:ml-0 mt-6 bg-yellow-200 p-2 sm:p-1 xl:p-2 hover:bg-yellow-300`}
                >
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                                className="h-5 w-5 hidden lg:block text-yellow-600"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="">
                            <h3 className="font-Roboto-Base text-sm md:super-small min-[2560px]:text-lg font-medium text-yellow-800">
                                Click here to verify your email and use all services.
                            </h3>
                        </div>
                    </div>
                    {/* Success */}
                    {isEmailSent && (
                        <Success message={`Verification email sent to ${userAuth?.userInfo?.user?.email}`} />
                    )}
                    {/* Error */}
                    {error && <Error message={error} />}
                </button>
            )}
        </>
    );
}

export default VerificationButton;
