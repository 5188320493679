import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    feedBackAnswerAction,
    resetSuccessAction,
    setShowFeedBackModal,
} from "../../../../redux/slices/globalSlice";
import CreatePostInput from "../../../Posts/CreatePostInput";
import Loading from "../../../Alerts/Loading";
import Success from "../../../Alerts/Success";
import Error from "../../../Alerts/Error";

function AdminFeedBackModal() {
    const dispatch = useDispatch();
    const { loading, success, error } = useSelector((state) => state?.global);
    const showFeedBackModalHandler = () => {
        dispatch(resetSuccessAction());
        dispatch(setShowFeedBackModal(false));
    };
    const { getFeedBackSuccess } = useSelector((state) => state?.users);
    const data = getFeedBackSuccess?.feedBack;
    const copyData = [...data];
    const reversedData = copyData.reverse();

    //* Inputs hanling

    const [formData, setFormData] = useState({ email: "", content: "" });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const feedBackAnswerHandler = (e) => {
        e.preventDefault();
        dispatch(feedBackAnswerAction({ formData }));
    };

    useEffect(() => {
        if (success) dispatch(setShowFeedBackModal(false));
    }, [dispatch, success]);

    return (
        <div className="flex justify-center">
            {/* Success */}
            {success && <Success message={success} />}
            {/* Error */}
            {error && <Error message={error} />}

            <div onClick={showFeedBackModalHandler} className="backdrop "></div>
            <div className="fixed z-30  bg-white px-3 sm:px-7 lg:px-10 xl:px-20 py-14 xl:py-16 2xl:py-14 my-space-20 sm:my-space-13 md:my-space-13 lg:my-space-6 xl:my-space-5 w-space-97 sm:w-space-95 md:w-space-90 lg:w-space-85 h-5/6 rounded-md overflow-scroll">
                <div className="relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-black cursor-pointer absolute -right-0 sm:-right-2 xl:-right-10 -top-10 sm:-top-10 xl:-top-8"
                        onClick={showFeedBackModalHandler}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="relative flex flex-col-reverse sm:flex-row justify-between">
                    <div className=" w-full sm:basis-7/12 lg:basis-6/12 self-start overflow-hidden">
                        {reversedData?.map((post) => {
                            return (
                                <div className="bg-white flex flex-col justify-between border p-3 lg:p-6 mb-4 rounded-md">
                                    <div className="2xl:flex justify-between">
                                        <p className="text-black">
                                            <span className=" font-bold">Name: </span> {post?.name}{" "}
                                        </p>
                                        <p className="text-black">
                                            <span className=" font-bold">id: </span>
                                            {post?._id}{" "}
                                        </p>
                                    </div>
                                    <div className="2xl:flex justify-between">
                                        <p className="text-black">
                                            <span className=" font-bold">Email: </span> {post?.email}{" "}
                                        </p>
                                        <p className="text-black">
                                            <span className=" font-bold">Created: </span>
                                            {new Date(post?.createdAt).toDateString()}{" "}
                                        </p>
                                    </div>

                                    <p className="text-black">
                                        <span className=" font-bold">Type: </span>
                                        {post?.type}{" "}
                                    </p>

                                    <p className="text-black">
                                        <span className=" font-bold">Content: </span>
                                        {post?.content}{" "}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <form onSubmit={feedBackAnswerHandler} className="basis-4/12 max-sm:mb-6">
                        <CreatePostInput
                            title=""
                            type="text"
                            id="email"
                            placeholder="email"
                            value={formData.email}
                            onChange={handleChange}
                            span=""
                            basis={"basis-3/12"}
                        />
                        <textarea
                            id="comment"
                            rows={5}
                            className="text-gray-700 resize-none font-bold block w-full mt-1 border p-3 rounded-md shadow-sm form-textarea"
                            placeholder=""
                            defaultValue={""}
                            value={formData.content}
                            onChange={handleChange}
                            name="content"
                        />
                        {loading ? (
                            <Loading color={"blue"} h={"3rem"} w={"3rem"} mt={"1rem"} mr={"20rem"} />
                        ) : (
                            <button
                                className="mt-4 self-end w-28 h-10 inline-block leading-6 text-green-50 font-bold text-xl text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md"
                                type="submit"
                            >
                                Send
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminFeedBackModal;
