import React from "react";

function Modal(props) {
    return (
        <div class="body">
            <>{props.children}</>
            <div class="light x1"></div>
            <div class="light x2"></div>
            <div class="light x3"></div>
            <div class="light x4"></div>
            <div class="light x5"></div>
            <div class="light x6"></div>
            <div class="light x7"></div>
            <div class="light x8"></div>
            <div class="light x9"></div>
        </div>
    );
}

export default Modal;
