import React from "react";
import Modal from "../helpers/Modal";

function PrivacyPolicy() {
    return (
        <Modal>
            <div className="pt-5 sm:pt-10 relative z-20 font-semibold">
                <div className="text-black bg-white w-vw-95 md:w-5/6 mt-40 py-10 sm:py-20 px-5 sm:px-10 lg:px-20 mx-auto border-2 border-gray-200 rounded-md">
                    <div className="background:url('//www.lawdepot.com/common/images//draft_text.png') repeat-y center top/contain #fff;">
                        <div className=" text-xl text-center mb-10 font-black">
                            <span className="font-Roboto-Bold ">PRIVACY POLICY</span>
                        </div>
                        <br />
                        <br />
                        <p className="font-Roboto-Bold ">Last change 29.11.2023</p>
                        <br />
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Purpose</u>
                            <br />
                            <br />
                            The purpose of this privacy policy (this "Privacy Policy") is to inform users of our
                            Site of the following:
                        </p>
                        <br />
                        <ol className="list-disc">
                            <li>
                                <span>The personal data we will collect;</span>
                                <br />
                            </li>
                            <li>
                                <span>Use of collected data;</span>
                                <br />
                            </li>
                            <li>
                                <span>Who has access to the data collected;</span>
                                <br />
                            </li>
                            <li>
                                <span>The rights of Site users; and</span>
                                <br />
                            </li>
                            <li>
                                <span>The Site's cookie policy.</span>
                                <br />
                            </li>
                        </ol>
                        <br />
                        <p className="text-left font-Roboto-Base">
                            This Privacy Policy applies in addition to the terms and conditions of our Site.
                        </p>
                        <br />
                        <br />
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">GDPR</u>
                                <br />
                                <br />
                                For users in the European Union, we adhere to the Regulation (EU) 2016/679 of the
                                European Parliament and of the Council of 27 April 2016, known as the General Data
                                Protection Regulation (the "GDPR"). For users in the United Kingdom, we adhere to
                                the GDPR as enshrined in the Data Protection Act 2018.
                                <br />
                                <br />
                                We have not appointed a Data Protection Officer as we do not fall within the
                                categories of controllers and processors required to appoint a Data Protection
                                Officer under Article 37 of the GDPR.
                            </p>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">Consent</u>
                                <br />
                                <br />
                                By using our Site users agree that they consent to the conditions set out in this
                                Privacy Policy.
                            </p>

                            <p className="text-left font-Roboto-Base">
                                When the legal basis for us processing your personal data is that you have provided
                                your consent to that processing, you may withdraw your consent at any time. If you
                                withdraw your consent, it will not make processing which we completed before you
                                withdrew your consent unlawful.
                            </p>
                            <p className="text-left font-Roboto-Base">
                                You can withdraw your consent by: By writing a corresponding letter in support -
                                lostify.feedback@gmail.com.
                            </p>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">Legal Basis for Processing</u>
                                <br />
                                <br />
                                We collect and process personal data about users in the EU only when we have a
                                legal basis for doing so under Article 6 of the GDPR. <br />
                                <br />
                                We rely on the following legal basis to collect and process the personal data of
                                users in the EU:
                                <br />
                                Users have provided their consent to the processing of their data for one or more
                                specific purposes.
                            </p>
                            <br />
                        </div>
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Personal Data We Collect</u>
                            <br />
                            <br />
                            We only collect data that helps us achieve the purpose set out in this Privacy Policy.
                            We will not collect any additional data beyond the data listed below without notifying
                            you first.
                            <br />
                            <br />
                            <br />
                        </p>
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Data Collected in a Non-Automatic Way</u>
                            <br />
                            <br />
                            We may also collect the following data when you perform certain functions on our Site:
                        </p>
                        <br />
                        <ol className="list-disc">
                            <li>
                                <span>Email address;</span>
                                <br />
                            </li>
                            <li>
                                <span>Payment information.</span>
                                <br />
                                <br />
                            </li>
                        </ol>
                        <p className="text-left font-Roboto-Base">
                            This data may be collected using the following methods:
                        </p>
                        <br />
                        <ol className="list-disc">
                            <li>
                                <span>Creating an account.</span>
                                <br />
                            </li>
                            <li>
                                <span>Purchase on the site.</span>
                                <br />
                            </li>
                        </ol>
                        <br />
                        <br />
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">How We Use Personal Data</u>
                            <br />
                            <br />
                            Data collected on our Site will only be used for the purposes specified in this Privacy
                            Policy or indicated on the relevant pages of our Site. We will not use your data beyond
                            what we disclose in this Privacy Policy.
                        </p>
                        <br />
                        <p className="text-left font-Roboto-Base">
                            The data we collect when the user performs certain functions may be used for the
                            following purposes:
                        </p>
                        <br />
                        <ol className="list-disc">
                            <li>
                                <span>Communication;</span>
                                <br />
                            </li>
                            <li>
                                <span>Password reset;</span>
                                <br />
                            </li>
                            <li>
                                <span>Verify account.</span>
                                <br />
                            </li>
                        </ol>
                        <br />
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Who We Share Personal Data With</u>
                            <br />
                            <br />
                            <u className="font-Roboto-Bold ">Employees</u>
                            <br />
                            <br />
                            We may disclose user data to any member of our organization who reasonably needs access
                            to user data to achieve the purposes set out in this Privacy Policy.
                        </p>
                        <br />
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">Other Disclosures</u>
                                <br />
                                <br />
                                We will not sell or share your data with other third parties, except in the
                                following cases:
                            </p>
                            <br />
                            <ol className="list-disc">
                                <li>
                                    <span>If the law requires it;</span>
                                    <br />
                                </li>
                                <li>
                                    <span>If it is required for any legal proceeding;</span>
                                    <br />
                                </li>
                                <li>
                                    <span>To prove or protect our legal rights;</span>
                                    <br />
                                </li>
                                <li>
                                    <span>
                                        To buyers or potential buyers of this company in the event that we seek to
                                        sell the company.
                                    </span>
                                    <br />
                                    <br />
                                </li>
                            </ol>
                            <p className="text-left;">
                                If you follow hyperlinks from our Site to another Site, please note that we are not
                                responsible for and have no control over their privacy policies and practices.
                            </p>
                        </div>
                        <br />
                        <br />
                        <p className="text-left;">
                            <u className="font-Roboto-Bold ">How Long We Store Personal Data</u>
                            <br />
                            <br />
                            User data will be stored until the purpose the data was collected for has been
                            achieved.
                            <br />
                            <br />
                            You will be notified if your data is kept for longer than this period.
                        </p>
                        <br />
                        <br />
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">How We Protect Your Personal Data</u>
                            <br />
                            <br />
                            In order to protect your security, we use the strongest available br/owser encryption
                            and store all of our data on servers in secure facilities. All data is only accessible
                            to our employees. Our employees are bound by strict confidentiality agreements and a
                            each of this agreement would result in the employee's termination and to bring them to
                            responsibility.
                            <br />
                            <br />
                            While we take all reasonable precautions to ensure that user data is secure and that
                            users are protected, there always remains the risk of harm. The Internet as a whole can
                            be insecure at times and therefore we are unable to guarantee the security of user data
                            beyond what is reasonably practical.
                        </p>
                        <br />
                        <br />
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Your Rights as a User</u>
                            <br />
                            <br />
                            Under the GDPR, you have the following rights:
                        </p>
                        <br />
                        <ol className="list-disc">
                            <li>
                                <span>Right to be informed;</span>
                                <br />
                            </li>
                            <li>
                                <span>Right of access;</span>
                                <br />
                            </li>
                            <li>
                                <span>Right to rectification;</span>
                                <br />
                            </li>
                            <li>
                                <span>Right to erasure;</span>
                                <br />
                            </li>
                            <li>
                                <span>Right to restrict processing;</span>
                                <br />
                            </li>
                            <li>
                                <span>Right to data portability;</span>
                                <br />
                            </li>
                            <li>
                                <span>Right to object.</span>
                                <br />
                            </li>
                        </ol>
                        <br />
                        <br />
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">Children</u>
                                <br />
                                <br />
                                We do not knowingly collect or use personal data from children under 16 years of
                                age. If we learn that we have collected personal data from a child under 16 years
                                of age, the personal data will be deleted as soon as possible. If a child under 16
                                years of age has provided us with personal data their parent or guardian may
                                contact with out support - lostify.feedback@gmail.com.
                            </p>
                            <br />
                        </div>
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">
                                How to Access, Modify, Delete, or Challenge the Data Collected
                            </u>
                            <br />
                            <br />
                            If you would like to know if we have collected your personal data, how we have used
                            your personal data, if we have disclosed your personal data and to who we disclosed
                            your personal data, if you would like your data to be deleted or modified in any way,
                            or if you would like to exercise any of your other rights under the GDPR, please
                            contact our privacy officer here:
                            <br />
                            <br />
                        </p>
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">Do Not Track Notice</u>
                                <br />
                                <br />
                                Do Not Track ("DNT") is a privacy preference that you can set in certain web
                                br/owsers. We do not track the users of our Site over time and across third party
                                websites and therefore do not respond to br/owser-initiated DNT signals.
                            </p>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p className="text-left font-Roboto-Base">
                                <u className="font-Roboto-Bold ">Cookie Policy</u>
                                <br />
                                <br />A cookie is a small file, stored on a user's hard drive by a website. Its
                                purpose is to collect data relating to the user's br/owsing habits. You can choose
                                to be notified each time a cookie is transmitted. You can also choose to disable
                                cookies entirely in your internet br/owser, but this may decrease the quality of
                                your user experience.
                            </p>
                            <br />
                            <p className="text-left font-Roboto-Base">
                                We use the following types of cookies on our Site:
                            </p>
                            <br />
                            <ol className="list-disc">
                                <li>
                                    <u className="font-Roboto-Bold ">
                                        <span>Functional cookies</span>
                                    </u>
                                    <br />
                                    Functional cookies are used to remember the selections you make on our Site so
                                    that your selections are saved for your next visits;
                                    <br />
                                </li>
                                <br />
                                <li>
                                    <u className="font-Roboto-Bold ">
                                        <span>Analytical cookies</span>
                                    </u>
                                    <br />
                                    Analytical cookies allow us to improve the design and functionality of our Site
                                    by collecting data on how you access our Site, for example data on the content
                                    you access, how long you stay on our Site, etc; and
                                    <br />
                                </li>
                                <br />
                                <li>
                                    <u className="font-Roboto-Bold ">
                                        <span>Targeting cookies</span>
                                    </u>
                                    <br />
                                    Targeting cookies collect data on how you use the Site and your preferences.
                                    This allows us to personalize the information you see on our Site for you.
                                    <br />
                                </li>
                            </ol>
                            <br /> <br />
                        </div>
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Modifications</u>
                            <br />
                            <br />
                            This Privacy Policy may be amended from time to time in order to maintain compliance
                            with the law and to reflect any changes to our data collection process. When we amend
                            this Privacy Policy we will update the "Effective Date" at the top of this Privacy
                            Policy. We recommend that our users periodically review our Privacy Policy to ensure
                            that they are notified of any updates. If necessary, we may notify users by email of
                            changes to this Privacy Policy.
                        </p>
                        <br /> <br />
                        <p className="text-left font-Roboto-Base">
                            <u className="font-Roboto-Bold ">Complaints</u>
                            <br />
                            <br />
                            If you have any complaints about how we process your personal data, please contact us
                            through the mail - lostify.feedback@gmail.com. If you feel we have not addressed your
                            concern in a satisfactory manner you may contact a supervisory authority. You also have
                            the right to directly make a complaint to a supervisory authority.
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PrivacyPolicy;
