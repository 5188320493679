import React from "react";

function SearchPostInput({ title, type, id, placeholder, value, onChange, errors, span, content, basis }) {
    return (
        <div className={` mb-4 sm:mx-3 flex flex-col ${basis}`}>
            <label htmlFor={id} className="font-Roboto-Semibold mb-1 text-white font-medium">
                {title}
            </label>

            <input
                className={` py-2 px-3 leading-5 text-black font-semibold border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-blue-500  rounded-l `}
                type={type}
                id={id}
                placeholder={placeholder}
                name={id}
                value={value}
                onChange={onChange}
            />

            <span className="text-red-400 font-semibold">{span}</span>
            {errors?.[id] && <p className="pt-2 text-red-500 font-bold">{errors?.[id]}</p>}
        </div>
    );
}

export default SearchPostInput;
