import { useSelector } from "react-redux";
import SearchPost from "./SearchPost";

const SearchResultPosts = ({ page }) => {
    const { posts } = useSelector((state) => state?.posts?.posts);

    return (
        <div id="result">
            <ul
                // role="list"
                className="w-vw-95 sm:w-vw-97 xl:w-vw-90 min-[2560px]:w-vw-70 2xl:w-vw-80 divide-y divide-black-100 flex flex-col gap-y-4 z-0 mt-5 sm:mt-20 mb-10 relative"
            >
                {posts?.length > 0 &&
                    posts?.map((post, index) => <SearchPost post={post} key={index} page={page} />)}
            </ul>
        </div>
    );
};

export default SearchResultPosts;
