import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAdminDataAction,
    resetTokensAction,
    setShowAdminDetailModal,
    setShowAdminUserDataModal,
    setShowFeedBackModal,
} from "../../../../redux/slices/globalSlice";
import CreatePostInput from "../../../Posts/CreatePostInput";
import { deleteComment } from "../../../../redux/slices/commentsSlice";
import {
    blockUserAction,
    changeUserStatusAction,
    deleteProfileAdminAction,
    fetchSingleUserAction,
    getFeedBackAction,
    userProfileAction,
} from "../../../../redux/slices/usersSlice";
import Success from "../../../Alerts/Success";
import { deletePostAction, fetchSinglePostAction } from "../../../../redux/slices/postsSlice";
import Error from "../../../Alerts/Error";
import Loading from "../../../Alerts/Loading";

function AdminPannel() {
    const dispatch = useDispatch();

    //* Get admin data

    useEffect(() => {
        dispatch(getAdminDataAction());
    }, [dispatch]);

    //* Selectors
    const { commentsSuccess, commentsError, commentsLoading } = useSelector((state) => state?.comments);
    const { success, error, loading, singlePostSuccess, singlePostError, singlePostLoading } = useSelector(
        (state) => state?.posts
    );
    const {
        blockUserSuccess,
        blockUserError,
        blockUserLoading,
        changeStatusSuccess,
        changeStatusError,
        changeStatusLoading,
        deleteAccSuccess,
        deleteAccError,
        deleteAccLoading,
        getFeedBackSuccess,
        getFeedBackError,
        getFeedBackLoading,
        fetchUserProfile,
        fetchUserError,
        fetchUserLoading,
    } = useSelector((state) => state?.users);
    const {
        posts,
        accounts,
        verifiedAccounts,
        unverifiedAccounts,
        postsFound,
        postsLost,
        postsPhones,
        postsTablets,
        postsLaptops,
        comments,
        feedBacks,
        appoinments,
    } = useSelector((state) => state?.global?.data?.data);
    const { resetTokensError, resetTokensSuccess, resetTokensLoading } = useSelector((state) => state?.global);

    //* Form Data handling

    const [formData, setFormData] = useState({
        status: "",
        userid: "",
        postid: "",
        commentid: "",
        blockuserid: "",
        statususerid: "",
        statusduration: "",
        postsearchbyid: "",
        userinfoid: "",
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //* Submit Handling

    const deleteCommentHandler = (e) => {
        e.preventDefault();
        dispatch(deleteComment(formData?.commentid));
    };

    const deletePostHandler = (e) => {
        e.preventDefault();
        dispatch(deletePostAction(formData?.postid));
    };

    const deleteAccountHandler = (e) => {
        e.preventDefault();
        dispatch(deleteProfileAdminAction(formData?.userid));
    };

    const blockUserHandler = (e) => {
        e.preventDefault();
        dispatch(blockUserAction(formData?.blockuserid));
    };

    const changeStatusHandler = (e) => {
        e.preventDefault();
        dispatch(
            changeUserStatusAction({
                status: formData?.status,
                statususerid: formData?.statususerid,
                statusduration: formData?.statusduration,
            })
        );
    };

    const getFeedBackHandler = (e) => {
        e.preventDefault();
        dispatch(getFeedBackAction());
    };

    const [disabled, setDisabled] = useState(true);
    const resetTokensHandler = (e) => {
        e.preventDefault();
        dispatch(resetTokensAction());
    };

    const getSinglePostHandler = (e) => {
        e.preventDefault();
        dispatch(fetchSinglePostAction({ postid: formData?.postsearchbyid }));
    };

    const getUserInfoHandler = (e) => {
        e.preventDefault();
        dispatch(fetchSingleUserAction({ userinfoid: formData?.userinfoid }));
    };

    //* UseEffect actions

    useEffect(() => {
        if (commentsSuccess || success || deleteAccSuccess || blockUserSuccess || getFeedBackSuccess)
            dispatch(userProfileAction());
        if (getFeedBackSuccess) {
            dispatch(setShowFeedBackModal(true));
        }
        if (singlePostSuccess) {
            dispatch(setShowAdminDetailModal(true));
        }
        if (fetchUserProfile) {
            dispatch(setShowAdminUserDataModal(true));
        }
    }, [
        dispatch,
        commentsSuccess,
        success,
        deleteAccSuccess,
        blockUserSuccess,
        getFeedBackSuccess,
        singlePostSuccess,
        fetchUserProfile,
    ]);

    return (
        <div className="sm:w-vw-75">
            {/* Fetch user error */}
            {fetchUserError && <Error message={fetchUserError} />}

            {/* Get single post */}
            {singlePostError && <Error message={singlePostError} />}

            {/* reset tokens */}
            {resetTokensSuccess && <Success message={resetTokensSuccess?.message} />}
            {resetTokensError && <Error message={resetTokensError?.message} />}

            {/* Delete comment */}
            {commentsSuccess && <Success message={"Comment deleted successfully."} />}
            {commentsError && <Error message={commentsError} />}

            {/* change status */}
            {changeStatusSuccess && <Success message={"Status changed successfully."} />}
            {changeStatusError && <Error message={changeStatusError} />}

            {/* Get feedback */}
            {getFeedBackError && <Error message={getFeedBackError} />}

            {/* Delete post */}
            {success && <Success message={"Post deleted successfully."} />}
            {error && <Error message={error} />}

            {/* Delete account */}
            {deleteAccSuccess && <Success message={"Account deleted successfully."} />}
            {deleteAccError && <Error message={deleteAccError} />}

            {/* Block user */}
            {blockUserSuccess && <Success message={blockUserSuccess} />}
            {blockUserError && <Error message={blockUserError} />}

            <section className="flex flex-col ml-10 max-[385px]:ml-2  sm:ml-3 md:ml-6 lg:ml-4 xl:ml-12 mb-48 bg-white text-gray-700 ">
                <div className="container  z-10 px-4 ">
                    <h3 className="font-Roboto-Bold mb-4 text-2xl font-semibold  text-black">Admin Pannel</h3>
                </div>
                <section className="flex flex-col mt-6 mb-20">
                    <div className="flex md:flex-row max-md:text-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                            />
                        </svg>
                        <div className="flex flex-col  mb-1 sm:mb-0 sm:flex-row">
                            {" "}
                            <p className="ml-2">Accounts registered: {accounts} </p>
                            <p className="ml-6">verified: {verifiedAccounts}</p>
                            <p className="ml-6">unverified: {unverifiedAccounts}</p>
                        </div>
                    </div>
                    <div className="flex flex-row max-md:text-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                        </svg>
                        <div className="flex flex-col  mb-1 sm:mb-0 sm:flex-row">
                            <p className="ml-2">Posts: {posts} </p>
                            <p className="ml-4">Lost: {postsLost} </p>
                            <p className="ml-4">Found: {postsFound} </p>
                            <p className="ml-4">Phones: {postsPhones} </p>
                            <p className="ml-4">Tablets: {postsTablets} </p>
                            <p className="ml-4">Laptops: {postsLaptops} </p>
                        </div>
                    </div>
                    <div className="flex flex-row max-md:text-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                            />
                        </svg>

                        <p className="ml-2">Comments: {comments} </p>
                    </div>
                    <div className="flex flex-row max-md:text-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                            />
                        </svg>

                        <p className="ml-2">Feedbacks: {feedBacks} </p>
                    </div>
                    <div className="flex flex-row max-md:text-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>

                        <p className="ml-2">Appoinments: {appoinments} </p>
                    </div>
                    <div className="flex flex-row max-md:text-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 20"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                            />
                        </svg>

                        <p className="ml-2">Deals: {appoinments} </p>
                    </div>
                </section>
                <section className="flex flex-col">
                    <div className="flex">
                        <form onSubmit={deleteAccountHandler} className="flex ">
                            <CreatePostInput
                                title="Delete user"
                                type="text"
                                id="userid"
                                placeholder="user id"
                                value={formData.userid}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12"}
                            />
                            {deleteAccLoading ? (
                                <Loading color={"blue"} w="3rem" mt="1.4rem" ml="1.7rem" />
                            ) : (
                                <button
                                    className="outline-0 mb-4 ml-3 self-end  w-20 h-10 inline-block leading-6 text-white  font-bold text-xl text-center bg-blue-500 rounded-md"
                                    type="submit"
                                >
                                    Delete
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="flex">
                        <form onSubmit={deletePostHandler} className="flex ">
                            <CreatePostInput
                                title="Delete post"
                                type="text"
                                id="postid"
                                placeholder="post id"
                                value={formData.postid}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12"}
                            />
                            {loading ? (
                                <Loading color={"blue"} w="3rem" mt="1.4rem" ml="1.7rem" />
                            ) : (
                                <button
                                    className="outline-0 mb-4 ml-3 self-end  w-20 h-10 inline-block leading-6 text-white font-bold text-xl text-center bg-blue-500  rounded-md"
                                    type="submit"
                                >
                                    Delete
                                </button>
                            )}{" "}
                        </form>
                    </div>
                    <div className="flex">
                        <form onSubmit={deleteCommentHandler} className="flex flex-row">
                            <CreatePostInput
                                title="Delete comment"
                                type="text"
                                id="commentid"
                                placeholder="comment id"
                                value={formData.commentid}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12"}
                            />
                            {commentsLoading ? (
                                <Loading color={"blue"} w="3rem" mt="1.4rem" ml="1.7rem" />
                            ) : (
                                <button
                                    className="outline-0 mb-4 ml-3 self-end  w-20 h-10 inline-block leading-6 text-white font-bold text-xl text-center bg-blue-500  rounded-md"
                                    type="submit"
                                >
                                    Delete
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="flex">
                        <form onSubmit={blockUserHandler} className="flex flex-row">
                            <CreatePostInput
                                title="Block/unblock user"
                                type="text"
                                id="blockuserid"
                                placeholder="user id"
                                value={formData.blockuserid}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12"}
                            />
                            {blockUserLoading ? (
                                <Loading color={"blue"} w="3rem" mt="1.4rem" ml="1.7rem" />
                            ) : (
                                <button
                                    className="outline-0 mb-4 ml-3 self-end  w-20 h-10 inline-block leading-6 text-white font-bold text-xl text-center bg-blue-500  rounded-md"
                                    type="submit"
                                >
                                    Block
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="flex mt-10 lg:mt-0">
                        <form onSubmit={changeStatusHandler} className="flex flex-col lg:flex-row ">
                            <CreatePostInput
                                title="Change account status"
                                type="text"
                                id="status"
                                placeholder="silver/gold/platinum"
                                value={formData?.status}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12"}
                            />
                            <CreatePostInput
                                title="User ID to change"
                                type="text"
                                id="statususerid"
                                placeholder="user id"
                                value={formData?.statususerid}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12 lg:ml-3"}
                            />
                            <CreatePostInput
                                title="Days duration"
                                type="text"
                                id="statusduration"
                                placeholder="days"
                                value={formData?.statusduration}
                                onChange={handleChange}
                                span=""
                                basis={"basis-3/12 lg:ml-3"}
                            />
                            {changeStatusLoading ? (
                                <Loading color={"blue"} w="3rem" mr="6rem" />
                            ) : (
                                <button
                                    className="outline-0 mb-4 max-sm:py-2 lg:ml-3 max-lg:self-start self-end w-24 h-10 inline-block leading-6 text-green-50 font-bold text-xl text-center bg-blue-500 rounded-md"
                                    type="submit"
                                >
                                    Change
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="flex flex-col mt-12">
                        <label className="mb-1 font-bold text-coolGray-800">Feedback</label>
                        {getFeedBackLoading ? (
                            <Loading color={"blue"} w="3rem" mr="14rem" />
                        ) : (
                            <button
                                className="outline-0 mb-4 max-sm:py-2 lg:ml-3 self-start  w-20 h-10 inline-block leading-6 text-green-50 font-bold text-xl text-center bg-blue-500 rounded-md"
                                type="button"
                                onClick={getFeedBackHandler}
                            >
                                Get
                            </button>
                        )}
                    </div>
                    <div className="flex flex-col mt-12">
                        <label className="mb-1 font-bold text-coolGray-800">Reset Tokens</label>
                        <input
                            type="checkbox"
                            className="self-start mb-2"
                            onChange={() => setDisabled((p) => !p)}
                        ></input>
                        {resetTokensLoading ? (
                            <Loading color={"blue"} w="3rem" mr="14rem" />
                        ) : (
                            <button
                                className="outline-0 mb-4 max-sm:py-2 lg:ml-3  self-start w-20 h-10 inline-block leading-6 text-green-50 font-bold text-xl text-center bg-blue-500 rounded-md"
                                type="button"
                                disabled={disabled}
                                onClick={resetTokensHandler}
                            >
                                Reset
                            </button>
                        )}
                    </div>
                    <form onSubmit={getSinglePostHandler} className="flex mt-12">
                        <CreatePostInput
                            title="Post search by ID"
                            type="text"
                            id="postsearchbyid"
                            placeholder="post id"
                            value={formData?.postsearchbyid}
                            onChange={handleChange}
                            span=""
                            basis={"basis-3/12"}
                        />
                        {singlePostLoading ? (
                            <Loading color={"blue"} w="3rem" mt="1.4rem" ml="1.5rem" />
                        ) : (
                            <button
                                className="outline-0 mb-4 ml-3 self-end  w-20 h-10 inline-block leading-6 text-white font-bold text-xl text-center bg-blue-500  rounded-md"
                                type="submit"
                            >
                                Get
                            </button>
                        )}
                    </form>
                    <form onSubmit={getUserInfoHandler} className="flex mt-12">
                        <CreatePostInput
                            title="User search by ID"
                            type="text"
                            id="userinfoid"
                            placeholder="user id"
                            value={formData?.userinfoid}
                            onChange={handleChange}
                            span=""
                            basis={"basis-3/12"}
                        />
                        {fetchUserLoading ? (
                            <Loading color={"blue"} w="3rem" mt="1.4rem" ml="1.5rem" />
                        ) : (
                            <button
                                className="outline-0 mb-4 ml-3 self-end  w-20 h-10 inline-block leading-6 text-white font-bold text-xl text-center bg-blue-500  rounded-md"
                                type="submit"
                            >
                                Get
                            </button>
                        )}
                    </form>
                </section>
            </section>
        </div>
    );
}

export default AdminPannel;
