import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { resetErrorAction, resetLoadingAction, resetSuccessAction } from "./globalSlice";
import { baseURL } from "../../utils/BaseURL";

const INITIAL_STATE = {
    loading: false,
    error: false,
    success: false,
    posts: [],
    post: null,
    postSaveLoading: false,
    postSaveSuccess: false,
    postSaveError: false,
    searchSaveSuccess: false,
    searchSaveError: false,
    singlePostLoading: false,
    singlePostError: false,
    singlePostSuccess: false,
    situationOptions: [
        { value: "lost", label: "I lost" },
        { value: "found", label: "I found" },
        { value: "", label: "all" },
    ],
    manufacturerOptions: [
        { value: "", label: "all" },
        { value: "3Com", label: "3Com" },
        { value: "Abocom", label: "Abocom" },
        { value: "Acer", label: "Acer" },
        { value: "Acorn", label: "Acorn" },
        { value: "Advan", label: "Advan" },
        { value: "AdvanceTC", label: "AdvanceTC" },
        { value: "Ainol", label: "Ainol" },
        { value: "Airis", label: "Airis" },
        { value: "Airo", label: "Airo" },
        { value: "Akai", label: "Akai" },
        { value: "Akortek", label: "Akortek" },
        { value: "Akua Mobile", label: "Akua Mobile" },
        { value: "Alcatel", label: "Alcatel" },
        { value: "Alcor", label: "Alcor" },
        { value: "AllCall", label: "AllCall" },
        { value: "Allview", label: "Allview" },
        { value: "Altek", label: "Altek" },
        { value: "Altina", label: "Altina" },
        { value: "Amazon", label: "Amazon" },
        { value: "Amoi", label: "Amoi" },
        { value: "AnexTEK", label: "AnexTEK" },
        { value: "AnyDATA", label: "AnyDATA" },
        { value: "Apple", label: "Apple" },
        { value: "Archos", label: "Archos" },
        { value: "Argox", label: "Argox" },
        { value: "Arima", label: "Arima" },
        { value: "ARP Mobile", label: "ARP Mobile" },
        { value: "Ascent", label: "Ascent" },
        { value: "Asus", label: "Asus" },
        { value: "Athesi", label: "Athesi" },
        { value: "Audiovox", label: "Audiovox" },
        { value: "Axia", label: "Axia" },
        { value: "Axioo", label: "Axioo" },
        { value: "Azumi Mobile", label: "Azumi Mobile" },
        { value: "Babiken", label: "Babiken" },
        { value: "BBK", label: "BBK" },
        { value: "BenQ", label: "BenQ" },
        { value: "BenQ-Siemens", label: "BenQ-Siemens" },
        { value: "Billion", label: "Billion" },
        { value: "Bkav", label: "Bkav" },
        { value: "Blackview", label: "Blackview" },
        { value: "Blaupunkt", label: "Blaupunkt" },
        { value: "Blu", label: "Blu" },
        { value: "Bluboo", label: "Bluboo" },
        { value: "Bluebird", label: "Bluebird" },
        { value: "BN", label: "BN" },
        { value: "Boeing", label: "Boeing" },
        { value: "Boss", label: "Boss" },
        { value: "BQ", label: "BQ" },
        { value: "Bsquare", label: "Bsquare" },
        { value: "BungBungame", label: "BungBungame" },
        { value: "Bush Mobile", label: "Bush Mobile" },
        { value: "Camangi", label: "Camangi" },
        { value: "Casio", label: "Casio" },
        { value: "Casper", label: "Casper" },
        { value: "Caterpillar", label: "Caterpillar" },
        { value: "Celkon", label: "Celkon" },
        { value: "Centric", label: "Centric" },
        { value: "Changjiang", label: "Changjiang" },
        { value: "Cherry Mobile", label: "Cherry Mobile" },
        { value: "China Mobile", label: "China Mobile" },
        { value: "Cincinnati Bell", label: "Cincinnati Bell" },
        { value: "Cingular", label: "Cingular" },
        { value: "CipherLab", label: "CipherLab" },
        { value: "Clearview", label: "Clearview" },
        { value: "Colorovo", label: "Colorovo" },
        { value: "Colors Mobile", label: "Colors Mobile" },
        { value: "Commodore", label: "Commodore" },
        { value: "Commtiva", label: "Commtiva" },
        { value: "Compaq", label: "Compaq" },
        { value: "CompuLab", label: "CompuLab" },
        { value: "ConCorde", label: "ConCorde" },
        { value: "Condor", label: "Condor" },
        { value: "Coolpad", label: "Coolpad" },
        { value: "Coship", label: "Coship" },
        { value: "Covia", label: "Covia" },
        { value: "Cowon", label: "Cowon" },
        { value: "Coxion", label: "Coxion" },
        { value: "Creative", label: "Creative" },
        { value: "Creo", label: "Creo" },
        { value: "Cube", label: "Cube" },
        { value: "Cubot", label: "Cubot" },
        { value: "CyberBank", label: "CyberBank" },
        { value: "Dallab", label: "Dallab" },
        { value: "DAP", label: "DAP" },
        { value: "Data Evolution", label: "Data Evolution" },
        { value: "Datalogic Mobile", label: "Datalogic Mobile" },
        { value: "Datang", label: "Datang" },
        { value: "DataWind", label: "DataWind" },
        { value: "Daxian", label: "Daxian" },
        { value: "Dell", label: "Dell" },
        { value: "Diamond", label: "Diamond" },
        { value: "Diesel", label: "Diesel" },
        { value: "Diginnos Mobile", label: "Diginnos Mobile" },
        { value: "Disney Mobile", label: "Disney Mobile" },
        { value: "Doogee", label: "Doogee" },
        { value: "Doov", label: "Doov" },
        { value: "Dopod", label: "Dopod" },
        { value: "Doro", label: "Doro" },
        { value: "DragonBox", label: "DragonBox" },
        { value: "DreimGO", label: "DreimGO" },
        { value: "E-TEN", label: "E-TEN" },
        { value: "EinsUndEins", label: "EinsUndEins" },
        { value: "Eken", label: "Eken" },
        { value: "Elephone", label: "Elephone" },
        { value: "Emobile", label: "Emobile" },
        { value: "Emporio Armani", label: "Emporio Armani" },
        { value: "Energizer", label: "Energizer" },
        { value: "enTourage", label: "enTourage" },
        { value: "Ericsson", label: "Ericsson" },
        { value: "Essential", label: "Essential" },
        { value: "Everex", label: "Everex" },
        { value: "Evolve", label: "Evolve" },
        { value: "Evolveo", label: "Evolveo" },
        { value: "Exper", label: "Exper" },
        { value: "Explay", label: "Explay" },
        { value: "Faea", label: "Faea" },
        { value: "Fairphone", label: "Fairphone" },
        { value: "FarEasTone", label: "FarEasTone" },
        { value: "Figgers", label: "Figgers" },
        { value: "FIH", label: "FIH" },
        { value: "Firefly Mobile", label: "Firefly Mobile" },
        { value: "FirstOne", label: "FirstOne" },
        { value: "Fly", label: "Fly" },
        { value: "Forsa", label: "Forsa" },
        { value: "Fossil", label: "Fossil" },
        { value: "Four Mobile", label: "Four Mobile" },
        { value: "Fourier", label: "Fourier" },
        { value: "FPT", label: "FPT" },
        { value: "Freelander", label: "Freelander" },
        { value: "Freetel", label: "Freetel" },
        { value: "Fujitsu", label: "Fujitsu" },
        { value: "Fujitsu-Siemens", label: "Fujitsu-Siemens" },
        { value: "Funkertech", label: "Funkertech" },
        { value: "Fxtec", label: "Fxtec" },
        { value: "Gaoxinqi", label: "Gaoxinqi" },
        { value: "Garmin", label: "Garmin" },
        { value: "Garmin-Asus", label: "Garmin-Asus" },
        { value: "Geeksphone", label: "Geeksphone" },
        { value: "General Mobile", label: "General Mobile" },
        { value: "Geofox", label: "Geofox" },
        { value: "Getac", label: "Getac" },
        { value: "GFive", label: "GFive" },
        { value: "Gigabyte", label: "Gigabyte" },
        { value: "Gigaset", label: "Gigaset" },
        { value: "GiONEE", label: "GiONEE" },
        { value: "GlocalMe", label: "GlocalMe" },
        { value: "Goclever", label: "Goclever" },
        { value: "Gome", label: "Gome" },
        { value: "Google", label: "Google" },
        { value: "Goophone", label: "Goophone" },
        { value: "GPD", label: "GPD" },
        { value: "GPLUS", label: "GPLUS" },
        { value: "Gresso", label: "Gresso" },
        { value: "GrunMobile", label: "GrunMobile" },
        { value: "Haier", label: "Haier" },
        { value: "HandEra", label: "HandEra" },
        { value: "Handspring", label: "Handspring" },
        { value: "Hanmac", label: "Hanmac" },
        { value: "Hannspree", label: "Hannspree" },
        { value: "HAUS", label: "HAUS" },
        { value: "Hewlett-Packard", label: "Hewlett-Packard" },
        { value: "Highscreen", label: "Highscreen" },
        { value: "Hisense", label: "Hisense" },
        { value: "Hitachi", label: "Hitachi" },
        { value: "Hivision", label: "Hivision" },
        { value: "HKC", label: "HKC" },
        { value: "Honeywell", label: "Honeywell" },
        { value: "HOTT", label: "HOTT" },
        { value: "HP Palm", label: "HP Palm" },
        { value: "HTC", label: "HTC" },
        { value: "Huawei", label: "Huawei" },
        { value: "Hyundai", label: "Hyundai" },
        { value: "I-Mate", label: "I-Mate" },
        { value: "i-mobile", label: "i-mobile" },
        { value: "i-teq", label: "i-teq" },
        { value: "iBall", label: "iBall" },
        { value: "IBM", label: "IBM" },
        { value: "ICD", label: "ICD" },
        { value: "Iconbit", label: "Iconbit" },
        { value: "iDO", label: "iDO" },
        { value: "Ikimobile", label: "Ikimobile" },
        { value: "iMI", label: "iMI" },
        { value: "iNew", label: "iNew" },
        { value: "Infinix", label: "Infinix" },
        { value: "InFocus", label: "InFocus" },
        { value: "Infomark", label: "Infomark" },
        { value: "InnJoo", label: "InnJoo" },
        { value: "Innos", label: "Innos" },
        { value: "iNO Mobile", label: "iNO Mobile" },
        { value: "INQ", label: "INQ" },
        { value: "Intel", label: "Intel" },
        { value: "Intermec", label: "Intermec" },
        { value: "Interpad", label: "Interpad" },
        { value: "Intex", label: "Intex" },
        { value: "Inventec", label: "Inventec" },
        { value: "iOcean", label: "iOcean" },
        { value: "Itel", label: "Itel" },
        { value: "Ivoomi", label: "Ivoomi" },
        { value: "izenMobile", label: "izenMobile" },
        { value: "Jiayu", label: "Jiayu" },
        { value: "Jinga", label: "Jinga" },
        { value: "Jivi", label: "Jivi" },
        { value: "Joinhand", label: "Joinhand" },
        { value: "JoinTech", label: "JoinTech" },
        { value: "Jolla", label: "Jolla" },
        { value: "JVC", label: "JVC" },
        { value: "JXD", label: "JXD" },
        { value: "K-Touch", label: "K-Touch" },
        { value: "Kaan", label: "Kaan" },
        { value: "Karbonn", label: "Karbonn" },
        { value: "Kate Spade", label: "Kate Spade" },
        { value: "KAZAM", label: "KAZAM" },
        { value: "KDDI", label: "KDDI" },
        { value: "Keecoo", label: "Keecoo" },
        { value: "KNC", label: "KNC" },
        { value: "Kobo", label: "Kobo" },
        { value: "Kodak", label: "Kodak" },
        { value: "Kogan", label: "Kogan" },
        { value: "Konka", label: "Konka" },
        { value: "Konrow", label: "Konrow" },
        { value: "Koobee", label: "Koobee" },
        { value: "Koolnee", label: "Koolnee" },
        { value: "Krome", label: "Krome" },
        { value: "KT", label: "KT" },
        { value: "Kult", label: "Kult" },
        { value: "Kyocera", label: "Kyocera" },
        { value: "Land Rover", label: "Land Rover" },
        { value: "LANIX", label: "LANIX" },
        { value: "Lava", label: "Lava" },
        { value: "LEAGOO", label: "LEAGOO" },
        { value: "LeEco", label: "LeEco" },
        { value: "Lenovo", label: "Lenovo" },
        { value: "lephone", label: "lephone" },
        { value: "LeTV", label: "LeTV" },
        { value: "Leway", label: "Leway" },
        { value: "LG", label: "LG" },
        { value: "Linshof", label: "Linshof" },
        { value: "Lobster", label: "Lobster" },
        { value: "Louis Vuitton", label: "Louis Vuitton" },
        { value: "Lumigon", label: "Lumigon" },
        { value: "Luna", label: "Luna" },
        { value: "LYF", label: "LYF" },
        { value: "M-Horse", label: "M-Horse" },
        { value: "M3Mobile", label: "M3Mobile" },
        { value: "Macoox", label: "Macoox" },
        { value: "MAG", label: "MAG" },
        { value: "Marshall", label: "Marshall" },
        { value: "Maxwest", label: "Maxwest" },
        { value: "Maze", label: "Maze" },
        { value: "Media-Droid", label: "Media-Droid" },
        { value: "Medion", label: "Medion" },
        { value: "Meitu", label: "Meitu" },
        { value: "Meizu", label: "Meizu" },
        { value: "MENQ", label: "MENQ" },
        { value: "Michael Kors", label: "Michael Kors" },
        { value: "Micromax", label: "Micromax" },
        { value: "Microsoft", label: "Microsoft" },
        { value: "Mio", label: "Mio" },
        { value: "Misfit", label: "Misfit" },
        { value: "MiTAC", label: "MiTAC" },
        { value: "Mobiistar", label: "Mobiistar" },
        { value: "Mobile Compia", label: "Mobile Compia" },
        { value: "Mobile Crossing", label: "Mobile Crossing" },
        { value: "Mobinnova", label: "Mobinnova" },
        { value: "Mobvoi", label: "Mobvoi" },
        { value: "Mogu", label: "Mogu" },
        { value: "Montblanc", label: "Montblanc" },
        { value: "Motorola", label: "Motorola" },
        { value: "Mouse Computer", label: "Mouse Computer" },
        { value: "Movado", label: "Movado" },
        { value: "MSI", label: "MSI" },
        { value: "Multilaser", label: "Multilaser" },
        { value: "MWg", label: "MWg" },
        { value: "MyPhone", label: "MyPhone" },
        { value: "Navigon", label: "Navigon" },
        { value: "NaviGoo", label: "NaviGoo" },
        { value: "Navon", label: "Navon" },
        { value: "NDrive", label: "NDrive" },
        { value: "NEC", label: "NEC" },
        { value: "Neo", label: "Neo" },
        { value: "Neonode", label: "Neonode" },
        { value: "Neptune", label: "Neptune" },
        { value: "New Balance", label: "New Balance" },
        { value: "Newman", label: "Newman" },
        { value: "Nextway", label: "Nextway" },
        { value: "NGM", label: "NGM" },
        { value: "Nikon", label: "Nikon" },
        { value: "Noa", label: "Noa" },
        { value: "Nokia", label: "Nokia" },
        { value: "Nomu", label: "Nomu" },
        { value: "Nothing", label: "Nothing" },
        { value: "Notion Ink", label: "Notion Ink" },
        { value: "NTT DoCoMo", label: "NTT DoCoMo" },
        { value: "NUU", label: "NUU" },
        { value: "NVIDIA", label: "NVIDIA" },
        { value: "O+", label: "O+" },
        { value: "O2", label: "O2" },
        { value: "Obi Worldphone", label: "Obi Worldphone" },
        { value: "Okwap", label: "Okwap" },
        { value: "OKWU", label: "OKWU" },
        { value: "Olivetti", label: "Olivetti" },
        { value: "Olympus", label: "Olympus" },
        { value: "Omate", label: "Omate" },
        { value: "Onda", label: "Onda" },
        { value: "One Five", label: "One Five" },
        { value: "OnePlus", label: "OnePlus" },
        { value: "Onkyo", label: "Onkyo" },
        { value: "Oppo", label: "Oppo" },
        { value: "Opticon", label: "Opticon" },
        { value: "Optima", label: "Optima" },
        { value: "Optimus", label: "Optimus" },
        { value: "Orange", label: "Orange" },
        { value: "Oregon Scientific", label: "Oregon Scientific" },
        { value: "ORSiO", label: "ORSiO" },
        { value: "Oukitel", label: "Oukitel" },
        { value: "Overmax", label: "Overmax" },
        { value: "Owen", label: "Owen" },
        { value: "Palm", label: "Palm" },
        { value: "PalmOne", label: "PalmOne" },
        { value: "Panasonic", label: "Panasonic" },
        { value: "Pantech", label: "Pantech" },
        { value: "Pebble", label: "Pebble" },
        { value: "Pepsi", label: "Pepsi" },
        { value: "Pharos", label: "Pharos" },
        { value: "Philips", label: "Philips" },
        { value: "Pioneer", label: "Pioneer" },
        { value: "PiPO", label: "PiPO" },
        { value: "Pixelphone", label: "Pixelphone" },
        { value: "Planet Computers", label: "Planet Computers" },
        { value: "Plum Mobile", label: "Plum Mobile" },
        { value: "Polar", label: "Polar" },
        { value: "Polaroid", label: "Polaroid" },
        { value: "Polytron", label: "Polytron" },
        { value: "Poptel", label: "Poptel" },
        { value: "Positivo", label: "Positivo" },
        { value: "PPTV", label: "PPTV" },
        { value: "Prestigio", label: "Prestigio" },
        { value: "Protruly", label: "Protruly" },
        { value: "Psion", label: "Psion" },
        { value: "Psion Teklogix", label: "Psion Teklogix" },
        { value: "Q-Mobile", label: "Q-Mobile" },
        { value: "QiGi", label: "QiGi" },
        { value: "Qihoo 360", label: "Qihoo 360" },
        { value: "QiKU", label: "QiKU" },
        { value: "QMobile", label: "QMobile" },
        { value: "Qool", label: "Qool" },
        { value: "Qtek", label: "Qtek" },
        { value: "Qualcomm", label: "Qualcomm" },
        { value: "Quantum", label: "Quantum" },
        { value: "Ramos", label: "Ramos" },
        { value: "Razer", label: "Razer" },
        { value: "RealAegis", label: "RealAegis" },
        { value: "RED", label: "RED" },
        { value: "Red Bull Mobile", label: "Red Bull Mobile" },
        { value: "Reliance", label: "Reliance" },
        { value: "RIM", label: "RIM" },
        { value: "Ringing Bells", label: "Ringing Bells" },
        { value: "Rogers", label: "Rogers" },
        { value: "RoverPC", label: "RoverPC" },
        { value: "RoyalTek", label: "RoyalTek" },
        { value: "Royole", label: "Royole" },
        { value: "Safako", label: "Safako" },
        { value: "Sagem", label: "Sagem" },
        { value: "Samsung", label: "Samsung" },
        { value: "Sanyo", label: "Sanyo" },
        { value: "Saygus", label: "Saygus" },
        { value: "Sencor", label: "Sencor" },
        { value: "Sendo", label: "Sendo" },
        { value: "SFR", label: "SFR" },
        { value: "Sharp", label: "Sharp" },
        { value: "Sharper Image", label: "Sharper Image" },
        { value: "Shenzhen Hidiyin", label: "Shenzhen Hidiyin" },
        { value: "Sico", label: "Sico" },
        { value: "Siemens", label: "Siemens" },
        { value: "Sierra Wireless", label: "Sierra Wireless" },
        { value: "Silent Circle", label: "Silent Circle" },
        { value: "Silicon-i", label: "Silicon-i" },
        { value: "Simputer Trust", label: "Simputer Trust" },
        { value: "Simvalley Mobile", label: "Simvalley Mobile" },
        { value: "Siswoo", label: "Siswoo" },
        { value: "Sitronics", label: "Sitronics" },
        { value: "Smartfren", label: "Smartfren" },
        { value: "Smartisan", label: "Smartisan" },
        { value: "SmartQ", label: "SmartQ" },
        { value: "SMiT", label: "SMiT" },
        { value: "Snail", label: "Snail" },
        { value: "Socket Mobile", label: "Socket Mobile" },
        { value: "SoftBank", label: "SoftBank" },
        { value: "Sonim", label: "Sonim" },
        { value: "Sony", label: "Sony" },
        { value: "Sony Ericsson", label: "Sony Ericsson" },
        { value: "Soulycin ", label: "Soulycin " },
        { value: "Spice", label: "Spice" },
        { value: "Sprint", label: "Sprint" },
        { value: "Starmobile", label: "Starmobile" },
        { value: "STK", label: "STK" },
        { value: "Stuart Hughes", label: "Stuart Hughes" },
        { value: "Sugar", label: "Sugar" },
        { value: "Sunno", label: "Sunno" },
        { value: "Swipe", label: "Swipe" },
        { value: "Swisscom", label: "Swisscom" },
        { value: "Symbol", label: "Symbol" },
        { value: "Symorp", label: "Symorp" },
        { value: "T-Mobile", label: "T-Mobile" },
        { value: "TAG Heuer", label: "TAG Heuer" },
        { value: "Tapwave", label: "Tapwave" },
        { value: "Tatung", label: "Tatung" },
        { value: "TCL", label: "TCL" },
        { value: "TechFaith", label: "TechFaith" },
        { value: "Teclast", label: "Teclast" },
        { value: "Tecno Mobile", label: "Tecno Mobile" },
        { value: "TELE System", label: "TELE System" },
        { value: "Telekom", label: "Telekom" },
        { value: "Telstra", label: "Telstra" },
        { value: "Tencent", label: "Tencent" },
        { value: "Tesco", label: "Tesco" },
        { value: "Tesla", label: "Tesla" },
        { value: "The Medical Phone", label: "The Medical Phone" },
        { value: "ThinkNavi", label: "ThinkNavi" },
        { value: "THL", label: "THL" },
        { value: "ThreeK", label: "ThreeK" },
        { value: "Thuraya", label: "Thuraya" },
        { value: "Tibo", label: "Tibo" },
        { value: "Tinnos", label: "Tinnos" },
        { value: "TMN", label: "TMN" },
        { value: "Tonino Lamborghini", label: "Tonino Lamborghini" },
        { value: "TORQ", label: "TORQ" },
        { value: "Toshiba", label: "Toshiba" },
        { value: "TP-Link", label: "TP-Link" },
        { value: "Trimble", label: "Trimble" },
        { value: "Trinity", label: "Trinity" },
        { value: "Trium", label: "Trium" },
        { value: "TrueSmart", label: "TrueSmart" },
        { value: "Turing", label: "Turing" },
        { value: "Typhoon", label: "Typhoon" },
        { value: "U.S. Robotics", label: "U.S. Robotics" },
        { value: "Ubik", label: "Ubik" },
        { value: "UBiQUiO", label: "UBiQUiO" },
        { value: "Uhans", label: "Uhans" },
        { value: "uleFone", label: "uleFone" },
        { value: "Umeox", label: "Umeox" },
        { value: "UMI", label: "UMI" },
        { value: "Unihertz", label: "Unihertz" },
        { value: "Uniscope", label: "Uniscope" },
        { value: "Unitech", label: "Unitech" },
        { value: "UPQ", label: "UPQ" },
        { value: "UTStarcom", label: "UTStarcom" },
        { value: "Vadem", label: "Vadem" },
        { value: "VAIO", label: "VAIO" },
        { value: "VCHOK", label: "VCHOK" },
        { value: "Vector", label: "Vector" },
        { value: "Verizon", label: "Verizon" },
        { value: "Vernee", label: "Vernee" },
        { value: "Vertu", label: "Vertu" },
        { value: "verykool", label: "verykool" },
        { value: "Verzio", label: "Verzio" },
        { value: "Vestel", label: "Vestel" },
        { value: "Videocon", label: "Videocon" },
        { value: "Vido", label: "Vido" },
        { value: "ViewSonic", label: "ViewSonic" },
        { value: "Viliv", label: "Viliv" },
        { value: "VinSmart", label: "VinSmart" },
        { value: "Vitsmo", label: "Vitsmo" },
        { value: "Vizio", label: "Vizio" },
        { value: "VKWorld", label: "VKWorld" },
        { value: "Vodafone", label: "Vodafone" },
        { value: "Walton", label: "Walton" },
        { value: "Wanxin", label: "Wanxin" },
        { value: "WayteQ", label: "WayteQ" },
        { value: "WellcoM", label: "WellcoM" },
        { value: "Wiko", label: "Wiko" },
        { value: "Wileyfox", label: "Wileyfox" },
        { value: "Willcom", label: "Willcom" },
        { value: "Xiaomi", label: "Xiaomi" },
        { value: "Xplore", label: "Xplore" },
        { value: "Yakumo", label: "Yakumo" },
        { value: "Yamada Denki", label: "Yamada Denki" },
        { value: "Yangtze", label: "Yangtze" },
        { value: "Yarvik", label: "Yarvik" },
        { value: "Yatay", label: "Yatay" },
        { value: "Yezz", label: "Yezz" },
        { value: "Yota", label: "Yota" },
        { value: "Zeblaze", label: "Zeblaze" },
        { value: "Zebra", label: "Zebra" },
        { value: "Zenum", label: "Zenum" },
        { value: "Ziox", label: "Ziox" },
        { value: "Ziss", label: "Ziss" },
        { value: "Zopo", label: "Zopo" },
        { value: "ZTE", label: "ZTE" },
        { value: "ZUK", label: "ZUK" },
        { value: "ZyXel", label: "ZyXel" },
    ],
    devicetypeOptions: [
        { value: "", label: "all" },
        { value: "phone", label: "phone" },
        { value: "laptop", label: "laptop" },
        { value: "tablet", label: "tablet" },
    ],
    colorOptions: [
        { value: "", label: "all" },
        { value: "black", label: "black" },
        { value: "white", label: "white" },
        { value: "gray", label: "gray" },
        { value: "silver", label: "silver" },
        { value: "gold", label: "gold" },
        { value: "brown", label: "brown" },
        { value: "violet", label: "violet" },
        { value: "indigo", label: "indigo" },
        { value: "gopurple", label: "purple" },
        { value: "pink", label: "pink" },
        { value: "blue", label: "blue" },
        { value: "green", label: "green" },
        { value: "yellow", label: "yellow" },
        { value: "orange", label: "orange" },
        { value: "red", label: "red" },
        { value: "olive", label: "olive" },
        { value: "sand", label: "sand" },
    ],
    countryOptions: [
        { value: "", label: "all" },
        {
            value: "Afghanistan",
            label: "Afghanistan",
        },
        {
            value: "Albania",
            label: "Albania",
        },
        {
            value: "Algeria",
            label: "Algeria",
        },
        {
            value: "American Samoa",
            label: "American Samoa",
        },
        {
            value: "Andorra",
            label: "Andorra",
        },
        {
            value: "Angola",
            label: "Angola",
        },
        {
            value: "Anguilla",
            label: "Anguilla",
        },
        {
            value: "Antarctica",
            label: "Antarctica",
        },
        {
            value: "Antigua and Barbuda",
            label: "Antigua and Barbuda",
        },
        {
            value: "Argentina",
            label: "Argentina",
        },
        {
            value: "Armenia",
            label: "Armenia",
        },
        {
            value: "Aruba",
            label: "Aruba",
        },
        {
            value: "Australia",
            label: "Australia",
        },
        {
            value: "Austria",
            label: "Austria",
        },
        {
            value: "Azerbaijan",
            label: "Azerbaijan",
        },
        {
            value: "Bahamas",
            label: "Bahamas",
        },
        {
            value: "Bahrain",
            label: "Bahrain",
        },
        {
            value: "Bangladesh",
            label: "Bangladesh",
        },
        {
            value: "Barbados",
            label: "Barbados",
        },
        {
            value: "Belarus",
            label: "Belarus",
        },
        {
            value: "Belgium",
            label: "Belgium",
        },
        {
            value: "Belize",
            label: "Belize",
        },
        {
            value: "Benin",
            label: "Benin",
        },
        {
            value: "Bermuda",
            label: "Bermuda",
        },
        {
            value: "Bhutan",
            label: "Bhutan",
        },
        {
            value: "Bolivia",
            label: "Bolivia",
        },
        {
            value: "Bonaire, Saint Eustatius and Saba ",
            label: "Bonaire, Saint Eustatius and Saba ",
        },
        {
            value: "Bosnia and Herzegovina",
            label: "Bosnia and Herzegovina",
        },
        {
            value: "Botswana",
            label: "Botswana",
        },
        {
            value: "Bouvet Island",
            label: "Bouvet Island",
        },
        {
            value: "Brazil",
            label: "Brazil",
        },
        {
            value: "British Indian Ocean Territory",
            label: "British Indian Ocean Territory",
        },
        {
            value: "British Virgin Islands",
            label: "British Virgin Islands",
        },
        {
            value: "Brunei",
            label: "Brunei",
        },
        {
            value: "Bulgaria",
            label: "Bulgaria",
        },
        {
            value: "Burkina Faso",
            label: "Burkina Faso",
        },
        {
            value: "Burundi",
            label: "Burundi",
        },
        {
            value: "Cambodia",
            label: "Cambodia",
        },
        {
            value: "Cameroon",
            label: "Cameroon",
        },
        {
            value: "Canada",
            label: "Canada",
        },
        {
            value: "Cape Verde",
            label: "Cape Verde",
        },
        {
            value: "Cayman Islands",
            label: "Cayman Islands",
        },
        {
            value: "Central African Republic",
            label: "Central African Republic",
        },
        {
            value: "Chad",
            label: "Chad",
        },
        {
            value: "Chile",
            label: "Chile",
        },
        {
            value: "China",
            label: "China",
        },
        {
            value: "Christmas Island",
            label: "Christmas Island",
        },
        {
            value: "Cocos (Keeling) Islands",
            label: "Cocos (Keeling) Islands",
        },
        {
            value: "Cocos Islands",
            label: "Cocos Islands",
        },
        {
            value: "Colombia",
            label: "Colombia",
        },
        {
            value: "Comoros",
            label: "Comoros",
        },
        {
            value: "Congo",
            label: "Congo",
        },
        {
            value: "Cook Islands",
            label: "Cook Islands",
        },
        {
            value: "Costa Rica",
            label: "Costa Rica",
        },
        {
            value: "Croatia",
            label: "Croatia",
        },
        {
            value: "Cuba",
            label: "Cuba",
        },
        {
            value: "Curacao",
            label: "Curacao",
        },
        {
            value: "Cyprus",
            label: "Cyprus",
        },
        {
            value: "Czech Republic",
            label: "Czech Republic",
        },
        {
            value: "Democratic Republic of the Congo",
            label: "Democratic Republic of the Congo",
        },
        {
            value: "Denmark",
            label: "Denmark",
        },
        {
            value: "Djibouti",
            label: "Djibouti",
        },
        {
            value: "Dominica",
            label: "Dominica",
        },
        {
            value: "Dominican Republic",
            label: "Dominican Republic",
        },
        {
            value: "Ecuador",
            label: "Ecuador",
        },
        {
            value: "Egypt",
            label: "Egypt",
        },
        {
            value: "El Salvador",
            label: "El Salvador",
        },
        {
            value: "Equatorial Guinea",
            label: "Equatorial Guinea",
        },
        {
            value: "Eritrea",
            label: "Eritrea",
        },
        {
            value: "Estonia",
            label: "Estonia",
        },
        {
            value: "Ethiopia",
            label: "Ethiopia",
        },
        {
            value: "Falkland Islands",
            label: "Falkland Islands",
        },
        {
            value: "Faroe Islands",
            label: "Faroe Islands",
        },
        {
            value: "Fiji",
            label: "Fiji",
        },
        {
            value: "Finland",
            label: "Finland",
        },
        {
            value: "France",
            label: "France",
        },
        {
            value: "French Polynesia",
            label: "French Polynesia",
        },
        {
            value: "French Southern Territories",
            label: "French Southern Territories",
        },
        {
            value: "Gabon",
            label: "Gabon",
        },
        {
            value: "Gambia",
            label: "Gambia",
        },
        {
            value: "Georgia",
            label: "Georgia",
        },
        {
            value: "Germany",
            label: "Germany",
        },
        {
            value: "Ghana",
            label: "Ghana",
        },
        {
            value: "Gibraltar",
            label: "Gibraltar",
        },
        {
            value: "Greece",
            label: "Greece",
        },
        {
            value: "Greenland",
            label: "Greenland",
        },
        {
            value: "Grenada",
            label: "Grenada",
        },
        {
            value: "Guadeloupe",
            label: "Guadeloupe",
        },
        {
            value: "Guam",
            label: "Guam",
        },
        {
            value: "Guatemala",
            label: "Guatemala",
        },
        {
            value: "Guernsey",
            label: "Guernsey",
        },
        {
            value: "Guinea",
            label: "Guinea",
        },
        {
            value: "Guinea-Bissau",
            label: "Guinea-Bissau",
        },
        {
            value: "Guyana",
            label: "Guyana",
        },
        {
            value: "Haiti",
            label: "Haiti",
        },
        {
            value: "Heard Island and McDonald Islands",
            label: "Heard Island and McDonald Islands",
        },
        {
            value: "Honduras",
            label: "Honduras",
        },
        {
            value: "Hong Kong",
            label: "Hong Kong",
        },
        {
            value: "Hungary",
            label: "Hungary",
        },
        {
            value: "Iceland",
            label: "Iceland",
        },
        {
            value: "India",
            label: "India",
        },
        {
            value: "Indonesia",
            label: "Indonesia",
        },
        {
            value: "Iran",
            label: "Iran",
        },
        {
            value: "Iraq",
            label: "Iraq",
        },
        {
            value: "Ireland",
            label: "Ireland",
        },
        {
            value: "Isle of Man",
            label: "Isle of Man",
        },
        {
            value: "Israel",
            label: "Israel",
        },
        {
            value: "Italy",
            label: "Italy",
        },
        {
            value: "Ivory Coast",
            label: "Ivory Coast",
        },
        {
            value: "Jamaica",
            label: "Jamaica",
        },
        {
            value: "Japan",
            label: "Japan",
        },
        {
            value: "Jersey",
            label: "Jersey",
        },
        {
            value: "Jordan",
            label: "Jordan",
        },
        {
            value: "Kazakhstan",
            label: "Kazakhstan",
        },
        {
            value: "Kenya",
            label: "Kenya",
        },
        {
            value: "Kiribati",
            label: "Kiribati",
        },
        {
            value: "Kosovo",
            label: "Kosovo",
        },
        {
            value: "Kuwait",
            label: "Kuwait",
        },
        {
            value: "Kyrgyzstan",
            label: "Kyrgyzstan",
        },
        {
            value: "Laos",
            label: "Laos",
        },
        {
            value: "Latvia",
            label: "Latvia",
        },
        {
            value: "Lebanon",
            label: "Lebanon",
        },
        {
            value: "Lesotho",
            label: "Lesotho",
        },
        {
            value: "Liberia",
            label: "Liberia",
        },
        {
            value: "Libya",
            label: "Libya",
        },
        {
            value: "Liechtenstein",
            label: "Liechtenstein",
        },
        {
            value: "Lithuania",
            label: "Lithuania",
        },
        {
            value: "Luxembourg",
            label: "Luxembourg",
        },
        {
            value: "Macau",
            label: "Macau",
        },
        {
            value: "Macedonia",
            label: "Macedonia",
        },
        {
            value: "Madagascar",
            label: "Madagascar",
        },
        {
            value: "Malawi",
            label: "Malawi",
        },
        {
            value: "Malaysia",
            label: "Malaysia",
        },
        {
            value: "Maldives",
            label: "Maldives",
        },
        {
            value: "Mali",
            label: "Mali",
        },
        {
            value: "Malta",
            label: "Malta",
        },
        {
            value: "Marshall Islands",
            label: "Marshall Islands",
        },
        {
            value: "Martinique",
            label: "Martinique",
        },
        {
            value: "Mauritania",
            label: "Mauritania",
        },
        {
            value: "Mauritius",
            label: "Mauritius",
        },
        {
            value: "Mayotte",
            label: "Mayotte",
        },
        {
            value: "Mexico",
            label: "Mexico",
        },
        {
            value: "Micronesia",
            label: "Micronesia",
        },
        {
            value: "Moldova",
            label: "Moldova",
        },
        {
            value: "Monaco",
            label: "Monaco",
        },
        {
            value: "Mongolia",
            label: "Mongolia",
        },
        {
            value: "Montenegro",
            label: "Montenegro",
        },
        {
            value: "Montserrat",
            label: "Montserrat",
        },
        {
            value: "Morocco",
            label: "Morocco",
        },
        {
            value: "Mozambique",
            label: "Mozambique",
        },
        {
            value: "Myanmar",
            label: "Myanmar",
        },
        {
            value: "Namibia",
            label: "Namibia",
        },
        {
            value: "Nauru",
            label: "Nauru",
        },
        {
            value: "Nepal",
            label: "Nepal",
        },
        {
            value: "Netherlands",
            label: "Netherlands",
        },
        {
            value: "New Caledonia",
            label: "New Caledonia",
        },
        {
            value: "New Zealand",
            label: "New Zealand",
        },
        {
            value: "Nicaragua",
            label: "Nicaragua",
        },
        {
            value: "Niger",
            label: "Niger",
        },
        {
            value: "Nigeria",
            label: "Nigeria",
        },
        {
            value: "Niue",
            label: "Niue",
        },
        {
            value: "Norfolk Island",
            label: "Norfolk Island",
        },
        {
            value: "North Korea",
            label: "North Korea",
        },
        {
            value: "Northern Mariana Islands",
            label: "Northern Mariana Islands",
        },
        {
            value: "Norway",
            label: "Norway",
        },
        {
            value: "Oman",
            label: "Oman",
        },
        {
            value: "Pakistan",
            label: "Pakistan",
        },
        {
            value: "Palau",
            label: "Palau",
        },
        {
            value: "Palestinian Territory",
            label: "Palestinian Territory",
        },
        {
            value: "Panama",
            label: "Panama",
        },
        {
            value: "Papua New Guinea",
            label: "Papua New Guinea",
        },
        {
            value: "Paraguay",
            label: "Paraguay",
        },
        {
            value: "Peru",
            label: "Peru",
        },
        {
            value: "Philippines",
            label: "Philippines",
        },
        {
            value: "Pitcairn",
            label: "Pitcairn",
        },
        {
            value: "Poland",
            label: "Poland",
        },
        {
            value: "Portugal",
            label: "Portugal",
        },
        {
            value: "Puerto Rico",
            label: "Puerto Rico",
        },
        {
            value: "Qatar",
            label: "Qatar",
        },
        {
            value: "Romania",
            label: "Romania",
        },
        {
            value: "Russia",
            label: "Russia",
        },
        {
            value: "Rwanda",
            label: "Rwanda",
        },
        {
            value: "Réunion",
            label: "Réunion",
        },
        {
            value: "Saint Barthelemy",
            label: "Saint Barthelemy",
        },
        {
            value: "Saint Helena",
            label: "Saint Helena",
        },
        {
            value: "Saint Kitts and Nevis",
            label: "Saint Kitts and Nevis",
        },
        {
            value: "Saint Lucia",
            label: "Saint Lucia",
        },
        {
            value: "Saint Martin",
            label: "Saint Martin",
        },
        {
            value: "Saint Pierre and Miquelon",
            label: "Saint Pierre and Miquelon",
        },
        {
            value: "Saint Vincent and the Grenadines",
            label: "Saint Vincent and the Grenadines",
        },
        {
            value: "Samoa",
            label: "Samoa",
        },
        {
            value: "San Marino",
            label: "San Marino",
        },
        {
            value: "Sao Tome and Principe",
            label: "Sao Tome and Principe",
        },
        {
            value: "Saudi Arabia",
            label: "Saudi Arabia",
        },
        {
            value: "Senegal",
            label: "Senegal",
        },
        {
            value: "Serbia",
            label: "Serbia",
        },
        {
            value: "Seychelles",
            label: "Seychelles",
        },
        {
            value: "Sierra Leone",
            label: "Sierra Leone",
        },
        {
            value: "Singapore",
            label: "Singapore",
        },
        {
            value: "Sint Maarten",
            label: "Sint Maarten",
        },
        {
            value: "Slovakia",
            label: "Slovakia",
        },
        {
            value: "Slovenia",
            label: "Slovenia",
        },
        {
            value: "Solomon Islands",
            label: "Solomon Islands",
        },
        {
            value: "Somalia",
            label: "Somalia",
        },
        {
            value: "South Africa",
            label: "South Africa",
        },
        {
            value: "South Georgia and the South Sandwich Islands",
            label: "South Georgia and the South Sandwich Islands",
        },
        {
            value: "South Korea",
            label: "South Korea",
        },
        {
            value: "South Sudan",
            label: "South Sudan",
        },
        {
            value: "Spain",
            label: "Spain",
        },
        {
            value: "Sri Lanka",
            label: "Sri Lanka",
        },
        {
            value: "Sudan",
            label: "Sudan",
        },
        {
            value: "Suriname",
            label: "Suriname",
        },
        {
            value: "Svalbard and Jan Mayen",
            label: "Svalbard and Jan Mayen",
        },
        {
            value: "Swaziland",
            label: "Swaziland",
        },
        {
            value: "Sweden",
            label: "Sweden",
        },
        {
            value: "Switzerland",
            label: "Switzerland",
        },
        {
            value: "Syria",
            label: "Syria",
        },
        {
            value: "Taiwan",
            label: "Taiwan",
        },
        {
            value: "Tajikistan",
            label: "Tajikistan",
        },
        {
            value: "Tanzania",
            label: "Tanzania",
        },
        {
            value: "Thailand",
            label: "Thailand",
        },
        {
            value: "Timor-Leste",
            label: "Timor-Leste",
        },
        {
            value: "Togo",
            label: "Togo",
        },
        {
            value: "Tokelau",
            label: "Tokelau",
        },
        {
            value: "Tonga",
            label: "Tonga",
        },
        {
            value: "Trinidad and Tobago",
            label: "Trinidad and Tobago",
        },
        {
            value: "Tunisia",
            label: "Tunisia",
        },
        {
            value: "Turkey",
            label: "Turkey",
        },
        {
            value: "Turkmenistan",
            label: "Turkmenistan",
        },
        {
            value: "Turks and Caicos Islands",
            label: "Turks and Caicos Islands",
        },
        {
            value: "Tuvalu",
            label: "Tuvalu",
        },
        {
            value: "U.S. Virgin Islands",
            label: "U.S. Virgin Islands",
        },
        {
            value: "Uganda",
            label: "Uganda",
        },
        {
            value: "Ukraine",
            label: "Ukraine",
        },
        {
            value: "United Arab Emirates",
            label: "United Arab Emirates",
        },
        {
            value: "United Kingdom",
            label: "United Kingdom",
        },
        {
            value: "United States",
            label: "United States",
        },
        {
            value: "United States Minor Outlying Islands",
            label: "United States Minor Outlying Islands",
        },
        {
            value: "Uruguay",
            label: "Uruguay",
        },
        {
            value: "Uzbekistan",
            label: "Uzbekistan",
        },
        {
            value: "Vanuatu",
            label: "Vanuatu",
        },
        {
            value: "Vatican",
            label: "Vatican",
        },
        {
            value: "Vatican City State (Holy See)",
            label: "Vatican City State (Holy See)",
        },
        {
            value: "Venezuela",
            label: "Venezuela",
        },
        {
            value: "Vietnam",
            label: "Vietnam",
        },
        {
            value: "Wallis and Futuna",
            label: "Wallis and Futuna",
        },
        {
            value: "Western Sahara",
            label: "Western Sahara",
        },
        {
            value: "Yemen",
            label: "Yemen",
        },
        {
            value: "Zambia",
            label: "Zambia",
        },
        {
            value: "Zimbabwe",
            label: "Zimbabwe",
        },
    ],
    yearOptions: [
        { value: "", label: "all" },
        { value: "2024", label: "2024" },
        { value: "2023", label: "2023" },
        { value: "2022", label: "2022" },
        { value: "2021", label: "2021" },
        { value: "2020", label: "2020" },
        { value: "2019", label: "2019" },
        { value: "2018", label: "2018" },
        { value: "2017", label: "2017" },
        { value: "2016", label: "2016" },
        { value: "2015", label: "2015" },
        { value: "2014", label: "2014" },
        { value: "2013", label: "2013" },
        { value: "2012", label: "2012" },
        { value: "2011", label: "2011" },
        { value: "2010", label: "2010" },
        { value: "2009", label: "2009" },
        { value: "2008", label: "2008" },
        { value: "2007", label: "2007" },
        { value: "2006", label: "2006" },
        { value: "2005", label: "2005" },
        { value: "2004", label: "2004" },
        { value: "2003", label: "2003" },
        { value: "2002", label: "2002" },
        { value: "2001", label: "2001" },
        { value: "2000", label: "2000" },
    ],
};

//! Fetch posts

export const fetchPostsAction = createAsyncThunk(
    "posts/fetch",
    async ({ page, limit, formData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.get(
                `${baseURL}/posts/fetch?page=${page}&limit=${limit}&searchTitleTerms=${formData?.title}&searchContentTerms=${formData?.content}&situation=${formData?.situation}&devicetype=${formData?.devicetype}&manufacturer=${formData?.manufacturer}&model=${formData?.model}&countryname=${formData?.countryname}&cityname=${formData?.cityname}&color=${formData?.color}&year=${formData?.year}&deviceid=${formData?.deviceid}`,
                config
            );

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! delete posts

export const deletePostAction = createAsyncThunk(
    "posts/delete-post",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.delete(`${baseURL}/posts/delete/${payload}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! fetch single post

export const fetchSinglePostAction = createAsyncThunk(
    "posts/get-post",
    async ({ postid }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState()?.users?.userAuth?.userInfo?.token;
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const { data } = await axios.get(`${baseURL}/posts/fetch/${postid}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! Edit post
export const editPostAction = createAsyncThunk(
    "edit-post",
    async ({ editPostFormData, id }, { rejectWithValue, getState, dispatch }) => {
        try {
            const formData = new FormData();
            formData.append("title", editPostFormData?.title);
            formData.append("content", editPostFormData?.content);
            formData.append("situation", editPostFormData?.situation);
            formData.append("deviceid", editPostFormData?.deviceid);
            formData.append("devicetype", editPostFormData?.devicetype);
            formData.append("manufacturer", editPostFormData?.manufacturer);
            formData.append("model", editPostFormData?.model);
            formData.append("color", editPostFormData?.color);
            formData.append("year", editPostFormData?.year);
            formData.append("countryname", editPostFormData?.countryname);
            formData.append("cityname", editPostFormData?.cityname);
            formData.append("file", editPostFormData?.image);

            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/posts/update/${id}`, formData, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! Create a post
export const addPostAction = createAsyncThunk(
    "add-post",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const formData = new FormData();
            formData.append("title", payload?.title);
            formData.append("content", payload?.content);
            formData.append("situation", payload?.situation);
            formData.append("deviceid", payload?.deviceid);
            formData.append("devicetype", payload?.devicetype);
            formData.append("manufacturer", payload?.manufacturer);
            formData.append("model", payload?.model);
            formData.append("color", payload?.color);
            formData.append("year", payload?.year);
            formData.append("countryname", payload?.countryname);
            formData.append("cityname", payload?.cityname);
            formData.append("file", payload?.image);

            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/posts/create`, formData, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! like posts

export const likePostAction = createAsyncThunk(
    "posts/like/:id",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/posts/likes/${payload}`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! view post count

export const postViewCountAction = createAsyncThunk(
    "posts/post-views",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.put(`${baseURL}/posts/${payload}/post-views-count`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! Save post to favorites
export const savePostAction = createAsyncThunk(
    "save-post",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/posts/save-post/${payload}`, {}, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! Save search to favorites
export const saveSearchAction = createAsyncThunk(
    "save-search",
    async ({ formData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(`${baseURL}/posts/save-search`, formData, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);
//! Delete search from favorites
export const deleteSearchAction = createAsyncThunk(
    "delete-search",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.delete(`${baseURL}/posts/delete-search/${payload}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const PostsSlice = createSlice({
    name: "postsSlice",
    initialState: INITIAL_STATE,
    reducers: {
        logoutPostsSlice: (state) => {
            state = undefined;
        },
    },
    extraReducers: (builder) => {
        //! Delete search from favorites
        builder.addCase(deleteSearchAction.pending, (state, action) => {
            state.searchSaveLoading = true;
        });
        builder.addCase(deleteSearchAction.fulfilled, (state, action) => {
            state.searchSaveSuccess = true;
            state.searchSaveError = null;
            state.searchSaveLoading = false;
        });
        builder.addCase(deleteSearchAction.rejected, (state, action) => {
            state.searchSaveError = action.payload.message;
            state.searchSaveLoading = false;
        });
        //! Save search to favorites

        builder.addCase(saveSearchAction.pending, (state, action) => {
            state.searchSaveLoading = true;
        });
        builder.addCase(saveSearchAction.fulfilled, (state, action) => {
            state.searchSaveSuccess = true;
            state.searchSaveError = null;
            state.searchSaveLoading = false;
        });
        builder.addCase(saveSearchAction.rejected, (state, action) => {
            state.searchSaveLoading = false;
            state.searchSaveError = action.payload.message;
        });
        //! Save post to favorites

        builder.addCase(savePostAction.pending, (state, action) => {
            state.postSaveLoading = true;
        });
        builder.addCase(savePostAction.fulfilled, (state, action) => {
            state.postSaveLoading = false;
            state.postSaveSuccess = true;
            state.postSaveError = null;
            state.post = action.payload;
        });
        builder.addCase(savePostAction.rejected, (state, action) => {
            state.postSaveLoading = false;
            state.postSaveError = action?.payload?.message;
        });
        //! Create a post
        builder.addCase(addPostAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(addPostAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
            state.post = action.payload;
        });
        builder.addCase(addPostAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //! Fetch posts
        builder.addCase(fetchPostsAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchPostsAction.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = true;
            state.posts = action.payload;
        });
        builder.addCase(fetchPostsAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //! delete post
        builder.addCase(deletePostAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deletePostAction.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = true;
        });
        builder.addCase(deletePostAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //! edit post
        builder.addCase(editPostAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(editPostAction.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
        });
        builder.addCase(editPostAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //! fetch single post
        builder.addCase(fetchSinglePostAction.pending, (state, action) => {
            state.singlePostLoading = true;
        });
        builder.addCase(fetchSinglePostAction.fulfilled, (state, action) => {
            state.singlePostLoading = false;
            state.singlePostError = null;
            state.singlePostSuccess = true;
            state.post = action.payload;
        });
        builder.addCase(fetchSinglePostAction.rejected, (state, action) => {
            state.singlePostLoading = false;
            state.singlePostError = action.payload.message;
        });

        //! like post
        builder.addCase(likePostAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(likePostAction.fulfilled, (state, action) => {
            state.loading = false;

            state.error = null;
            state.post = action.payload;
        });
        builder.addCase(likePostAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //! post views count
        builder.addCase(postViewCountAction.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(postViewCountAction.fulfilled, (state, action) => {
            state.loading = false;

            state.error = null;
            state.post = action.payload;
        });
        builder.addCase(postViewCountAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //!reset error action
        builder.addCase(resetErrorAction.fulfilled, (state) => {
            state.error = null;
            state.postSaveError = false;
            state.searchSaveError = false;
            state.singlePostError = false;
        });

        //!reset success action
        builder.addCase(resetSuccessAction.fulfilled, (state) => {
            state.success = false;
            state.postSaveSuccess = false;
            state.searchSaveSuccess = false;
            state.singlePostSuccess = false;
        });

        //!reset loading action
        builder.addCase(resetLoadingAction.fulfilled, (state) => {
            state.loading = false;
            state.postSaveLoading = false;
            state.singlePostLoading = false;
        });
    },
});

const postsReducer = PostsSlice.reducer;
export const { logoutPostsSlice } = PostsSlice.actions;
export default postsReducer;
