import { useDispatch } from "react-redux";
import MainInfo from "./MainInfo";
import { setActivePage } from "../../redux/slices/globalSlice";
import { useEffect } from "react";
import Modal from "../helpers/Modal";

const Home = () => {
    //* Set active page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActivePage(0));
    }, [dispatch]);

    return (
        <Modal>
            <div className="mx-auto max-w-3xl pt-24">
                <MainInfo />
            </div>
        </Modal>
    );
};

export default Home;
