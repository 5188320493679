import React, { useEffect } from "react";
import { setActivePage } from "../../redux/slices/globalSlice";
import { useDispatch } from "react-redux";
import Modal from "../helpers/Modal";

function FAQ() {
    //* Set active page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActivePage(6));
    }, [dispatch]);
    return (
        <Modal>
            <div className="pt-24">
                <h1 className="font-Roboto-Bold text-white px-10 md:px-20 text-4xl font-bold text-center mt-16">
                    Frequently Asked Questions
                </h1>
                <div className="text-2xl md:text-xl lg:text-lg xl:text-xl min-[1920px]:text-2xl min-[2560px]:text-3xl relative z-20 text-white xl:w-5/6 lg:w-vw-90 mt-20 md:py-20 px-5 lg:px-10 md:mx-7 lg:mx-auto lg:border-2 lg:border-gray-200 rounded-md ">
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className="font-bold leading-relaxed mb-4 md:w-5/12 ">
                            What should I do if I lost a device?
                            <br /> How to create post correctly.
                        </h3>

                        <div className="md:w-1/2 mb-16 font-Roboto-Base max-sm:text-base">
                            <span className="font-Roboto-Bold">I</span>f you lost your device first of all, check
                            through our search whether your device is in the list of found ones. If not, create a
                            new "Lost" post, trying to fill out as many fields as possible. The most important
                            fields are IMEI/ID, country and city where you lost the device. In the description of
                            the post, indicate any information that will help identify your device: external
                            defects, screensaver on your desktop, etc.
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className=" mb-4 leading-relaxed font-bold md:w-5/12">
                            What should I do if I find a device?
                            <br /> How to create post correctly.
                        </h3>

                        <div className="md:w-1/2  mb-16 font-Roboto-Base max-sm:text-base">
                            <span className="font-Roboto-Bold">I</span>f you find someone else's device, first of
                            all, check through our search whether it is on the wanted list. If not, create a new
                            post. Fill in as many form fields as possible. In the description, describe in detail
                            the device, its external defects or other identifying features. Write down the return
                            conditions and wait for the person who lost the device to respond to your ad by writing
                            a conmment to your post. You will recieve a mail about that.
                            <br />
                            <span className="font-Roboto-Bold">I</span>f you are not sure who the manufacturer and
                            what model of the device you find is, take a photo of its back panel with your
                            smartphone or computer camera and follow the Google instructions “search in the web by
                            image” at this link{" "}
                            <div className="overflow-hidden font-Roboto-Base">
                                <a
                                    href="https://support.google.com/
                            websearch/answer/1325808?hl=ru&co=GENIE.Platform%3DDesktop&oco=1"
                                    target="blank"
                                    className="font-Roboto-Base text-red-400 hover:text-red-300 "
                                >
                                    https://images.google.com/
                                </a>
                            </div>
                            Or live this or any field empty if you are not sure.
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className=" mb-4 leading-relaxed font-bold md:w-5/12">
                            What should I do if my lost device is in the "Found" list?
                        </h3>

                        <div className="md:w-1/2   mb-16 font-Roboto-Base max-sm:text-base">
                            <span className="font-Roboto-Bold">С</span>ongratulations! There is a high probability
                            that someone found your device! Click on a post to view its details. Next, leave your
                            comment below, where it says that this is your device and you are ready to discuss the
                            details of its transfer. To avoid fraud, we recommend communicating on our platform
                            rather than third-party apps.
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className=" mb-4 leading-relaxed font-bold md:w-5/12">
                            What should I do if my NOT lost device is on the "Lost" list?
                        </h3>

                        <div className="md:w-1/2 font-Roboto-Base mb-16 max-sm:text-base">
                            <span className="font-Roboto-Bold">W</span>e're sorry to hear about this. If you bought
                            a new device from a store, then you have nothing to worry about. Collect all possible
                            information about the purchase (receipt, box), take a photo of it and send it to us at
                            this address along with the post number. We will look into your situation and if all
                            the data is correct, we will delete this post. If you bought this device second-hand,
                            the device could have been stolen and its data entered into the police database. You
                            should immediately contact the owner of the post to resolve this situation.
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className=" mb-4 leading-relaxed font-bold md:w-5/12">How to delete my post?</h3>

                        <div className="md:w-1/2   mb-16 font-Roboto-Base max-sm:text-base">
                            <span className="font-Roboto-Bold">F</span>irst of all, log into your account, then
                            open your personal profile page and click on the post you want to delete. At the bottom
                            of the page that opens, click on the delete post button and confirm your action.
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className=" mb-4 leading-relaxed font-bold md:w-5/12">
                            What do I risk by transferring communication with the user to a third-party messenger?
                        </h3>

                        <div className="md:w-1/2   mb-16 font-Roboto-Base max-sm:text-base">
                            <span className="font-Roboto-Bold">O</span>f course, our platform does not have such
                            wide capabilities for communication between users as many common instant messengers or
                            social networks. If in the correspondence in the comments to the post, you are
                            convinced that it was your device that was found and you trust the person as much as
                            possible, you can switch to any method of communication convenient for you. But
                            remember that scammers are everywhere and correspondence on our website is saved until
                            the post is deleted and you will be able to have evidence of the correspondence rather
                            than it being simply deleted on a third-party service. And remember, never transfer any
                            money in advance to someone who claims to return your device by mail or any other
                            method other than a personal meeting.
                            <span className="font-Roboto-Bold text-red-400">
                                {""} We do not bear any responsibility for the actions you take!
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <h3 className=" mb-4 leading-relaxed font-bold md:w-5/12">
                            How to securely arrange a device handover meeting.
                        </h3>

                        <div className="md:w-1/2   mb-16 font-Roboto-Base max-sm:text-base">
                            <span className="font-Roboto-Bold ">I</span>t wouldn't hurt to be careful on both
                            sides. Choose a crowded public place. Don't come to the meeting alone. Do not rush to
                            give the device back, even if they are ready to give you a reward right away. Make sure
                            that the device belongs to this person and have him unlock it. If the device is
                            unlocked, immediately hand the device over to that person and take the reward, if any,
                            has been claimed. Congratulations! The deal is done! Don't forget to delete the post
                            after this!
                        </div>
                    </div>

                    <div className="flex flex-row justify-between mt-10">
                        <h3 className="text-xl leading-relaxed font-bold md:w-5/12">
                            Didn't find the answer? Contsct with us by{" "}
                            <span className="underline">lostify.feedback@gmail.com</span>
                        </h3>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default FAQ;
