import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../helpers/Modal";
import Loading from "../Alerts/Loading";
import Success from "../Alerts/Success";
import Error from "../Alerts/Error";
import { sendResetPasswordEmail } from "../../redux/slices/usersSlice";

const PasswordResetRequest = () => {
    const { loading, error, isEmailSent } = useSelector((state) => state?.users);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email: "",
    });

    //* Handle form change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //* Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(sendResetPasswordEmail({ formData }));
    };

    useEffect(() => {
        // reset form
        setFormData({
            email: "",
        });
    }, [isEmailSent]);

    return (
        <Modal>
            <form className="flex flex-col items-center justify-center min-h-screen">
                <div className="font-Roboto-Bold w-80 sm:w-96  p-6 bg-white rounded-xl shadow-md mb-20 2xl:mb-40">
                    <h1 className="post?.image text-2xl sm:text-3xl font-bold text-gray-700 text-center mb-6">
                        Reset your password
                    </h1>
                    <p className="text-gray-600 text-center text font-bold mb-6">
                        Enter your email address and we'll send you a link to reset your password.
                    </p>
                    <div className="mb-6 relative">
                        <AiOutlineMail className="absolute text-gray-500 text-2xl top-2 left-2" />
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="font-bold pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            name="email"
                            value={formData?.email}
                            onChange={handleChange}
                        />
                    </div>
                    {loading ? (
                        <Loading color={"blue"} w={40} h={40} />
                    ) : (
                        <button
                            onClick={handleSubmit}
                            className="font-Roboto-Bold  post?.image font-bold w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Send reset link
                        </button>
                    )}
                    {/* Success */}
                    {isEmailSent && (
                        <Success timer={6000} message={`Сonfirmation email sent to ${formData?.email}`} />
                    )}
                    {/* Error */}
                    {error && <Error message={error} />}
                </div>
            </form>
        </Modal>
    );
};

export default PasswordResetRequest;
