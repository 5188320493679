import React, { useCallback, useEffect, useState } from "react";
import Success from "../../../Alerts/Success";
import Loading from "../../../Alerts/Loading";
import CreatePostInput from "../../../Posts/CreatePostInput";
import CreatePostSelect from "../../../Posts/CreatePostSelect";
import { useDispatch, useSelector } from "react-redux";
import { editPostAction } from "../../../../redux/slices/postsSlice";
import Error from "../../../Alerts/Error";
import { fetchCitiesAction } from "../../../../redux/slices/citiesSlice";
import { setShowEditPostModal } from "../../../../redux/slices/globalSlice";
import { userProfileAction } from "../../../../redux/slices/usersSlice";

function EditPostForm() {
    const post = useSelector((state) => state?.global?.postId);
    const dispatch = useDispatch();

    //* Select options
    const { situationOptions, manufacturerOptions, devicetypeOptions, colorOptions, yearOptions, countryOptions } =
        useSelector((state) => state?.posts);
    const { cityList, cityLoading } = useSelector((state) => state?.cities);

    const cityOptions = cityList?.data?.map((item) => {
        return { value: item, label: item };
    });

    //* Initial Form Data state
    const [editPostFormData, seteditPostFormData] = useState({
        situation: post?.situation?.type,
        title: post?.title,
        content: post?.content,
        deviceid: post?.deviceid,
        devicetype: post?.devicetype?.name,
        manufacturer: post?.manufacturer,
        model: post?.model,
        color: post?.color,
        year: post?.year,
        countryname: post?.countryname,
        cityname: post?.cityname || "Choose country",
        image: post?.image,
    });

    //* Fetch cities
    useEffect(() => {
        if (editPostFormData?.countryname !== "") {
            dispatch(fetchCitiesAction(editPostFormData?.countryname));
        }
    }, [dispatch, editPostFormData?.countryname]);

    //* Input handlers

    const handleChange = (e) => {
        seteditPostFormData({ ...editPostFormData, [e.target.name]: e.target.value });
    };
    const imageChangeHandler = (e) => {
        seteditPostFormData({ ...editPostFormData, image: e.target?.files[0] });
    };
    const situationHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, situation: selectedOption.value });
    };
    const deviceTypeHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, devicetype: selectedOption.value });
    };
    const manufacturerHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, manufacturer: selectedOption.value });
    };
    const colorHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, color: selectedOption.value });
    };
    const yearHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, year: selectedOption.value });
    };
    const countryHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, countryname: selectedOption.value });
    };
    const citynameHandleChange = (selectedOption) => {
        seteditPostFormData({ ...editPostFormData, cityname: selectedOption.value });
    };

    //* Error handling
    const { error, success, loading } = useSelector((state) => state?.posts);

    //* Client-side validation
    const [errors, setErrors] = useState({});
    const validateForm = (data) => {
        let errors = {};
        if (!data.title) errors.title = "Title is required";
        if (!data.situation) errors.situation = "Situation is required";
        if (!data.devicetype) errors.devicetype = "Device type is required";
        if (!data.manufacturer) errors.manufacturer = "Manufacturer is required";
        if (!data.countryname) errors.countryname = "Country is required";
        if (!data.cityname) errors.cityname = "City is required";
        return errors;
    };

    //* close modal

    const closeModal = useCallback(() => {
        dispatch(setShowEditPostModal(false));
    }, [dispatch]);

    //* Submit handler

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(editPostFormData);
        const id = post?._id;
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(editPostAction({ editPostFormData, id }));
        }
    };

    useEffect(() => {
        if (success) {
            closeModal();
            dispatch(userProfileAction());
        }
    }, [dispatch, closeModal, success]);

    return (
        <>
            <div className="backdrop" onClick={closeModal}></div>
            <div className="absolute z-20 left-space-1.5 top-space-15 sm:top-space-10 sm:left-space-5 lg:top-space-20 lg:left-space-2.5  xl:left-space-7.5 2xl:left-space-15 2xl:top-space-15 min-[2560px]:left-space-20 min-[2560px]:top-space-25 min-[1920px]:top-space-20">
                <form
                    onSubmit={handleSubmit}
                    className="w-vw-97 sm:w-vw-90  lg:w-vw-95 xl:w-vw-85 2xl:w-vw-70 min-[2560px]:w-vw-60   bg-white px-2 sm:px-10 lg:py-6 sm:py-2 rounded-xl relative "
                >
                    <span onClick={closeModal} className="absolute top-3 right-3 hover:cursor-pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    <h2 className="font-Roboto-Bold mb-10 pt-6 lg:mt-4  text-2xl md:text-2xl lg:text-2xl min-[2560px]:text-4xl text-coolGray-900 font-semibold lg:font-bold text-center">
                        Edit post
                    </h2>

                    <div className="flex flex-col lg:flex-row  justify-between 2xl:mb-10">
                        <CreatePostSelect
                            title={"Situation"}
                            placeholder={editPostFormData?.situation}
                            options={situationOptions}
                            name="situation"
                            onChange={situationHandleChange}
                            errors={errors}
                            basis={"basis-1/12  "}
                        />
                        <CreatePostInput
                            title="Title"
                            type="text"
                            id="title"
                            placeholder="Enter the post title"
                            value={editPostFormData.title}
                            onChange={handleChange}
                            errors={errors}
                            span=""
                            basis={"basis-6/12 "}
                        />
                        <CreatePostInput
                            title="EMEI/ID"
                            type="text"
                            id="deviceid"
                            placeholder="Enter the device EMEI/ID"
                            value={editPostFormData.deviceid}
                            onChange={handleChange}
                            errors={errors}
                            span=""
                            basis={"basis-4/12 "}
                        />
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between 2xl:mb-10">
                        <CreatePostSelect
                            placeholder={editPostFormData?.devicetype}
                            title={"Device Type"}
                            options={devicetypeOptions}
                            name="devicetype"
                            onChange={deviceTypeHandleChange}
                            errors={errors}
                            basis={"basis-2/12 "}
                        />
                        <CreatePostSelect
                            placeholder={
                                editPostFormData?.manufacturer === "null"
                                    ? manufacturerOptions?.value
                                    : editPostFormData?.manufacturer
                            }
                            title={"Manufacturer"}
                            options={manufacturerOptions}
                            name="manufacturer"
                            onChange={manufacturerHandleChange}
                            errors={errors}
                            basis={"basis-2/12 "}
                        />

                        <CreatePostInput
                            title="Model"
                            type="text"
                            id="model"
                            placeholder="Enter model of device "
                            value={editPostFormData.model}
                            onChange={handleChange}
                            errors={errors}
                            span=""
                            basis={"basis-2/12 "}
                        />
                        <CreatePostSelect
                            placeholder={
                                editPostFormData?.color === "null" ? colorOptions?.value : editPostFormData?.color
                            }
                            title={"Color"}
                            options={colorOptions}
                            name="color"
                            onChange={colorHandleChange}
                            errors={errors}
                            basis={"basis-2/12 "}
                        />
                        <CreatePostSelect
                            placeholder={
                                editPostFormData?.year === "null" ? yearOptions?.value : editPostFormData?.year
                            }
                            title={"Year"}
                            options={yearOptions}
                            name="year"
                            onChange={yearHandleChange}
                            errors={errors}
                            basis={"basis-2/12 "}
                        />
                    </div>

                    <div className="flex flex-col lg:flex-row justify-between 2xl:mb-10">
                        <CreatePostSelect
                            placeholder={editPostFormData?.countryname}
                            title={"Country"}
                            options={countryOptions}
                            name="countryname"
                            onChange={countryHandleChange}
                            errors={errors}
                            basis={"basis-2/12 "}
                        />
                        {!cityLoading ? (
                            <CreatePostSelect
                                placeholder={editPostFormData?.cityname}
                                title={"City"}
                                options={cityOptions}
                                name="cityname"
                                onChange={citynameHandleChange}
                                errors={errors}
                                basis={"basis-2/12 "}
                            />
                        ) : (
                            <Loading color="blue" />
                        )}

                        <CreatePostInput
                            accept={"image/png, image/jpeg,image/jpg"}
                            title="Image"
                            type="file"
                            id="image"
                            placeholder=""
                            onChange={imageChangeHandler}
                            errors={errors}
                            span={`(Upload an image only if it shows clear features of the device
                                or if you dont know who is manufacturer.)`}
                            basis={"basis-5/12 overflow-hidden "}
                        />
                    </div>
                    <div className="flex flex-col lg:flex-row justify-between">
                        <CreatePostInput
                            title="Content"
                            type="text"
                            id="content"
                            placeholder="Write your description here"
                            value={editPostFormData?.content}
                            onChange={handleChange}
                            errors={errors}
                            content={true}
                            span=""
                            basis={"basis-11/12"}
                        />
                        <div className="flex  items-center mb-3 lg:mt-3 justify-end basis-3/12">
                            {" "}
                            {loading ? (
                                <Loading color="blue" mr={"3rem"} />
                            ) : (
                                <button
                                    className="font-Roboto-Bold w-36 sm:w-40 h-12 sm:h-16 inline-block leading-6 text-green-50 font-bold text-xl min-[2560px]:text-2xl text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md"
                                    type="submit"
                                >
                                    Update
                                </button>
                            )}
                            {/* Error */}
                            {error && <Error message={error} />}
                            {/* Success */}
                            {success && <Success message={"Post updated successfully"} />}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditPostForm;
