import { useEffect } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { accountVerificationCheckAction } from "../../redux/slices/usersSlice";
import Loading from "../Alerts/Loading";
import Modal from "../helpers/Modal";
import Error from "../Alerts/Error";
import { resetSuccessAction } from "../../redux/slices/globalSlice";

export default function AccountVerification() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, verificationError, isVerified } = useSelector((state) => state?.users);
    const { token, email, name } = useParams();
    const user = useSelector((state) => state?.users?.userAuth?.userInfo);

    useEffect(() => {
        if (!isVerified) {
            dispatch(accountVerificationCheckAction({ token, email, name }));
        }
        if (isVerified) {
            setTimeout(() => {
                dispatch(resetSuccessAction());
                if (user) {
                    navigate("/profile");
                } else {
                    navigate("/login");
                }
            }, 4000);
        }
    }, [dispatch, navigate, isVerified, token, email, name, user]);
    return (
        <Modal>
            {verificationError && <Error message={verificationError} />}
            <div className="max-lg:pb-28 flex flex-col items-center justify-center min-h-screen ">
                {loading && (
                    <div className="w-screen h-screen flex items-center justify-center flex-col">
                        <h1 className="post?.image text-center  text-white text-xl lg:text-3xl 3xl:text-5xl font-bold">
                            Verifying...
                        </h1>
                        <Loading mt={"2rem"} />
                    </div>
                )}
                {isVerified && (
                    <>
                        <FiCheckCircle className="text-blue-500 text-9xl" />
                        <h1 className="post?.image mt-4 text-3xl font-bold text-white">Account Verified!</h1>
                        <h1 className="post?.image mt-4 text-3xl font-bold text-white">
                            {!user && "Now you can login..."}
                        </h1>
                    </>
                )}
            </div>
        </Modal>
    );
}
