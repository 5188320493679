import React, { useEffect, useState } from "react";
import SearchForm from "./SearchForm";
import SearchResultPosts from "./SearchResultPosts";
import PaginationButtons from "./PaginationButtons";
import PostDetails from "../Posts/PostDetails";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../redux/slices/globalSlice";
import Modal from "../helpers/Modal";
import VerificationButton from "../Users/Profile/Main/VerificationButton";

function SearchPage() {
    //* Set Active Page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActivePage(2));
    }, [dispatch]);

    //* Open details modal

    const showDetailsModal = useSelector((state) => state?.global?.showDetailsModal);

    // pagination
    const [page, setPage] = useState(1);
    const [paginationButtonClick, setPaginationButtonClick] = useState(false);
    const setPaginationButtonClickToFalse = () => {
        setPaginationButtonClick(false);
    };

    const handleNextPage = () =>
        setPage((p) => {
            const element = document.getElementById("result");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            setPaginationButtonClick(true);
            return p + 1;
        });
    const handlePreviousPage = () =>
        setPage((p) => {
            const element = document.getElementById("result");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            setPaginationButtonClick(true);
            return p > 1 ? p - 1 : 1;
        });
    const handleSetPage = (index) => {
        setPage(index);
        const element = document.getElementById("result");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const user = useSelector((state) => state?.users?.profile?.user);
    return (
        <>
            {/* details modal */}
            {showDetailsModal && <PostDetails />}
            {/* main */}
            <Modal>
                {user?.isVerified ? (
                    <div className="py-14 flex flex-col items-center">
                        <div className=" flex flex-col items-center ">
                            <h1 className="font-Roboto-Bold text-white font-semibold text-3xl sm:text-4xl text-center my-10 sm:my-16 min-[2560px]:my-24">
                                Search
                            </h1>
                            <SearchForm
                                page={page}
                                handleSetPage={handleSetPage}
                                paginationButtonClick={paginationButtonClick}
                                setPaginationButtonClickToFalse={setPaginationButtonClickToFalse}
                            />
                        </div>
                        <SearchResultPosts page={page} />
                        <PaginationButtons
                            page={page}
                            handleNextPage={handleNextPage}
                            handlePreviousPage={handlePreviousPage}
                            handleSetPage={handleSetPage}
                        />
                    </div>
                ) : (
                    <div className="w-full h-vh-100 centered">
                        <VerificationButton color={"white"} />
                    </div>
                )}
            </Modal>
        </>
    );
}

export default SearchPage;
