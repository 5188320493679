import React, { useEffect, useState } from "react";
import { AiOutlineLock } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetPasswordAction } from "../../redux/slices/usersSlice";
import Loading from "../Alerts/Loading";
import Error from "../Alerts/Error";
import Modal from "../helpers/Modal";
import Success from "../Alerts/Success";

export const PasswordReset = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();

    const [formData, setFormData] = useState({
        password: "",
    });

    //* Handle form change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //* Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            resetPasswordAction({
                resetToken: token,
                password: formData.password,
            })
        );
    };

    //* Redirection;
    const { loading, error, isPasswordChanged } = useSelector((state) => state?.users);
    useEffect(() => {
        if (isPasswordChanged) {
            setTimeout(() => {
                navigate("/login");
            }, 2000);
            // reset form
            setFormData({
                password: "",
            });
        }
    }, [dispatch, navigate, isPasswordChanged]);

    return (
        <Modal>
            <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center justify-center min-h-screen font-bold pb-36 sm:pb-32 md:pb-28 2xl:pb-20"
            >
                <div className="font-Roboto-Bold w-80 sm:w-96 p-6 bg-white rounded-xl shadow-md">
                    <h1 className="post?.image text-xl sm:text-3xl font-bold text-gray-700 text-center mb-6">
                        Reset your password
                    </h1>
                    <div className="mb-4 relative">
                        <AiOutlineLock className="absolute text-gray-500 text-2xl top-2 left-2" />
                        <input
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            type="password"
                            placeholder="Enter your new password"
                            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>

                    {loading ? (
                        <Loading color={"blue"} />
                    ) : (
                        <button className="post?.image w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none">
                            Change Password
                        </button>
                    )}
                    {/* Success */}
                    {isPasswordChanged && <Success message={"Password changed successfully!"} />}
                    {/* Error */}
                    {error && <Error message={error} />}
                </div>
            </form>
        </Modal>
    );
};
