import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSuccessAction, setShowAdminUserDataModal } from "../../../../redux/slices/globalSlice";
import userPic from "../../../../media/user.png";

function AdminUserDetailsModal() {
    const dispatch = useDispatch();
    const { userFound } = useSelector((state) => state?.users?.fetchUserProfile);
    const closeAdminUserDetailsModal = () => {
        dispatch(setShowAdminUserDataModal(false));
        dispatch(resetSuccessAction());
    };

    return (
        <div className="">
            <div onClick={closeAdminUserDetailsModal} className="backdrop "></div>
            <div className="fixed z-30 bg-white px-3 sm:px-5 md:px-10 lg:px-20 py-10 sm:my-24 md:my-20 2xl:my-24 max-[1980px]:my-16 max-[2560px]:my-28 sm: mx-space-1.5 md:mx-space-10 w-space-97 md:w-space-80  h-5/6 rounded-md overflow-scroll">
                <div className="px-4 sm:px-0 flex justify-between">
                    <h1 className="md:text-xl lg:text-2xl min-[1980px]:text-4xl font-semibold leading-7 text-gray-900">
                        {" "}
                        {userFound?.name}
                    </h1>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-black cursor-pointer"
                        onClick={closeAdminUserDetailsModal}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="mt-4 md:text-xl lg:text-2xl min-[1980px]:text-4xl ">
                    <div className="self-end my-6">
                        <img
                            src={userFound?.profilePicture || userPic}
                            alt="avatar"
                            className="w-20 sm:w-40 h-20 sm:h-40 rounded-full"
                        />
                    </div>
                    <h2>{userFound?.email}</h2>
                    <h2>{userFound?.role}</h2>
                    <h2>{userFound?.accountLevel}</h2>
                    <h2>{userFound?.isVerified ? "verified" : "not verified"}</h2>
                    <h2>{userFound?.emailNotifications ? "email notifications ON" : "email notifications OF"}</h2>
                    <h2>
                        <span className="font-bold">last login:</span>{" "}
                        {new Date(userFound?.lastLogin).toString().slice(0, 24)}
                    </h2>
                    <h2>
                        <span className="font-bold">account level expired:</span>
                        {new Date(userFound?.accountLevelExpired).toString().slice(0, 16)}
                    </h2>
                    <h2>
                        <span className="font-bold">created at:</span>{" "}
                        {new Date(userFound?.createdAt).toString().slice(0, 24)}
                    </h2>
                    <h2>
                        <span className="font-bold">updated at:</span>{" "}
                        {new Date(userFound?.updatedAt).toString().slice(0, 24)}
                    </h2>
                    <h2>
                        <span className="font-bold">accountVerificationExpires:</span>{" "}
                        {new Date(userFound?.accountVerificationExpires).toString().slice(0, 24)}
                    </h2>
                    <h2>
                        <span className="font-bold">accountVerificationToken:</span>{" "}
                        {userFound?.accountVerificationToken}
                    </h2>

                    <h2 className="mt-4">
                        <span className="font-bold">unviewed notifications:</span>
                    </h2>
                    {userFound?.unViewedNotifications?.length === 0 ? (
                        <div>empty</div>
                    ) : (
                        userFound?.unViewedNotifications?.map((item) => {
                            return <p>{item?._id}</p>;
                        })
                    )}
                    <h2 className="mt-4">
                        <span className="font-bold">saved searches:</span>
                    </h2>
                    {userFound?.savedSearches?.length === 0 ? (
                        <div>empty</div>
                    ) : (
                        userFound?.savedSearches?.map((item, index) => {
                            return (
                                <div className="mb-3">
                                    <p>
                                        {index + 1}){item?._id}
                                    </p>
                                    <p>{item?.title}</p>
                                    <p>{item?.deviceid}</p>
                                    <p>{item?.situation}</p>
                                    <p>{item?.devicetype}</p>
                                    <p>{item?.manufacturer}</p>
                                    <p>{item?.model}</p>
                                    <p>{item?.color}</p>
                                    <p>{item?.year}</p>
                                    <p>{item?.countryname}</p>
                                    <p>{item?.cityname}</p>
                                    <p>{item?.content}</p>
                                </div>
                            );
                        })
                    )}
                    <h2 className="mt-4">
                        <span className="font-bold">saved posts:</span>
                    </h2>
                    {userFound?.savedPosts?.length === 0 ? (
                        <div>empty</div>
                    ) : (
                        userFound?.savedPosts?.map((item, index) => {
                            return (
                                <div className="mb-3">
                                    <p>
                                        {index + 1}){item?._id}
                                    </p>
                                    <p>{item?.title}</p>
                                    <p>{item?.deviceid}</p>
                                    <p>{item?.situation?.type}</p>
                                    <p>{item?.devicetype?.name}</p>
                                    <p>{item?.manufacturer === null && ""}</p>
                                    <p>{item?.model === null && ""}</p>
                                    <p>{item?.color === null && ""}</p>
                                    <p>{item?.year === null && ""}</p>
                                    <p>{item?.countryname}</p>
                                    <p>{item?.cityname}</p>
                                    <p>{item?.content}</p>
                                </div>
                            );
                        })
                    )}
                    <h2 className="mt-4">
                        <span className="font-bold">posts:</span>
                    </h2>
                    {userFound?.posts?.length === 0 ? (
                        <div>empty</div>
                    ) : (
                        userFound?.posts?.map((item, index) => {
                            return (
                                <div className="mb-7">
                                    <p>
                                        {index + 1}){item?._id}
                                    </p>
                                    <p>{item?.title}</p>
                                    <p>{item?.deviceid}</p>
                                    <p>{item?.situation?.type}</p>
                                    <p>{item?.devicetype?.name}</p>
                                    <p>{item?.manufacturer === null && ""}</p>
                                    <p>{item?.model === null && ""}</p>
                                    <p>{item?.color === null && ""}</p>
                                    <p>{item?.year === null && ""}</p>
                                    <p>{item?.countryname}</p>
                                    <p>{item?.cityname}</p>
                                    <p>{item?.content}</p>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminUserDetailsModal;
