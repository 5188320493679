import ReactLoading from "react-loading";

const Loading = ({ color, h, w, mt, mr, ml }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                marginTop: mt,
                marginRight: mr,
                marginLeft: ml,
            }}
        >
            <ReactLoading type="spinningBubbles" color={color} height={h} width={w} />
        </div>
    );
};

export default Loading;
