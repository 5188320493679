import React, { useEffect, useState } from "react";
import { sendFeedBackMessageAction, setActivePage } from "../../redux/slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../helpers/Modal";
import SearchPostInput from "../SearchPage/SearchPostInput";
import { Link } from "react-router-dom";
import CreatePostSelect from "../Posts/CreatePostSelect";
import Loading from "../Alerts/Loading";
import Success from "../Alerts/Success";
import Error from "../Alerts/Error";

function Feedback() {
    //* Set active page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActivePage(5));
    }, [dispatch]);

    //* Initial Form Data state
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        content: "",
        type: "",
    });

    //* Event handling
    const { error, loading, success } = useSelector((state) => state?.global);

    //* Client-side validation
    const [errors, setErrors] = useState({});
    const validateForm = (data) => {
        let errors = {};
        if (!data.name) errors.name = "name is required";
        if (!data.email) errors.email = "email is required";
        if (!data.content) errors.content = "content is required";
        if (!data.type) errors.type = "type is required";

        return errors;
    };

    //* Handlers
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const typeOptions = [
        { value: "feedback", label: "feedback" },
        { value: "errors", label: "site errors" },
        { value: "propositions", label: "propositions" },
        { value: "complaint", label: "complaint" },
    ];
    const typeHandleChange = (selectedOption) => {
        setFormData({ ...formData, type: selectedOption.value });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(sendFeedBackMessageAction({ formData }));
        }
    };

    useEffect(() => {
        if (success) {
            setFormData({
                name: "",
                email: "",
                content: "",
                type: "",
            });
        }
    }, [dispatch, success]);
    return (
        <Modal>
            <div className="pt-24 flex flex-col items-center">
                <h1 className="font-Roboto-Bold text-white text-2xl mt-3 font-bold text-center sm:text-4xl sm:mt-10 lg:max-xl:text-2xl 2xl:text-3xl 2xl:mt-space-5  ">
                    Feedback
                </h1>
                <h1 className="font-Roboto-Bold text-white text-l sm:text-xl lg:text-lg xl:mt-12 font-bold text-center sm:mt-16 mt-8 ">
                    Any questions, propositions ?<br></br> Please, contact us using form below.{" "}
                </h1>
                <form className="flex flex-col items-start mt-10 ">
                    <SearchPostInput
                        title="Name"
                        type="text"
                        id="name"
                        placeholder="Enter your name "
                        value={formData.name}
                        onChange={handleChange}
                        basis={"mx-0 w-vw-95 mx-auto sm:w-96"}
                        errors={errors}
                    />
                    <SearchPostInput
                        title="Email"
                        type="email"
                        id="email"
                        placeholder="Enter your mail "
                        value={formData.email}
                        onChange={handleChange}
                        basis={"mx-0 w-vw-95 mx-auto sm:w-96"}
                        errors={errors}
                    />
                    <CreatePostSelect
                        title={"Reason "}
                        options={typeOptions}
                        name="type"
                        onChange={typeHandleChange}
                        errors={errors}
                        basis={"mx-3 w-vw-95 sm:w-96"}
                        className={"text-white font-Roboto-Semibold"}
                    />
                    <label id="content" className="font-Roboto-Semibold text-white font-medium mb-1 mx-3">
                        Content
                    </label>
                    <textarea
                        minLength={10}
                        placeholder="Whrite down your text here..."
                        id={"content"}
                        name={"content"}
                        rows={5}
                        className="w-vw-95 sm:w-96 resize-none rounded-md p-3 mx-3 text-l font-bold text-gray-800"
                        onChange={handleChange}
                        value={formData.content}
                    ></textarea>

                    {errors.content && <p className="pt-2 mx-3 text-red-500 font-bold">{errors.content}</p>}
                    {loading ? (
                        <Loading color={"white"} h={50} w={50} mt={"3rem"} />
                    ) : (
                        <Link
                            type="submit"
                            onClick={submitHandler}
                            className="outline-0 rounded-md font-Roboto-Bold h-12 self-start mt-10  flex flex-row w-vw-95 sm:w-96  py-1 px-5 mx-3 justify-center text-center items-center  bg-blue-500  text-2xl  text-white  hover:bg-blue-600  "
                        >
                            Submit
                        </Link>
                    )}
                </form>
            </div>
            {/* Success */}
            {success && <Success message={"Message sent successfully"} />}
            {/* Error */}
            {error && <Error message={`${error} Please, reload page and try again`} />}
        </Modal>
    );
}

export default Feedback;
