import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React from "react";

function AppoinmentButton({ showConfirmModalHandler }) {
    return (
        <button
            type="button"
            onClick={showConfirmModalHandler}
            className="rounded-md  bg-yellow-200 py-1 px-2 mr-2 sm:mx-2 hover:bg-yellow-300"
        >
            <div className="flex">
                <div className="flex-shrink-0 hidden sm:visible">
                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-600" aria-hidden="true" />
                </div>
                <div className="sm:ml-3">
                    <h3 className="super-small font-Roboto-Base  text-yellow-800">
                        Click here if you have made an appointment with a person to hand over the device.
                    </h3>
                </div>
            </div>
        </button>
    );
}

export default AppoinmentButton;
