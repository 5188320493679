import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { resetErrorAction } from "../../redux/slices/globalSlice";
import { useNavigate } from "react-router-dom";

const Error = ({ message, nav }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let width;
    if (window.innerWidth < 640) {
        width = "85%";
    }

    Swal.fire({
        icon: "error",
        title: "Something went wrong...",
        text: message,
        padding: "1rem",
        confirmButtonColor: "#4e80f4",
        iconColor: "#4e80f4",
        width: width || "27rem",
        color: "#0236a7",
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch(resetErrorAction());
        }
        if (nav) {
            navigate(`${nav}`);
        }
    });
    dispatch(resetErrorAction());
};

export default Error;
