import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfileImageAction, userProfileAction } from "../../../../redux/slices/usersSlice";
import Error from "../../../Alerts/Error";
import Success from "../../../Alerts/Success";
import Loading from "../../../Alerts/Loading";
import { setShowImageModal } from "../../../../redux/slices/globalSlice";

const UploadProfileImage = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        image: null,
    });

    //* Close modal

    const closeModal = useCallback(() => dispatch(setShowImageModal(false)), [dispatch]);

    //* Error handling
    const { error, loading, success } = useSelector((state) => state?.users);

    const imageChangeHandler = (e) => {
        setFormData({ ...formData, image: e.target?.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(uploadProfileImageAction(formData));
        }
    };

    useEffect(() => {
        success && closeModal();
        dispatch(userProfileAction());
    }, [dispatch, closeModal, success]);

    //* Client-side validation

    const [errors, setErrors] = useState({});
    const validateForm = (data) => {
        let errors = {};
        if (!data.image) {
            errors.image = "Image is required";
        }
        return errors;
    };

    return (
        <>
            <div onClick={closeModal} className="backdrop"></div>
            <div className="image-modal">
                <form
                    onSubmit={handleSubmit}
                    className="bg-white z-20 flex flex-col items-center px-4  sm:px-10 py-10  xl:pb-12  rounded-xl shadow-2xl"
                >
                    <span onClick={closeModal} className="absolute top-3 right-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    <h2 className="font-Roboto-Bold mb-4 text-2xl md:text-3xl text-coolGray-900 font-bold text-center">
                        Upload profile image
                    </h2>
                    <h3 className="font-Roboto-Bold mb-7 text-base md:text-lg text-coolGray-500 font-medium text-center">
                        Upload or update profile image
                    </h3>

                    <label className="mb-4 flex flex-col w-full">
                        <input
                            className="py-3 px-3 leading-5 w-full text-coolGray-400 font-normal border border-coolGray-200 outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-lg shadow-sm"
                            type="file"
                            name="image"
                            onChange={imageChangeHandler}
                        />
                        {errors?.image && (
                            <p className={"pt-2"} style={{ color: "red" }}>
                                {" "}
                                {errors?.image}
                            </p>
                        )}
                    </label>

                    {loading ? (
                        <Loading color={"blue"} />
                    ) : (
                        <button
                            className="font-Roboto-Bold mb-4 inline-block py-3 px-7 w-full leading-6 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md"
                            type="submit"
                        >
                            Upload
                        </button>
                    )}
                    {/* Error */}
                    {error && <Error message={error} />}
                    {/* Success */}
                    {success && <Success message={"Profile image uploaded successfully"} />}
                </form>
            </div>
        </>
    );
};

export default UploadProfileImage;
