import React, { useEffect, useState } from "react";
import CreatePostInput from "../../../Posts/CreatePostInput";
import Switch from "../../../helpers/Switch";
import { useDispatch, useSelector } from "react-redux";
import { changeEmailAction, changePasswordAction, logoutUsersSlice } from "../../../../redux/slices/usersSlice";
import Success from "../../../Alerts/Success";
import Error from "../../../Alerts/Error";
import Loading from "../../../Alerts/Loading";
import { useNavigate } from "react-router-dom";
import { logoutCitiesSlice } from "../../../../redux/slices/citiesSlice";
import { logoutCommentsSlice } from "../../../../redux/slices/commentsSlice";
import { logoutGlobalSlice } from "../../../../redux/slices/globalSlice";
import { logoutPostsSlice } from "../../../../redux/slices/postsSlice";
import VerificationButton from "../Main/VerificationButton";

function ProfileSettings({ profile, deleteProfileModalHandler }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //* Initial Form Data state
    const [formData, setFormData] = useState({
        email: "",
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: "",
    });

    //* Input handlers
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //* Events handling
    const {
        emailChangeLoading,
        changeEmailSuccess,
        changeEmailError,
        deleteAccError,
        deleteAccSuccess,
        passwordChangeLoading,
        passwordChangeSuccess,
        passwordChangeError,
    } = useSelector((state) => state?.users);

    //* Client-side validation
    const [emailErrors, setEmailErrors] = useState({});
    const validateEmailForm = (data) => {
        let errors = {};
        if (!data.email) errors.email = "Email is required";
        return errors;
    };
    const [passwordErrors, setPasswordErrors] = useState({});
    const validatePasswordForm = (data) => {
        let errors = {};

        if (!data.currentPassword) errors.currentPassword = "Password is required";
        if (!data.newPassword) errors.newPassword = "New password is required";
        if (!data.repeatNewPassword || data.repeatNewPassword !== data.newPassword) {
            errors.repeatNewPassword = "Passwords should match and non empty";
        }
        return errors;
    };

    //* Submit handlers

    const changeEmailHandler = (e) => {
        e.preventDefault();
        const errors = validateEmailForm(formData);
        setEmailErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(changeEmailAction({ formData }));
            setFormData({
                email: "",
            });
        }
    };
    // email handler
    useEffect(() => {
        if (changeEmailSuccess) {
            setTimeout(() => {
                navigate("/profile");
            }, 2000);
        }
    }, [dispatch, navigate, changeEmailSuccess]);

    // password handler
    const changePasswordHandler = (e) => {
        e.preventDefault();
        const errors = validatePasswordForm(formData);
        setPasswordErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(changePasswordAction({ formData }));
            setFormData({
                currentPassword: "",
                newPassword: "",
                repeatNewPassword: "",
            });
        }
    };
    //* Navigate after deleting account

    useEffect(() => {
        if (deleteAccSuccess) {
            console.log("siasasasas");

            setTimeout(() => {
                dispatch(logoutUsersSlice());
                dispatch(logoutPostsSlice());
                dispatch(logoutCitiesSlice());
                dispatch(logoutCommentsSlice());
                dispatch(logoutGlobalSlice());
                localStorage.clear();
                window.location.reload();
            }, 1500);
        }
    }, [deleteAccSuccess, navigate, dispatch]);

    return (
        <section className="flex flex-col items-start max-[260px]:ml-2 max-[470px]:ml-8 ml-10 sm:ml-3 md:ml-12 w-11/12 mb-32 bg-white text-gray-700 ">
            <div className="flex flex-col mb-16 ">
                <div className="sm:hidden">
                    {!profile?.isVerified && <VerificationButton positionMain={true} />}
                </div>
                <h1 className="font-Roboto-Bold text-black text-2xl  font-bold mb-10 max-[470px]:ml-2">
                    Change email
                </h1>
                <form onSubmit={changeEmailHandler} className=" ml-4 max-[470px]:w-vw-50">
                    <p className="mb-4 max-[300px]:text-xs min-[1920px]:text-lg font-bold">
                        Your current email is: <span className="">{profile?.email}</span>
                    </p>
                    <div className="flex flex-col">
                        <CreatePostInput
                            title="Enter your new email:"
                            type="email"
                            id="email"
                            placeholder="new email"
                            value={formData?.email}
                            onChange={handleChange}
                            errors={emailErrors}
                            basis={"basis-5/12 "}
                        />
                        {emailChangeLoading ? (
                            <Loading color={"blue"} w={50} h={50} />
                        ) : (
                            <button
                                className="outline-0 font-Roboto-Bold self-start w-32 h-9  mt-3 inline-block leading-6 text-green-50 font-bold text-xl text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md"
                                type="submit"
                            >
                                Send
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <div className="flex flex-col mb-16">
                <h1 className="font-Roboto-Bold text-black text-2xl font-bold mb-10 max-[470px]:ml-2">
                    Change password
                </h1>
                <form onSubmit={changePasswordHandler} className="flex flex-col ml-4">
                    <CreatePostInput
                        autoComplete={"of"}
                        title="Enter your current password:"
                        type="password"
                        id="currentPassword"
                        placeholder="current password"
                        value={formData?.currentPassword}
                        onChange={handleChange}
                        errors={passwordErrors}
                        basis={"basis-5/12"}
                    />
                    <CreatePostInput
                        autoComplete={"of"}
                        title="Enter your new password:"
                        type="password"
                        id="newPassword"
                        placeholder="new password"
                        value={formData?.newPassword}
                        onChange={handleChange}
                        errors={passwordErrors}
                        basis={"basis-5/12"}
                    />
                    <CreatePostInput
                        autoComplete={"of"}
                        title="Reapeat your new password:"
                        type="password"
                        id="repeatNewPassword"
                        placeholder="repeat new password"
                        value={formData?.repeatNewPassword}
                        onChange={handleChange}
                        errors={passwordErrors}
                        basis={"basis-5/12"}
                    />
                    {passwordChangeLoading ? (
                        <Loading color={"blue"} w={50} h={50} />
                    ) : (
                        <button
                            className="outline-0 font-Roboto-Bold w-32 h-9 self-start inline-block leading-6 text-green-50 font-bold text-xl text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md"
                            type="submit"
                        >
                            Change
                        </button>
                    )}
                </form>
            </div>
            <div className="flex flex-col font-bold mb-16">
                <h1 className="font-Roboto-Bold text-2xl text-black mb-10 max-[470px]:ml-2">
                    Account notifications:
                </h1>

                <form className="flex ml-4">
                    <p className="mb-4 mr-6 max-[350px]:mr-16 max-[350px]:text-sm   min-[1920px]:text-lg">
                        Send notification <br className="max-[350px]:block hidden" /> to my email{" "}
                    </p>
                    <Switch notif={profile?.emailNotifications} />
                </form>
            </div>
            <div className="flex flex-col font-bold mb-6">
                <h1 className="font-Roboto-Bold text-2xl text-black mb-10 max-[470px]:ml-2">Delete Profile:</h1>

                <div className="flex flex-col ml-4">
                    <p className="mb-4 min-[1920px]:text-lg">
                        Your account, personal data and posts will be deleted completely.
                    </p>
                    <button
                        className="outline-0 w-20 h-9 self-start inline-block leading-6 text-green-50 font-bold text-l text-center bg-red-500 hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-md"
                        type="submit"
                        onClick={deleteProfileModalHandler}
                    >
                        Delete
                    </button>
                </div>
            </div>
            {/* success handler */}
            {deleteAccSuccess && <Success timer={1500} message={"Account deleted successfully!"} />}
            {passwordChangeSuccess && <Success timer={1500} message={"Password successfully changed!"} />}
            {changeEmailSuccess && (
                <Success timer={1500} message={`Confirmation email sent to ${profile?.email}`} />
            )}
            {/* error handler */}
            {deleteAccError && <Error message={deleteAccError} />}
            {passwordChangeError && <Error message={passwordChangeError} />}
            {changeEmailError && <Error message={changeEmailError?.message} />}
        </section>
    );
}

export default ProfileSettings;
