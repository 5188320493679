import React, { useEffect, useState } from "react";
import Success from "../Alerts/Success";
import Error from "../Alerts/Error";
import Loading from "../Alerts/Loading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CreatePostInput from "./CreatePostInput";
import CreatePostSelect from "./CreatePostSelect";
import { fetchCitiesAction } from "../../redux/slices/citiesSlice";
import { addPostAction } from "../../redux/slices/postsSlice";
import { setActivePage } from "../../redux/slices/globalSlice";
import Modal from "../helpers/Modal";
import VerificationButton from "../Users/Profile/Main/VerificationButton";

function CreatePost() {
    const navigate = useNavigate();
    //* Initial Form Data state
    const [formData, setFormData] = useState({
        situation: "",
        title: "",
        content: "",
        deviceid: "",
        devicetype: "",
        manufacturer: "",
        model: "",
        color: "",
        year: "",
        countryname: "",
        cityname: "",
        image: null,
    });

    //* Fetch cities
    const dispatch = useDispatch();

    useEffect(() => {
        //* Set Active Page
        dispatch(setActivePage(3));

        //* Fetch cities
        if (formData.countryname) dispatch(fetchCitiesAction(formData.countryname));
    }, [dispatch, formData.countryname]);
    const { cityList, cityLoading } = useSelector((state) => state?.cities);

    //* Select options

    const { situationOptions, manufacturerOptions, devicetypeOptions, colorOptions, yearOptions, countryOptions } =
        useSelector((state) => state?.posts);

    const cityOptions = cityList?.data?.map((item) => {
        return { value: item, label: item };
    });

    const situationOptionsFiltered = situationOptions?.filter((item) => {
        return item?.label !== "all";
    });

    const manufacturerOptionsFiltered = manufacturerOptions?.filter((item) => {
        return item?.label !== "all";
    });

    const devicetypeOptionsFiltered = devicetypeOptions?.filter((item) => {
        return item?.label !== "all";
    });

    const countryOptionsFiltered = countryOptions?.filter((item) => {
        return item?.label !== "all";
    });

    //* Input handlers

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const imageChangeHandler = (e) => {
        setFormData({ ...formData, image: e.target?.files[0] });
    };
    const situationHandleChange = (selectedOption) => {
        setFormData({ ...formData, situation: selectedOption.value });
    };
    const deviceTypeHandleChange = (selectedOption) => {
        setFormData({ ...formData, devicetype: selectedOption.value });
    };
    const manufacturerHandleChange = (selectedOption) => {
        setFormData({ ...formData, manufacturer: selectedOption.value });
    };
    const colorHandleChange = (selectedOption) => {
        setFormData({ ...formData, color: selectedOption.value });
    };
    const yearHandleChange = (selectedOption) => {
        setFormData({ ...formData, year: selectedOption.value });
    };
    const countryHandleChange = (selectedOption) => {
        setFormData({ ...formData, countryname: selectedOption.value });
    };
    const citynameHandleChange = (selectedOption) => {
        setFormData({ ...formData, cityname: selectedOption.value });
    };

    //* Selectors
    const { error, success, loading } = useSelector((state) => state?.posts);
    const user = useSelector((state) => state?.users?.profile?.user);

    //* Client-side validation
    const [errors, setErrors] = useState({});
    const validateForm = (data) => {
        let errors = {};
        if (!data.title) errors.title = "Title is required";
        if (!data.situation) errors.situation = "Situation is required";
        if (!data.devicetype) errors.devicetype = "Device type is required";
        if (!data.manufacturer) errors.manufacturer = "Manufacturer is required";
        if (!data.countryname) errors.countryname = "Country is required";
        return errors;
    };

    //* Submit handler
    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(addPostAction(formData));
        } else {
            const element = document.getElementById("situation-input");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        if (success) {
            setFormData({
                situation: { value: "", label: "" },
                title: "",
                content: "",
                deviceid: "",
                model: "",
                countryname: "",
                cityname: "",
                image: null,
            });
            navigate("/profile");
        }
    }, [dispatch, success, navigate]);

    return (
        <Modal>
            {user?.isVerified ? (
                <div className="py-24  sm:py-40  min-w-screen w-full h-full  text-black min-h-screen flex items-center justify-center">
                    <form
                        onSubmit={handleSubmit}
                        className=" w-vw-97 sm:w-vw-90 lg:w-vw-95 xl:w-vw-85 2xl:w-vw-70 min-[2560px]:w-vw-60  bg-white px-2 sm:px-10 py-6 sm:py-16 rounded-xl relative z-20"
                    >
                        <h2 className="font-Roboto-Bold mb-4 text-2xl md:text-3xl min-[2560px]:text-4xl text-coolGray-900 font-bold text-center">
                            Create New Post
                        </h2>
                        <h3 className="font-Roboto-Bold sm: mb-10 md:mb-7 text-sm min-[2560px]:text-lg text-coolGray-800 font-semibold text-center">
                            *Try to fill in as many fields as possible or click{" "}
                            <Link
                                to={"/faq"}
                                className="text-red-600 text-sm min-[2560px]:text-lg hover:text-red-800"
                            >
                                {" "}
                                here
                            </Link>{" "}
                            to read how to create a post correctly{" "}
                        </h3>
                        <div id="situation-input" className="flex flex-col lg:flex-row  justify-between 2xl:mb-10">
                            <CreatePostSelect
                                title={"Situation"}
                                options={situationOptionsFiltered}
                                name="situation"
                                onChange={situationHandleChange}
                                errors={errors}
                                basis={"basis-1/12"}
                            />
                            <CreatePostInput
                                title="Title"
                                type="text"
                                id="title"
                                max={25}
                                placeholder="Enter the post title"
                                value={formData.title}
                                onChange={handleChange}
                                errors={errors}
                                basis={"basis-6/12"}
                            />
                            <CreatePostInput
                                title="EMEI/ID"
                                type="text"
                                id="deviceid"
                                placeholder="Enter the device EMEI/ID"
                                value={formData.deviceid}
                                onChange={handleChange}
                                errors={errors}
                                basis={"basis-4/12"}
                            />
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between 2xl:mb-10">
                            <CreatePostSelect
                                title={"Device Type"}
                                options={devicetypeOptionsFiltered}
                                name="devicetype"
                                onChange={deviceTypeHandleChange}
                                errors={errors}
                                basis={"basis-2/12"}
                            />
                            <CreatePostSelect
                                title={"Manufacturer"}
                                options={manufacturerOptionsFiltered}
                                name="manufacturer"
                                onChange={manufacturerHandleChange}
                                errors={errors}
                                basis={"basis-2/12"}
                            />

                            <CreatePostInput
                                title="Model"
                                type="text"
                                id="model"
                                placeholder="Enter model of device "
                                value={formData.model}
                                onChange={handleChange}
                                errors={errors}
                                basis={"basis-2/12"}
                            />
                            <CreatePostSelect
                                title={"Color"}
                                options={colorOptions}
                                name="color"
                                onChange={colorHandleChange}
                                errors={errors}
                                basis={"basis-2/12"}
                            />
                            <CreatePostSelect
                                title={"Year"}
                                options={yearOptions}
                                name="year"
                                onChange={yearHandleChange}
                                errors={errors}
                                basis={"basis-2/12"}
                            />
                        </div>

                        <div className="flex flex-col lg:flex-row justify-between 2xl:mb-10">
                            <CreatePostSelect
                                title={"Country"}
                                options={countryOptionsFiltered}
                                name="countryname"
                                onChange={countryHandleChange}
                                errors={errors}
                                basis={"basis-2/12"}
                            />
                            {!cityLoading ? (
                                <CreatePostSelect
                                    title={"City"}
                                    options={cityOptions}
                                    name="cityname"
                                    onChange={citynameHandleChange}
                                    errors={errors}
                                    basis={"basis-2/12"}
                                />
                            ) : (
                                <Loading color="blue" w={40} h={40} mt={window.innerWidth <= 768 ? 25 : -30} />
                            )}

                            <CreatePostInput
                                accept={"image/png, image/jpeg,image/jpg"}
                                title="Image"
                                type="file"
                                id="image"
                                placeholder=""
                                onChange={imageChangeHandler}
                                errors={errors}
                                span={`Upload an image only if it shows clear features of the device
                                or if you dont know who is manufacturer.`}
                                basis={"basis-5/12 overflow-hidden"}
                            />
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between">
                            <CreatePostInput
                                title="Content"
                                type="text"
                                id="content"
                                max={50}
                                placeholder="Write your description here"
                                value={formData?.content}
                                onChange={handleChange}
                                errors={errors}
                                content={true}
                                span=""
                                basis={"basis-11/12"}
                            />
                            <div className="flex  items-center mt-3 justify-end basis-3/12">
                                {" "}
                                {loading ? (
                                    <Loading w={"3rem"} mr={"3rem"} color="blue" />
                                ) : (
                                    <button
                                        className="outline-0 font-Roboto-Bold w-36 sm:w-40 h-12 sm:h-16 inline-block leading-6 text-green-50 font-bold text-xl min-[2560px]:text-2xl text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md"
                                        type="submit"
                                    >
                                        Create
                                    </button>
                                )}
                                {/* Error */}
                                {error && <Error message={error} />}
                                {/* Success */}
                                {success && <Success message={"Post created successfully!"} />}
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="w-full h-vh-100 centered">
                    <VerificationButton color={"white"} />
                </div>
            )}
        </Modal>
    );
}

export default CreatePost;
