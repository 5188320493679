import React, { useEffect, useState } from "react";
import CommentsList from "./CommentList";
import { useDispatch, useSelector } from "react-redux";
import { createComment } from "../../redux/slices/commentsSlice";
import Loading from "../Alerts/Loading";
import { userProfileAction } from "../../redux/slices/usersSlice";
import userPic from "../../media/user.png";
import Error from "../Alerts/Error";
import {
    resetLoadingAction,
    resetSuccessAction,
    setShowConfirmAppoinmentModal,
    setShowDetailModal,
} from "../../redux/slices/globalSlice";
import AppoinmentButton from "./AppoinmentButton";

const AddComment = ({ comments, postId, postAuthorId }) => {
    const dispatch = useDispatch();
    const { commentsSuccess, commentsLoading, commentsError } = useSelector((state) => state?.comments);
    const userAuth = useSelector((state) => state?.users?.userAuth?.userInfo?.user?._id);
    const isAuthor = userAuth === postAuthorId;

    //* Reload after success submitting

    useEffect(() => {
        if (commentsSuccess) {
            dispatch(resetSuccessAction());
            dispatch(userProfileAction());
            setMessage("");
            window.location.reload();
        }
        dispatch(resetLoadingAction());
    }, [dispatch, commentsSuccess]);

    //* Handlers

    const [message, setMessage] = useState("");
    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createComment({ message, postId }));
    };

    const showConfirmModalHandler = () => {
        dispatch(setShowConfirmAppoinmentModal(true));
        dispatch(setShowDetailModal(false));
    };

    //* Save post
    const user = useSelector((state) => state?.users?.profile?.user);

    return (
        <div>
            <div className="bg-white rounded shadow px-2 xl:px-4 py-5 lg:py-2 xl:p-6">
                <h3 className="font-Roboto-Bold text-lg font-medium leading-6 text-blue-600">Comments</h3>

                <div className="mt-5">
                    <hr className="mt-5 border-gray-300" />
                    <form className="mt-4" onSubmit={handleSubmit}>
                        <div className="flex space-x-4">
                            <div className="flex-none">
                                <img
                                    src={user?.profilePicture || userPic}
                                    alt="avatar"
                                    className="w-9 sm:w-12 h-9 sm:h-12 rounded-full"
                                />
                            </div>
                            <div className="flex-grow">
                                <div className="border rounded-lg shadow-sm">
                                    <div className="p-3 border-b bg-gray-50">
                                        <h4 className="font-Roboto-Bold text-sm font-medium text-blue-600">
                                            Add a comment
                                        </h4>
                                    </div>
                                    <div className="p-3 ">
                                        <label htmlFor="comment" className="sr-only">
                                            Comment
                                        </label>
                                        <textarea
                                            id="comment"
                                            rows={5}
                                            className="text-gray-700 block w-full mt-1 border-gray-300 p-1 sm:p-3 rounded-md shadow-sm form-textarea resize-none"
                                            placeholder="Your comment"
                                            value={message}
                                            onChange={handleChange}
                                            name="message"
                                        />
                                    </div>
                                    <div className="flex items-center justify-end px-0.5 sm:px-3 py-0.5 sm:py-2 rounded-b-lg bg-gray-50">
                                        {isAuthor && (
                                            <AppoinmentButton showConfirmModalHandler={showConfirmModalHandler} />
                                        )}
                                        {commentsLoading ? (
                                            <Loading color="blue" h={40} w={40} />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="font-Roboto-Bold px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring"
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Comment lists */}
            <CommentsList comments={comments} />
            {/* Add comment rror */}
            {commentsError && <Error message={`${commentsError?.message} Please, reload page and try again.`} />}
        </div>
    );
};

export default AddComment;
