import React from "react";
import { Link } from "react-router-dom";
import SignUpForm from "./SignUpForm";

function SignUp() {
    return (
        <div class="body">
            <div className=" 2xl:pt-14 ">
                <div className="flex min-h-full flex-1 flex-col items-center justify-center pt-32 relative z-20 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="font-Roboto-Bold mt-10 2xl:mt-20 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                            Create new account
                        </h2>
                    </div>

                    <div className="w-5/6 mt-10 sm:mx-auto sm:max-w-sm sm:w-5/6">
                        <SignUpForm />

                        <p className="mt-10 text-center text-sl text-white font-semibold">
                            Already a member?{" "}
                            <Link
                                to="/login"
                                className="font-semibold leading-6 text-blue-400 hover:text-blue-500"
                            >
                                Log in
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            <div class="light x1"></div>
            <div class="light x2"></div>
            <div class="light x3"></div>
            <div class="light x4"></div>
            <div class="light x5"></div>
            <div class="light x6"></div>
            <div class="light x7"></div>
            <div class="light x8"></div>
            <div class="light x9"></div>
        </div>
    );
}

export default SignUp;
