import React from "react";
import { AiFillCamera } from "react-icons/ai";
import user from "../../../../media/user.png";
import { useDispatch, useSelector } from "react-redux";
import { setShowImageModal } from "../../../../redux/slices/globalSlice";

function ProfileImage() {
    const dispatch = useDispatch();
    const loggedInUser = useSelector((state) => state?.users?.profile?.user);

    const openModal = () => {
        dispatch(setShowImageModal(true));
    };
    return (
        <button
            onClick={() => openModal()}
            className="relative flex items-center justify-start top-20 sm:top-36  -right-6 min-[2560px]:-right-16"
        >
            <img
                className="bg-white  rounded-full ring-4 ring-white h-24 w-24 sm:h-40 sm:w-40 min-[2560px]:h-48 min-[2560px]:w-48"
                src={loggedInUser?.profilePicture || user}
                alt="user icon"
            />
            <label className="absolute bottom-0 left-20 lg:left-24 cursor-pointer justify-start">
                <AiFillCamera className="h-4 w-4 sm:w-6 sm:h-6 absolute -top-12 sm:-top-6 sm:left-8 min-[2560px]:left-20  text-gray-500 " />
            </label>
        </button>
    );
}

export default ProfileImage;
