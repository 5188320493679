import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/slices/usersSlice";
import Loading from "../Alerts/Loading";
import Error from "../Alerts/Error";
import { resetSuccessAction } from "../../redux/slices/globalSlice";
import VerificationButton from "../Users/Profile/Main/VerificationButton";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isHidden, setIsHidden] = useState(true);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const inputHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        dispatch(loginAction({ email: formData.email.toLowerCase(), password: formData.password }));
    };

    const { loading, error, loginSuccess } = useSelector((state) => state?.users);

    const successLogin = loginSuccess === "Login success";
    const accountNotVerified = loginSuccess === "Account not verified!";

    //* Redirection
    useEffect(() => {
        if (successLogin) {
            // reset form
            setFormData({
                password: "",
                email: "",
            });
            dispatch(resetSuccessAction());
            navigate("/profile");
        }
    }, [dispatch, navigate, successLogin, loginSuccess]);

    return (
        <div class="body">
            <div className="pt-24">
                <div className="flex min-h-full flex-1 flex-col items-center justify-center pt-16 sm:pt-32  lg:pt-20  2xl:pt-28 min-[1920px]:pt-40 min-[2560px]:pt-72 relative z-20 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="font-Roboto-Bold sm:mt-20 lg:mt-0 text-center text-2xl min-[1920px]:text-3xl font-bold leading-9 tracking-tight text-white">
                            Log in to your account
                            {accountNotVerified && <VerificationButton />}
                        </h2>
                    </div>
                    {/* Error  */}
                    {error && <Error message={error?.message} />}

                    <div className="w-5/6 mt-10 sm:mx-auto sm:max-w-sm sm:w-5/6">
                        <form className="space-y-6" action="#" method="POST" onSubmit={onSubmitHandler}>
                            <div className="">
                                <label
                                    htmlFor="email"
                                    className="font-Roboto-Bold block text-sm font-medium leading-6 text-white"
                                >
                                    Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        onChange={inputHandler}
                                        value={formData.email}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="font-semibold text-gray-600 block w-full rounded-md border-0 px-1.5 py-1.5 text-whiteshadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="password"
                                        className="font-Roboto-Bold block text-sm font-medium leading-6 text-white"
                                    >
                                        Password
                                    </label>
                                    <div className="text-sm">
                                        <Link
                                            to="/forgot-password"
                                            className="font-Roboto-Semibold text-blue-300 hover:text-blue-400"
                                        >
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <input
                                        onChange={inputHandler}
                                        value={formData.password}
                                        hidden={isHidden}
                                        id="password"
                                        name="password"
                                        type={isHidden ? "password" : "text"}
                                        autoComplete="current-password"
                                        required
                                        className="block w-full font-semibold rounded-md border-0 px-1.5 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="flex items-center mt-2 ">
                                    <input
                                        onClick={() => setIsHidden((p) => !p)}
                                        id="showpassword"
                                        type="checkbox"
                                        className="cursor-pointer font-semibold rounded-full"
                                    />
                                    <label
                                        htmlFor="showpassword"
                                        className="text-white font-semibold text-xs ml-2"
                                    >
                                        show password
                                    </label>
                                </div>
                            </div>

                            <div>
                                {/* Loading */}
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <button
                                        type="submit"
                                        className="font-Roboto-Bold flex w-full justify-center rounded-md bg-blue-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Log in
                                    </button>
                                )}
                            </div>
                        </form>

                        <p className="mt-10 text-center text-sm text-white font-semibold">
                            Not a member?{" "}
                            <Link
                                to="/signup"
                                className="font-semibold leading-6 text-blue-400 hover:text-blue-500"
                            >
                                Sign Up
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            <div class="light x1"></div>
            <div class="light x2"></div>
            <div class="light x3"></div>
            <div class="light x4"></div>
            <div class="light x5"></div>
            <div class="light x6"></div>
            <div class="light x7"></div>
            <div class="light x8"></div>
            <div class="light x9"></div>
        </div>
    );
}

export default Login;
