import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import UserProfile from "./components/Users/Profile/Main/UserProfile";
import Home from "./components/HomePage/Home";
import SearchPage from "./components/SearchPage/SearchPage";
import CreatePost from "./components/Posts/CreatePost";
import Login from "./components/Login/Login";
import NavBar from "./components/NavBar/NavBar";
import Feedback from "./components/Feedback/Feedback";
import Page404 from "./components/Terms,FAQ,Policy,404/Page404";
import PasswordResetRequest from "./components/Users/PasswordResetRequest";
import SignUp from "./components/SignUp/SignUp";
import FAQ from "./components/Terms,FAQ,Policy,404/FAQ";
import Terms from "./components/Terms,FAQ,Policy,404/Terms";
import PrivacyPolicy from "./components/Terms,FAQ,Policy,404/PrivacyPolicy";
import UploadProfileImage from "./components/Users/Profile/Main/UploadProfileImage";
import AccountVerification from "./components/Users/AccountVerification";
import "./App.css";
import { PasswordReset } from "./components/Users/PasswordReset";
import EmailChange from "./components/Users/EmailChange";
import WhatAndWhy from "./components/AboutUs/WhatAndWhy";

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/terms" element={<Terms />}></Route>
                <Route path="/policy" element={<PrivacyPolicy />}></Route>
                <Route path="/feedback" element={<Feedback />}></Route>
                <Route path="/about" element={<WhatAndWhy />}></Route>
                <Route path="/faq" element={<FAQ />}></Route>
                <Route path="/forgot-password" element={<PasswordResetRequest />}></Route>
                <Route path="/reset-password/:token" element={<PasswordReset />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/signup" element={<SignUp />}></Route>
                <Route path="/verify-account/:token/:email/:name" element={<AccountVerification />}></Route>
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <UserProfile />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/upload-profile-image"
                    element={
                        <ProtectedRoute>
                            <UploadProfileImage />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/posts/create"
                    element={
                        <ProtectedRoute>
                            <CreatePost />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/search"
                    element={
                        <ProtectedRoute>
                            <SearchPage />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/change-email/:token"
                    element={
                        <ProtectedRoute>
                            <EmailChange />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route path="*" element={<Page404 />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
