import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { resetErrorAction, resetLoadingAction, resetSuccessAction } from "./globalSlice";
import { baseURL } from "../../utils/BaseURL";

const INITIAL_STATE = {
    commentsLoading: false,
    commentsError: false,
    commentsSuccess: false,
    commentsList: [],
    comment: null,
};

//! add a comment

export const createComment = createAsyncThunk(
    "/add-comment",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.post(
                `${baseURL}/comments/create/${payload?.postId}`,
                {
                    message: payload?.message,
                },
                config
            );

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);
//! delete comment

export const deleteComment = createAsyncThunk(
    "/delete-comment",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.delete(`${baseURL}/comments/delete/${payload}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

//! delete Notification

export const deleteNotificationAction = createAsyncThunk(
    "/unviewed-note-delete",
    async (payload, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().users?.userAuth?.userInfo?.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.delete(`${baseURL}/comments/note/delete/${payload?.id}`, config);

            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const CommentsSlice = createSlice({
    name: "commentsSlice",
    initialState: INITIAL_STATE,
    reducers: {
        logoutCommentsSlice(state) {
            state = undefined;
        },
    },
    extraReducers: (builder) => {
        //! Add a comment
        builder.addCase(createComment.pending, (state) => {
            state.commentsLoading = true;
        });
        builder.addCase(createComment.fulfilled, (state, action) => {
            state.commentsLoading = false;
            state.commentsSuccess = true;
            state.commentsError = false;
            state.commentsList = action.payload;
        });
        builder.addCase(createComment.rejected, (state, action) => {
            state.commentsLoading = false;
            state.commentsError = action.payload;
        });

        //! Delete a comment
        builder.addCase(deleteComment.pending, (state) => {
            state.commentsLoading = true;
        });
        builder.addCase(deleteComment.fulfilled, (state, action) => {
            state.commentsLoading = false;
            state.commentsSuccess = true;
            state.commentsError = false;
            state.commentsList = action.payload;
        });
        builder.addCase(deleteComment.rejected, (state, action) => {
            state.commentsLoading = false;
            state.commentsError = action.payload.message;
        });
        //! Delete note from unviewed
        builder.addCase(deleteNotificationAction.pending, (state) => {
            state.commentsLoading = true;
        });
        builder.addCase(deleteNotificationAction.fulfilled, (state, action) => {
            state.commentsLoading = false;
            state.commentsSuccess = true;
            state.commentsError = false;
        });
        builder.addCase(deleteNotificationAction.rejected, (state, action) => {
            state.commentsLoading = false;
            state.commentsError = action.payload.message;
        });

        //!reset error action
        builder.addCase(resetErrorAction.fulfilled, (state) => {
            state.commentsError = null;
        });

        //!reset success action
        builder.addCase(resetSuccessAction.fulfilled, (state) => {
            state.commentsSuccess = false;
        });

        //!reset loading action
        builder.addCase(resetLoadingAction.fulfilled, (state) => {
            state.commentsLoading = false;
        });
    },
});

const commentsReducer = CommentsSlice.reducer;
export const { logoutCommentsSlice } = CommentsSlice.actions;
export default commentsReducer;
