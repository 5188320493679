import React, { useEffect } from "react";
import SavedSingleSearch from "./SavedSingleSearch";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAction } from "../../../../redux/slices/usersSlice";
import Error from "../../../Alerts/Error";
import { resetSuccessAction } from "../../../../redux/slices/globalSlice";

const SavedSearches = ({ profile }) => {
    //* reload data after deleting post
    const { searchSaveSuccess, searchSaveError } = useSelector((state) => state?.posts);

    const dispatch = useDispatch();
    useEffect(() => {
        if (searchSaveSuccess) {
            setTimeout(() => {
                dispatch(userProfileAction());
                dispatch(resetSuccessAction());
            }, 2000);
        }
    }, [searchSaveSuccess, dispatch]);

    return (
        <section className="relative pb-8 bg-white">
            <div
                className="absolute top-0 left-0 w-full h-full"
                style={{
                    backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left top",
                }}
            />
            <div className="container relative z-10 px-4 mx-auto">
                <div className=" text-start md:max-w-5xl md:mb-16">
                    <h3 className="font-Roboto-Bold mb-4 text-2xl font-semibold leading-tight tracking-tighter text-darkCoolGray-900">
                        Saved Searches
                    </h3>
                </div>

                <div className="flex flex-col mb-12 mx-auto md:mb-20">
                    {profile?.savedSearches.length === 0 ? (
                        <h1 className="text-center">no saved searches</h1>
                    ) : (
                        <ul className="flex flex-col gap-y-4 z-20 relative">
                            {profile?.savedSearches.map((search, index) => (
                                <SavedSingleSearch search={search} key={index} />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            {/* Error  */}
            {searchSaveError && <Error message={searchSaveError} />}
        </section>
    );
};

export default SavedSearches;
