import React from "react";
import { Link } from "react-router-dom";

function Logo() {
    return (
        <div className="flex">
            <Link to="/" className=" p-2.5">
                <span className="sr-only ">Company name</span>
                <h1 className="font-Signika-Bold text-white font-bold text-2xl sm:text-3xl min-[2560px]:text-5xl">
                    Lostify<span className="super-small font-Signika-Bold">.org</span>
                </h1>
            </Link>
        </div>
    );
}

export default Logo;
