import React, { useState } from "react";
import DesktopNavBar from "./DesktopNavBar";
import MobileNavBar from "./MobileNavBar";
import { useDispatch } from "react-redux";
import { resetLoadingAction } from "../../redux/slices/globalSlice";

function NavBar() {
    const navigation = [
        { name: "Home", href: "/" },
        { name: "Profile", href: "/profile" },
        { name: "Search", href: "/search" },
        { name: "Create Post", href: "/posts/create" },
        { name: "What & Why", href: "/about" },
        { name: "Feedback", href: "/feedback" },
        { name: "FAQ", href: "/faq" },
    ];
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const helper = (value) => setMobileMenuOpen(value);

    //
    const dispatch = useDispatch();

    window.onbeforeunload = () => {
        dispatch(resetLoadingAction());
    };
    return (
        <header className="absolute inset-x-0 top-0 z-30">
            <DesktopNavBar navigation={navigation} setMobileMenuOpen={helper} />
            <MobileNavBar navigation={navigation} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={helper} />
        </header>
    );
}

export default NavBar;
