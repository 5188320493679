import { MapPinIcon } from "@heroicons/react/20/solid";
import { deleteNotificationAction } from "../../../../redux/slices/commentsSlice";
import { useDispatch } from "react-redux";
import { resetSuccessAction, setPostId, setShowDetailModal } from "../../../../redux/slices/globalSlice";
import { userProfileAction } from "../../../../redux/slices/usersSlice";

const Notifications = ({ profile }) => {
    const dispatch = useDispatch();
    //* Find unique posts with new messages
    const unViewedNotifications = Array.from(new Set(profile?.unViewedNotifications));

    let postsWithNotifications = [];
    profile?.posts.map((post) => {
        return unViewedNotifications?.map((item) => {
            return item.toString() === post?._id.toString() && postsWithNotifications.push(post);
        });
    });

    const handler = (id) => {
        dispatch(setShowDetailModal(true));
        dispatch(setPostId(id));
        dispatch(deleteNotificationAction({ id }));
        setTimeout(() => {
            dispatch(userProfileAction());
            dispatch(resetSuccessAction());
        }, 2000);
    };

    return (
        <>
            {}
            {postsWithNotifications?.length === 0 ? (
                <h2 className="text-center"> You have no new notifications</h2>
            ) : (
                postsWithNotifications?.map((post, index) => {
                    return (
                        <div>
                            <div className="text-start md:max-w-5xl md:mb-16 px-2">
                                <h3 className="mb-4 text-2xl font-semibold leading-tight tracking-tighter text-darkCoolGray-900">
                                    Notifications
                                </h3>
                            </div>
                            <div
                                key={index}
                                className="bg-white h-16 mx-3 mt-4  xl:justify-start flex items-center lg:justify-between border py-2 px-2 lg:px-16 sm:py-6 rounded-md"
                            >
                                <div className="flex justify-start w-full">
                                    <div className="hidden sm:block rounded-full bg-red-600 w-5 h-5 self-center mr-5"></div>
                                    <h2 className="font-Roboto-Bold text-base sm:text-2xl md:text-2xl font-bold text-gray-800 sm:truncate lg:text-3xl sm:tracking-tight ">
                                        {post?.title}
                                    </h2>
                                    <div className="font-Roboto-Base max-lg:hidden self-end pb-1 text-sm ml-5 text-gray-500 ">
                                        <h2>Situation:{post?.situation?.type}</h2>
                                    </div>
                                    <div className="font-Roboto-Base max-lg:hidden flex self-end pb-1 text-sm ml-5 text-gray-500">
                                        <MapPinIcon
                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        {post?.countryname}, {post?.cityname}
                                    </div>
                                    <div className="font-Roboto-Base max-lg:hidden self-end text-sm ml-5 pb-1  text-gray-500">
                                        {post?.deviceid && <h2>EMEI/ID:{post?.deviceid}</h2>}
                                    </div>
                                </div>

                                <div className="flex lg:m">
                                    <span className="ml-3  block">
                                        <button
                                            onClick={() => {
                                                handler(post?._id);
                                            }}
                                            type="button"
                                            className="font-Roboto-Bold inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
                                        >
                                            Open
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })
            )}
        </>
    );
};

export default Notifications;
