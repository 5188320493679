import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import userIcon from "../../media/user.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutGlobalSlice, setActiveProfileButtons } from "../../redux/slices/globalSlice";
import { logoutCitiesSlice } from "../../redux/slices/citiesSlice";
import { logoutCommentsSlice } from "../../redux/slices/commentsSlice";
import { logoutPostsSlice } from "../../redux/slices/postsSlice";
import { logoutUsersSlice } from "../../redux/slices/usersSlice";

function ProfileDropdown() {
    const loggedInUser = useSelector((state) => state?.users?.profile?.user);

    const dispatch = useDispatch();
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    //* Logout handler

    const logOutHandler = () => {
        dispatch(logoutUsersSlice());
        dispatch(logoutPostsSlice());
        dispatch(logoutCitiesSlice());
        dispatch(logoutCommentsSlice());
        dispatch(logoutGlobalSlice());
        localStorage.clear();
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const dropDownList = [
        { title: "Your Profile", path: "/profile" },
        { title: "Create Post", path: "/posts/create" },
        { title: "Settings", path: "/profile" },
        { title: "Logout", onClick: logOutHandler },
    ];
    return (
        <div className="hidden lg:ml-4 lg:flex lg:flex-shrink-0 lg:items-center">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3 border-2 rounded-full border-blue-200">
                <div>
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <img
                            className="h-12 w-12 min-[2560px]:h-20 min-[2560px]:w-20 rounded-full"
                            src={loggedInUser?.profilePicture || userIcon}
                            alt="alt"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {dropDownList.map((item, index) => (
                            <Menu.Item key={index}>
                                {({ active }) =>
                                    item?.path ? (
                                        <Link
                                            onClick={() => {
                                                dispatch(setActiveProfileButtons(6));
                                            }}
                                            to={item?.path}
                                            className={classNames(
                                                active ? "bg-gray-100" : "",
                                                "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                        >
                                            {item.title}
                                        </Link>
                                    ) : (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <button
                                                    onClick={item?.onClick}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                >
                                                    {item?.title}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    )
                                }
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

export default ProfileDropdown;
