import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../redux/slices/globalSlice";
import Modal from "../helpers/Modal";

function Terms() {
    //* set active page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActivePage(4));
    }, [dispatch]);
    return (
        <Modal>
            <div className="pt-40 "></div>
            <div className="font-Roboto-Bold text-black font-bold bg-white w-vw-95 md:w-5/6 xl:w-4/6 py-6 sm:py-20 px-4 sm:px-10 xl:px-20 mx-auto border-2 border-gray-200 rounded-md relative z-20">
                <div>
                    <div className="">
                        <h1 className=" text-xl text-center mb-10 font-black">TERMS AND CONDITIONS</h1>
                        <p className="">
                            These terms and conditions (the "Terms and Conditions") govern the use of{" "}
                            <span className="text-bold ">www.lostify.org</span> (the "Site").
                            <br />
                            <br />
                            By using this Site, you indicate that you have read and understand these Terms and
                            Conditions and agree to abide by them at all times.
                            <br />
                            <br />
                            <br />
                        </p>
                        <div className="">
                            <p>
                                <span className="font-bold underline ">Terms for using the site</span>
                                <br />
                                <br />
                                The purpose of creating a website is to connect the party that lost an electronic
                                device (phone, tablet, laptop) and the party that found it. User on our web site
                                can:
                            </p>
                            <br />
                            <ul className="list-disc ">
                                <li>
                                    <span>Create a post about lost device;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Use web site for search lost gadgets;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>
                                        Use the chat on our website solely for the purpose of searching for his
                                        lost device or searching for the owner of a found device;
                                    </span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Use the settings of our website according to your own discretion;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>
                                        According to our policy, we do not prohibit the party who found the device
                                        from asking for a reward for its return, but not more than 15% of its
                                        market price. By using our service, you agree to these terms;
                                    </span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Delete and edit posts;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Delete account;</span>
                                    <br />
                                    <br />
                                </li>
                            </ul>
                            <p>Users of our web site you can`t:</p>
                            <br />
                            <ul className="list-disc ">
                                <li>
                                    <span>Publish posts about non-existent missing devices;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Publish posts about non-existent device finds;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Leave comments under posts that do not directly concern the user;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>
                                        Inflate the market value of the found device or demand more than 15% of its
                                        market value;
                                    </span>
                                    <br />
                                    <br />
                                </li>
                            </ul>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                Do not try to make money on the found device, ask for a minimum reward for the
                                found device or do not ask at all. Don't break the rules. If we believe you are
                                using our Site illegally or in a manner that violates these Terms and Conditions,
                                we reserve the right to limit, suspend or terminate your access to our Site. We
                                also reserve the right to take any legal steps necessary to prevent you from
                                accessing our Site.
                            </p>
                            <br />
                            <br />
                        </div>
                        <div className="">
                            <div className="">
                                <p className="">
                                    <span className="font-bold ">Sale of Services and Goods</span>
                                    <br />
                                    <br />
                                    These Terms and Conditions govern the providing the service and sale of goods
                                    (next goods) available on our Site.
                                </p>
                                <br />

                                <p className="font-bold ">The following goods are available on our Site:</p>
                                <br />

                                <ul>
                                    <li className="list-disc">
                                        <span className="">
                                            Subscription to limited or unlimited number of searches per month.
                                        </span>

                                        <br />
                                    </li>
                                    <li className="list-disc">
                                        <span className="">
                                            Subscription to limited or unlimited number of posts can be created.
                                        </span>

                                        <br />
                                    </li>
                                </ul>
                                <br />
                                <br />
                                <p className="">
                                    These Terms and Conditions apply to all the goods that are displayed on our
                                    Site at the time you access it. This includes all products listed as being out
                                    of stock. All information, descriptions, or images that we provide about our
                                    goods are as accurate as possible. However, we are not legally bound by such
                                    information, descriptions, or images as we cannot guarantee the accuracy of all
                                    goods we provide. You agree to purchase goods from our Site at your own risk.
                                </p>
                                <p className="">
                                    We reserve the right to modify, reject or cancel your order whenever it becomes
                                    necessary. If we cancel your order and have already processed your payment, we
                                    will give you a refund equal to the amount you paid. You agree that it is your
                                    responsibility to monitor your payment instrument to verify receipt of any
                                    refund.
                                </p>
                            </div>
                            All content published and made available on our Site is the property of owner of web
                            site (creator). This includes, but is not limited to images, text, logos, documents,
                            downloadable files and anything that contributes to the composition of our Site.
                            <br />
                            <br />
                        </div>
                        <div className="">
                            <p>
                                <span className="font-bold underline ">Acceptable Use</span>
                                <br />
                                <br />
                                As a user of our Site, you agree to use our Site legally, not to use our Site for
                                illegal purposes, and not to:
                            </p>
                            <br />
                            <ul className="list-disc ">
                                <li>
                                    <span>Harass or mistreat other users of our Site;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Violate the rights of other users of our Site;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>
                                        Violate the intellectual property rights of the Site owners or any third
                                        party to the Site;
                                    </span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Hack into the account of another user of the Site;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Act in any way that could be considered fraudulent;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Post any material that may be deemed inappropriate or offensive.</span>
                                    <br />
                                    <br />
                                </li>
                            </ul>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                If we believe you are using our Site illegally or in a manner that violates these
                                Terms and Conditions, we reserve the right to limit, suspend or terminate your
                                access to our Site. We also reserve the right to take any legal steps necessary to
                                prevent you from accessing our Site.
                            </p>
                            <br />
                            <br />
                        </div>
                        <div className="">
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                <span className="font-bold underline ">User Contributions</span>
                                <br />
                                <br />
                                Users may post the following information on our Site:
                            </p>
                            <br />
                            <ul className="list-disc">
                                <li>
                                    <span>Photos;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Public comments;</span>
                                    <br />
                                    <br />
                                </li>
                                <li>
                                    <span>Posts.</span>
                                    <br />
                                    <br />
                                </li>
                            </ul>
                            <p className="">
                                By posting publicly on our Site, you agree to do not act illegally or violate these
                                Terms and Conditions.
                            </p>
                            <br />
                            <br />
                        </div>
                        <div>
                            <p className="">
                                <span className="font-bold underline ">Accounts</span>
                                <br />
                                <br />
                                When you create an account on our Site, you agree to the following:
                            </p>
                            <br />
                            <ol className="list-disc">
                                <li>
                                    You are solely responsible for your account and the security and privacy of
                                    your account, including passwords or sensitive information attached to that
                                    account;
                                </li>
                                <br />
                                <li>
                                    All personal information you provide to us through your account is up to date,
                                    accurate, and truthful and that you will update your personal information if it
                                    changes.
                                </li>
                            </ol>
                            <br />

                            <p className="">
                                We reserve the right to suspend or terminate your account if you are using our Site
                                illegally or if you violate these Terms and Conditions.
                            </p>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p className="">
                                <span className="font-bold underline ">User Goods and Services</span>
                                <br />
                                <br />
                                Our Site do not allows users to sell goods and services. We do not assume any
                                responsibility for the goods and services users sell on our Site. However, if we
                                are made aware that a user is violating these Terms and Conditions, we reserve the
                                right to suspend or prohibit the user from selling goods and services on our Site.
                            </p>
                            <br />
                        </div>
                        <div>
                            <p className="">
                                <span className="font-bold underline ">Subscriptions</span>
                                <br />
                                <br />
                                Your subscription automatically renews and you will be automatically billed until
                                we receive notification that you want to cancel the subscription.
                            </p>

                            <p className="">
                                To cancel your subscription, please follow these steps: If you didn't use any
                                privelege on the website after buying a subscription, you can ask for a full refund
                                with our support service - lostify.feedback@gmail.com.
                            </p>
                        </div>
                        <br />
                        <div>
                            <p className="">
                                <span className="font-bold underline ">Payments</span>
                                <br />
                                <br />
                                We accept the following payment methods on our Site:
                            </p>
                            <br />
                            <ul className="list-disc">
                                <li>Credit Card;</li>
                                <br />
                                <li>PayPal;</li>
                                <br />
                                <li>Debit;</li>
                                <br />
                                <li>Direct Debit;</li>
                                <br />
                                <li>Crypto Currency</li>
                                <br />
                            </ul>
                            <p>
                                When you provide us with your payment information, you authorize our use of and
                                access to the payment instrument you have chosen to use. By providing us with your
                                payment information, you authorize us to charge the amount due to this payment
                                instrument.
                                <br />
                                <br />
                                If we believe your payment has violated any law or these Terms and Conditions, we
                                reserve the right to cancel or reverse your transaction.
                            </p>
                        </div>
                        <br /> <br />
                        <div>
                            <p>
                                <span className="font-bold underline ">Refunds</span>
                                <br /> <br />
                                <span className="font-bold">Refunds for Goods</span>
                                <br /> <br />
                                Refund requests must be made within 3 days after receipt of your goods.
                            </p>
                        </div>
                        <br /> <br />
                        <div>
                            <p>
                                <span className="font-bold underline ">Consumer Protection Law</span>
                                <br /> <br />
                                Where any consumer protection legislation in your jurisdiction applies and cannot
                                be excluded, these Terms and Conditions will not limit your legal rights and
                                remedies under that legislation. These Terms and Conditions will be read subject to
                                the mandatory provisions of that legislation. If there is a conflict between these
                                Terms and Conditions and that legislation, the mandatory provisions of the
                                legislation will apply.
                            </p>
                        </div>
                        <br />
                        <div>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                <span className="font-bold underline ">Limitation of Liability</span>
                                <br /> <br />
                                Owner, officers, agents, employees, subsidiaries, and affiliates will not be liable
                                for any actions, claims, losses, damages, liabilities and expenses including legal
                                fees from your use of the Site.
                            </p>
                        </div>
                        <br />
                        <div>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                <span className="font-bold underline ">Indemnity</span>
                                <br /> <br />
                                Except where prohibited by law, by using this Site you indemnify and hold harmless
                                Mykola Naumenko (owner) and our directors, officers, agents, employees,
                                subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities
                                and expenses including legal fees arising out of your use of our Site or your
                                violation of these Terms and Conditions.
                            </p>
                        </div>
                        <br />
                        <div>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                <span className="font-bold underline ">Severability</span>
                                <br /> <br />
                                If at any time any of the provisions set forth in these Terms and Conditions are
                                found to be inconsistent or invalid under applicable laws, those provisions will be
                                deemed void and will be removed from these Terms and Conditions. All other
                                provisions will not be affected by the removal and the rest of these Terms and
                                Conditions will still be considered valid.
                            </p>
                        </div>
                        <br />
                        <div>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                <span className="font-bold underline ">Changes</span>
                                <br /> <br />
                                These Terms and Conditions may be amended from time to time in order to maintain
                                compliance with the law and to reflect any changes to the way we operate our Site
                                and the way we expect users to behave on our Site. We will notify users by email of
                                changes to these Terms and Conditions or post a notice on our Site.
                            </p>
                        </div>
                        <br />
                        <div>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                <span className="font-bold underline ">Contact Details</span>
                                <br /> <br />
                                Please contact us if you have any questions or concerns. Our contact details are as
                                follows:
                                <br />
                                <br />
                                _______________________
                                <br />
                                lostify.feedback@gmail.com
                                <br />
                                _______________________
                            </p>
                            <p className="line-height:18.0pt;font-size:12.0pt;line-height:18.0pt;font-family:Times New Roman;color:#000000;text-align:Left;">
                                You can also contact us through the feedback form available on our Site.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p>©2002-2025 Copyright®</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Terms;
