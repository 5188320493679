import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { emailNotificationSwitchAction, userProfileAction } from "../../redux/slices/usersSlice";

function Switch({ notif }) {
    const [sent, setSent] = useState(false);
    const dispatch = useDispatch();
    const switchNotificationsHandler = (e) => {
        e.preventDefault();
        dispatch(emailNotificationSwitchAction());
        setSent((p) => !p);
    };
    useEffect(() => {
        setTimeout(() => {
            dispatch(userProfileAction());
        }, 1000);
    }, [sent, dispatch]);
    return (
        <button
            onClick={switchNotificationsHandler}
            className="w-11 h-6 rounded-full ring-1 ring-slate-400 bg-white relative "
            type="submit"
        >
            <div
                className={`${"w-6 h-6 rounded-full absolute"}
                ${notif && "bg-green-500  top-0 left-0"}
                ${!notif && " top-0 left-5 bg-gray-500"}`}
            ></div>
        </button>
    );
}

export default Switch;
