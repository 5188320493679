import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
    const { userAuth } = useSelector((state) => state?.users);
    const isLogin = userAuth?.userInfo?.token;

    if (!isLogin) {
        return <Navigate to={"/login"} />;
    } else {
        return children;
    }
}
