import React from "react";
import Select from "react-select";

function SearchPostSelect({ title, options, name, onChange, errors, basis, id }) {
    return (
        <div className={`${basis} mb-4 sm:mx-3 flex flex-col `}>
            <label htmlFor={id} className="font-Roboto-Semibold mb-1 text-white font-medium">
                {title}
            </label>
            <Select id={id} options={options} name={name} onChange={onChange}></Select>
            {errors?.[name] && <p className="pt-2 text-red-600">{errors?.[name]}</p>}
        </div>
    );
}

export default SearchPostSelect;
