import Swal from "sweetalert2";
import { resetSuccessAction } from "../../redux/slices/globalSlice";

import { useDispatch } from "react-redux";

const Success = ({ message, timer }) => {
    const dispatch = useDispatch();

    let width;
    if (window.innerWidth < 640) {
        width = "85%";
    }

    Swal.fire({
        position: "center",
        icon: "success",
        title: message,
        showConfirmButton: false,
        color: "#0236a7",
        iconColor: "#4e80f4",
        padding: "1rem",
        width: width || "27rem",
        timer: timer || 2000,
    });

    dispatch(resetSuccessAction());
};

export default Success;
