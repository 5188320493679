import UserPosts from "../MyPosts/UserPosts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteProfileAction, logoutUsersSlice, userProfileAction } from "../../../../redux/slices/usersSlice";
import CoverImage from "./CoverImage";
import VerificationButton from "./VerificationButton";
import ProfileButtons from "./ProfileButtons";
import UploadProfileImage from "./UploadProfileImage";
import PostDetails from "../../../Posts/PostDetails";
import FavoritePosts from "../FavoritePosts/FavoritePosts";
import SavedSearches from "../SavedSearches/SavedSearches";
import {
    addAppoinment,
    addDealAction,
    getAdminDataAction,
    logoutGlobalSlice,
    resetErrorAction,
    resetSuccessAction,
    setActivePage,
    setShowConfirmAppoinmentModal,
    setShowDetailModal,
} from "../../../../redux/slices/globalSlice";
import EditPostForm from "../MyPosts/EditPostForm";
import ProfileSettings from "../Settings/ProfileSettings";
import Notifications from "../Notifications/Notifications";
import DeleteModal from "./DeleteModal";
import { deletePostAction, logoutPostsSlice } from "../../../../redux/slices/postsSlice";
import AdminPannel from "../AdminPannel/AdminPannel";
import AdminFeedBackModal from "../AdminPannel/AdminFeedBackModal";
import Error from "../../../Alerts/Error";
import AdminPostDetails from "../AdminPannel/AdminPostDetail";
import AdminUserDetailsModal from "../AdminPannel/AdminUserDetailsModal";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { logoutCitiesSlice } from "../../../../redux/slices/citiesSlice";
import { logoutCommentsSlice } from "../../../../redux/slices/commentsSlice";

const UserProfile = () => {
    const dispatch = useDispatch();
    //* UseSelectors

    const loggedInUser = useSelector((state) => state?.users?.profile?.user);
    const active = useSelector((state) => state?.global?.button);
    const showImageModal = useSelector((state) => state?.global?.showImageModal);
    const showDetailsModal = useSelector((state) => state?.global?.showDetailsModal);
    const showEditPostModal = useSelector((state) => state?.global?.showEditPostModal);
    const showFeedBackModal = useSelector((state) => state?.global?.showFeedBackModal);
    const showAdminDetailsModal = useSelector((state) => state?.global?.showAdminDetailsModal);
    const showAdminUserDataModal = useSelector((state) => state?.global?.showAdminUserDataModal);
    const showConfirmAppoinmentModal = useSelector((state) => state?.global?.showConfirmAppoinmentModal);
    const { getFeedBackError, fetchUserError } = useSelector((state) => state?.users);

    //!reset store
    useEffect(() => {
        if (loggedInUser?.resetStore) {
            dispatch(logoutCitiesSlice());
            dispatch(logoutCommentsSlice());
            dispatch(logoutGlobalSlice());
            dispatch(logoutPostsSlice());
            dispatch(logoutUsersSlice());
            localStorage.clear();
            setTimeout(() => {
                window.location.reload();
                // navigate("/");
            }, 2000);
        }
    }, [dispatch, loggedInUser?.resetStore]);

    //* Set active page

    useEffect(() => {
        dispatch(resetErrorAction());
        dispatch(userProfileAction());
        dispatch(setActivePage(1));
        loggedInUser?.role !== "user" && dispatch(getAdminDataAction());
    }, [dispatch, loggedInUser?.role]);

    //* UseStates
    const [postid, setPostid] = useState("");

    //* Delete profile modal handler
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);
    const deleteProfileModalHandler = () => {
        setDeleteAccountModal((p) => !p);
    };
    const deleteProfileHandler = (e) => {
        e.preventDefault();
        dispatch(deleteProfileAction());
    };

    //* Delete post modal handler
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);
    const deletePostModalHandler = (id) => {
        setShowDeletePostModal((p) => !p); // он же делает открытие и закрытие компонента!! надо чтобы тут либо тру либо фолс и создать отдельную функцию для обратного
        setPostid(id);
    };
    const deletePostHandler = (e) => {
        e.preventDefault();
        dispatch(deletePostAction(postid));
        setShowDeletePostModal(true);
        dispatch(resetSuccessAction());
    };

    const deletePostPlusDealHandler = (e) => {
        e.preventDefault();
        dispatch(deletePostAction(postid));
        setShowDeletePostModal(true);

        dispatch(addDealAction());
        dispatch(resetSuccessAction());
    };

    //* Confirm appoinment modal handler

    const closeConfirmAppoinmentModal = (e) => {
        e.preventDefault();
        dispatch(setShowConfirmAppoinmentModal(false));
        dispatch(setShowDetailModal(true));
    };

    const countAppoinmentsHandler = (e) => {
        e.preventDefault();
        dispatch(addAppoinment());
        dispatch(setShowConfirmAppoinmentModal(false));
    };

    //* Side bar
    const [isOpenSideBar, setIsOpenSideBar] = useState(false);
    const [isHiddenSideBar, setIsHiddenSideBar] = useState(true);
    const toggleSideBar = () => {
        if (isHiddenSideBar) {
            setIsHiddenSideBar(false);
            setIsOpenSideBar(true);
        }

        if (!isHiddenSideBar) {
            setIsOpenSideBar(false);
            setTimeout(() => {
                setIsHiddenSideBar(true);
            }, 150);
        }
    };

    return (
        <>
            {/* Admin user details modal */}
            {showAdminUserDataModal && <AdminUserDetailsModal />}
            {fetchUserError && <Error message={fetchUserError?.message} />}

            {/* Admin post details modal */}
            {showAdminDetailsModal && <AdminPostDetails />}

            {/*  Admin feedback modal */}
            {showFeedBackModal && <AdminFeedBackModal />}
            {getFeedBackError && <Error message={getFeedBackError} />}

            {/* upload image */}
            {showImageModal && <UploadProfileImage />}

            {/* details modal */}
            {showDetailsModal && <PostDetails />}

            {/* edit post modal */}
            {showEditPostModal && <EditPostForm />}

            {/* confirm appoinment modal */}
            {showConfirmAppoinmentModal && (
                <DeleteModal
                    firstMessage={"Have you actually made an appointment with the person to hand over the device?"}
                    secondMessage={"*Don't forget to delete the post after you receive your device."}
                    successMessage={""}
                    closeDeleteModalHandler={closeConfirmAppoinmentModal}
                    deleteHandler={countAppoinmentsHandler}
                />
            )}

            {/* delete account modal */}
            {deleteAccountModal && (
                <DeleteModal
                    firstMessage={"Are you sure you want to delete your account?"}
                    secondMessage={"*this action cannot be undone"}
                    successMessage={"Account  successfully deleted"}
                    closeDeleteModalHandler={deleteProfileModalHandler}
                    deleteHandler={deleteProfileHandler}
                    deletePost={true}
                />
            )}

            {/* delete modal */}
            {showDeletePostModal && (
                <DeleteModal
                    firstMessage={"Did you find your device with our service?"}
                    successMessage={"Post successfully deleted"}
                    closeDeleteModalHandler={deletePostModalHandler}
                    counter={1}
                    deleteHandler={deletePostHandler}
                    deleteWithDealHandler={deletePostPlusDealHandler}
                />
            )}

            <div className="flex h-full min-h-screen bg-white">
                <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
                    <CoverImage />
                    <div
                        onClick={toggleSideBar}
                        className="sm:hidden z-10 flex items-center fixed left-0 top-space-50 bg-blue-800 max-[500px]:w-space-7.5 w-space-5 h-16 rounded-r-md"
                    >
                        {isOpenSideBar ? (
                            <ChevronLeftIcon className=" h-10  text-white" aria-hidden="true" />
                        ) : (
                            <ChevronRightIcon className=" h-10  text-white" aria-hidden="true" />
                        )}
                    </div>

                    <main
                        className={`${
                            isOpenSideBar && "max-sm:h-96"
                        } relative z-0 flex  sm:flex-row  sm:justify-around mt-20`}
                    >
                        <div
                            className={`${isOpenSideBar ? "visible-1 h-96" : "hidden-1"} ${
                                isHiddenSideBar ? "hidden" : "visible"
                            } sm:basis-4/12  md:max-xl:basis-3/12 xl:basis-2/12 sm:flex flex-col  sm:border-r-2 pr-6 pl-6  lg:max-xl:pr-1 lg:max-xl:pl-3 `}
                        >
                            <div className="ml-4 sm:ml-3 lg:mb-4">
                                <h1 className="font-Roboto-Bold text-3xl min-[2560px]:text-5xl text-gray-600 font-bold ">
                                    {loggedInUser?.name}
                                </h1>
                            </div>
                            <div className="">
                                <h3 className="font-Roboto-Semibold whitespace-nowrap ml-4 max-[260px]:text-xs sm:ml-3 text-l min-[2560px]:text-xl  text-gray-600">
                                    Account level:{" "}
                                    <span
                                        className={`${
                                            loggedInUser?.accountLevel === "gold" && "text-decoration-yellow-900"
                                        }`}
                                    >
                                        {loggedInUser?.accountLevel}
                                    </span>
                                </h3>
                            </div>{" "}
                            <div className=" sm:max-lg:w-48 mt-6 sm:flex sm:flex-col sm:items-start sm:justify-start sm:mt-6">
                                {!loggedInUser?.isVerified ? (
                                    <VerificationButton />
                                ) : (
                                    <ProfileButtons toggleSideBar={toggleSideBar} />
                                )}
                            </div>
                        </div>
                        <div
                            className={`${isOpenSideBar ? "visible-2 " : "hidden-2"} w-vw-100 grow sm:basis-9/12 `}
                        >
                            {active === 0 && loggedInUser?.isVerified && (
                                <UserPosts profile={loggedInUser} deletePostHandler={deletePostModalHandler} />
                            )}
                            {active === 3 && loggedInUser?.isVerified && <Notifications profile={loggedInUser} />}
                            {active === 4 && loggedInUser?.isVerified && <FavoritePosts profile={loggedInUser} />}
                            {active === 5 && loggedInUser?.isVerified && <SavedSearches profile={loggedInUser} />}
                            {(active === 6 || !loggedInUser?.isVerified) && (
                                <ProfileSettings
                                    profile={loggedInUser}
                                    deleteProfileModalHandler={deleteProfileModalHandler}
                                />
                            )}
                            {active === 7 && loggedInUser?.isVerified && loggedInUser?.role === "admin" && (
                                <AdminPannel />
                            )}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};
export default UserProfile;
