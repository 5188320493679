import React from "react";
import userPic from "../../../../media/user.png";
import { useDispatch, useSelector } from "react-redux";
import { resetSuccessAction, setShowAdminDetailModal } from "../../../../redux/slices/globalSlice";
import CommentsList from "../../../Comments/CommentList";

function AdminPostDetails() {
    //* Post data
    const post = useSelector((state) => state?.posts?.post?.postFound);

    const data = [
        { type: "Situation", value: post?.situation?.type },
        { type: "Country", value: post?.countryname },
        { type: "City", value: post?.cityname },
        { type: "Type", value: post?.devicetype?.name },
        { type: "Brand", value: post?.manufacturer },
        { type: "Model", value: post?.model },
        { type: "Color", value: post?.color === null && "" },
        { type: "Year", value: post?.year === null && "" },
        { type: "EMEI/ID", value: post?.deviceid },
        { type: "Post created at", value: new Date(post?.createdAt).toDateString() },
        { type: "Description", value: post?.content },
        { type: "Post ID", value: post?._id },
        { type: "Author ID", value: post?.author?._id },
    ];

    //* Close details modal
    const dispatch = useDispatch();
    const closeAdminPostDetailsModal = () => {
        dispatch(setShowAdminDetailModal(false));
        dispatch(resetSuccessAction());
    };

    return (
        <div className="flex w-full h-full  justify-center">
            <div onClick={closeAdminPostDetailsModal} className="backdrop "></div>
            <div className="fixed z-20 my-16 2xl:my-20 min-[2560px]:my-32 bg-white px-2 sm:px-7 2xl:px-20 py-10 w-vw-97 sm:w-11/12 xl:w-9/12 h-5/6 rounded-md overflow-scroll">
                <div className="pb-4 px-4 lg:px-0 flex justify-between">
                    <h3 className="text-lg md:text-2xl 2xl:text-2xl font-semibold leading-7 text-gray-900">
                        {post?.title}
                    </h3>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-black cursor-pointer"
                        onClick={closeAdminPostDetailsModal}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="flex lg:flex-row flex-col justify-between mt-6 border-t border-gray-100">
                    {post?.image !== "" && (
                        <div className="w-space-95 mb-5 lg:mb-0 mx-auto lg:w-full">
                            <img src={post?.image} alt="alt"></img>
                        </div>
                    )}
                    <dl className="basis-5/12 divide-y divide-gray-100">
                        {data?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="w-space-95 mx-auto py-1 px-1 lg:px-4 lg:py-2 grid grid-cols-3 gap-4 "
                                >
                                    <dt className="text-sm lg:text-xs 2xl:text-md min-[2560px]:text-xl font-medium leading-6 text-gray-900">
                                        {item?.type}:
                                    </dt>
                                    <dd className="text-end mt-1 text-sm lg:text-xs min-[2560px]:text-xl leading-6 text-gray-700 col-span-2">
                                        {item?.value}
                                    </dd>
                                </div>
                            );
                        })}
                    </dl>
                    <dl className="flex flex-col basis-5/12 mt-10 lg:mt-0 text-end">
                        <h1 className="text-xl">Author:</h1>
                        <div className="self-end my-4">
                            <img
                                src={post?.author?.profilePicture || userPic}
                                alt="avatar"
                                className="w-12 h-12 rounded-full"
                            />
                        </div>
                        <h3 className="text-base leading-7 text-gray-900">{post?.author?.name}</h3>
                        <h3 className="text-base leading-7 text-gray-900">{post?.author?.email}</h3>
                        <h3 className="text-base leading-7 text-gray-900">{post?.author?.role}</h3>
                        <h3 className="text-base leading-7 text-gray-900">{post?.author?.accountLevel}</h3>
                        <h3 className="text-base leading-7 text-gray-900">
                            {post?.author?.isVerified ? "Verified" : "Not verified"}
                        </h3>
                        <h3 className="text-base  leading-7 text-gray-900">
                            Account level expired:{" "}
                            {new Date(post?.author?.accountLevelExpired).toString().slice(0, 16)}
                        </h3>
                        <h3 className="text-base leading-7 text-gray-900">
                            Last login: {new Date(post?.author?.lastLogin).toString().slice(0, 24)}
                        </h3>
                        <div className="mt-4">
                            <h2 className="max-lg:text-start mb-2">Comments:</h2>
                            <CommentsList comments={post?.comments} />
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
}

export default AdminPostDetails;
