import { useEffect } from "react";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { savePostAction } from "../../redux/slices/postsSlice";
import { userProfileAction } from "../../redux/slices/usersSlice";
import { resetSuccessAction, setPostId, setShowDetailModal } from "../../redux/slices/globalSlice";
import Loading from "../Alerts/Loading";
import Error from "../Alerts/Error";

export default function SearchPost({ post }) {
    const dispatch = useDispatch();
    //* Did user save this post before
    const user = useSelector((state) => state?.users?.profile?.user);

    const userSavedPosts = user?.savedPosts;
    const postId = post?._id;
    const isSaved = userSavedPosts?.some((post) => post?._id === postId);

    //* Save/unsave post action
    const { postSaveError, postSaveLoading, postSaveSuccess } = useSelector((state) => state?.posts);
    const savePostHandler = (postid) => {
        dispatch(savePostAction(postid));
    };

    useEffect(() => {
        if (postSaveSuccess) {
            dispatch(userProfileAction());
            dispatch(resetSuccessAction());
        }
    }, [postSaveSuccess, postSaveError, dispatch]);

    //* Show details modal
    const showDetailsModal = (id) => {
        dispatch(setShowDetailModal(true));
        dispatch(setPostId(id));
    };

    return (
        <div className="bg-white font-bold flex items-center justify-between border px-2 sm:px-6 py-3 sm:py-10  rounded-md ">
            <div className="min-w-0 flex-1">
                <h2 className="font-Roboto-Bold text-2xl min-[2560px]:text-3xl font-bold leading-7 text-gray-800 sm:mb-8 sm:truncate sm:text-3xl sm:tracking-tight ">
                    {post?.title}
                </h2>
                <div className="font-Roboto-Bold mt-1 flex flex-col sm:mt-0 md:flex-row sm:flex-wrap sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm min-[2560px]:text-xl text-gray-500">
                        <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        {post?.countryname}
                    </div>
                    <div className="mt-2 flex items-center text-sm min-[2560px]:text-xl text-gray-500">
                        {post?.cityname}
                    </div>
                    <div className="mt-2 flex items-center text-sm min-[2560px]:text-xl text-gray-500">
                        <p className="text-gray-500">EMEI/ID: {post?.deviceid}</p>
                    </div>
                    <div className="mt-2 flex items-center text-sm min-[2560px]:text-xl text-gray-500">
                        {post?.devicetype === "651181a2467bfd90bf3caba3" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                />
                            </svg>
                        )}
                        {post?.devicetype === "652299913b22f00b67c538e0" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 002.25-2.25v-15a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 4.5v15a2.25 2.25 0 002.25 2.25z"
                                />
                            </svg>
                        )}
                        {post?.devicetype === "6522999a3b22f00b67c538e4" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                class="bi bi-laptop"
                                viewBox="0 0 20 18"
                            >
                                {" "}
                                <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />{" "}
                            </svg>
                        )}
                        {post?.manufacturer}, {post?.model}
                    </div>
                    <div className="mt-2 flex items-center text-sm min-[2560px]:text-xl text-gray-500">
                        <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        {new Date(post?.createdAt).toDateString()}
                    </div>
                </div>
            </div>
            <div className="mt-5 flex lg:ml-4 lg:mt-0 self-end">
                <span className="ml-3 ">
                    <button
                        onClick={() => showDetailsModal(post?._id)}
                        type="button"
                        className="font-Roboto-Bold inline-flex items-center rounded-md bg-white px-3 py-2 text-sm min-[2560px]:text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 28 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                            />
                        </svg>
                        Details
                    </button>
                </span>
                {/* save post */}
                {user?._id !== post?.author?._id && (
                    <span className="ml-3 ">
                        {postSaveLoading ? (
                            <Loading color={"blue"} w={35} h={35} mr={"2rem"} />
                        ) : (
                            <button
                                onClick={() => savePostHandler(post?._id)}
                                type="button"
                                className="font-Roboto-Bold inline-flex items-center rounded-md bg-white px-3 py-2 text-sm min-[2560px]:text-xl font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                {isSaved ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-5 h-5 mr-1"
                                    >
                                        <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5 mr-1"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                        />
                                    </svg>
                                )}
                                Save{isSaved && "d"}
                            </button>
                        )}
                    </span>
                )}

                {/* Dropdown
                <Menu as="div" className="relative ml-3 sm:hidden">
                    <Menu.Button className="font-Roboto-Bold inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                        More
                        <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="font-Roboto-Bold absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        onClick={() => showDetailsModal(post?._id)}
                                        className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                    >
                                        Details
                                    </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        onClick={() => savePostHandler(post?._id)}
                                        className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "flex  px-4 py-2 text-sm text-gray-700"
                                        )}
                                    >
                                        Save{isSaved && "d"}
                                        {isSaved ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-5 h-5 mr-1"
                                            >
                                                <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                class="w-5 h-5 mr-1"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                                />
                                            </svg>
                                        )}
                                    </Link>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu> */}
            </div>
            {/* Error */}
            {postSaveError && <Error message={postSaveError} />}
        </div>
    );
}
