import React from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutCitiesSlice } from "../../redux/slices/citiesSlice";
import { logoutCommentsSlice } from "../../redux/slices/commentsSlice";
import { logoutGlobalSlice } from "../../redux/slices/globalSlice";
import { logoutPostsSlice } from "../../redux/slices/postsSlice";
import { logoutUsersSlice } from "../../redux/slices/usersSlice";
function MobileNavBar({ navigation, mobileMenuOpen, setMobileMenuOpen }) {
    const dispatch = useDispatch();
    const userAuth = useSelector((state) => state?.users?.userAuth);

    //* Logout handler

    const logoutHandler = () => {
        dispatch(logoutUsersSlice());
        dispatch(logoutCitiesSlice());
        dispatch(logoutCommentsSlice());
        dispatch(logoutGlobalSlice());
        dispatch(logoutPostsSlice());
        localStorage.clear();
        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    return (
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />{" "}
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto  bg-blue-950 sm:max-w-sm ">
                <div className="flex items-center justify-end ">
                    <button
                        type="button"
                        className=" m-8 rounded-md p-1.5 text-white"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6 pt-10">
                            {navigation.map((item) => (
                                <Link
                                    onClick={() => setMobileMenuOpen(false)}
                                    key={item.name}
                                    to={item.href}
                                    className="font-Roboto-Bold -mx-3 text-center block rounded-lg px-3 py-2 text-xl font-bold leading-7 text-white"
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>

                        {!userAuth?.userInfo?.token ? (
                            <div className="py-6">
                                <Link
                                    onClick={() => setMobileMenuOpen(false)}
                                    to="/login"
                                    className="-mx-3 text-center block rounded-lg px-3 py-2.5 text-xl font-bold leading-7 text-white"
                                >
                                    Log in
                                </Link>
                            </div>
                        ) : (
                            <div className="py-6">
                                <Link
                                    onClick={logoutHandler}
                                    className="-mx-3 block text-center rounded-lg px-3 py-2.5 text-xl font-bold leading-7 text-white"
                                >
                                    Log out
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    );
}

export default MobileNavBar;
